import { useEffect, useContext, useState } from 'react'
import { ScreenSizeContext, DisplayMoreDataUpdateContext, ThemeContext } from '../../contexts/appContext'
import Slider from './Slider/Slider'
import './style.scss'

function MoreOption (props) {
  const [ isSliderDisplayed, setIsSliderDisplayed ] = useState(false)
  const [ sliderType, setSliderType ] = useState(null)

  const screenSize = useContext(ScreenSizeContext)
  const setIsMoreDisplayed = useContext(DisplayMoreDataUpdateContext)
  const themeContext = useContext(ThemeContext)
  //setDisplayMoreOption
  /*useEffect(() => {
    const eleStyle = document.getElementById('MoreBodyDiv').style

    if(screenSize.width > screenSize.height) {
      alert('landScape')
    }

    else {
      alert('portrait')
    }
  }, [screenSize])*/

  useEffect(() => {
    window.onkeydown = (event) => {
      if(event.key === 'Escape') {
        window.onkeydown = null
        setIsMoreDisplayed(false)
      }
    }
  }, [])

  useEffect(() => {
    const eleStyle = document.getElementById('MoreBodyDiv').style
    eleStyle.width = '400px'
    eleStyle.height = '600px'

    if(screenSize.width > screenSize.height) {
      eleStyle.maxWidth = (screenSize.height * 0.97 * 0.75) + 'px'
      eleStyle.maxHeight = '97vh'
    }

    else {
      eleStyle.maxWidth = '97vw'
      eleStyle.maxHeight = (screenSize.width * 0.97 * 1.33) + 'px'
    }
  }, [screenSize])

  const currentYear = new Date().getFullYear()
  return (
    <div id='MoreDiv'>
        <div id='MoreBodyDiv' className={themeContext}>
        <div className='optionInMore'
        onClick={() => {
          setSliderType('about')
          setIsSliderDisplayed(true)
        }}>
          About
        </div>
        <div className='optionInMore'
        onClick={() => {
          setSliderType('advertisement')
          setIsSliderDisplayed(true)
        }}>
          Advertisement
        </div>
        <div className='optionInMore'
        onClick={() => {
          setSliderType('contact')
          setIsSliderDisplayed(true)
        }}>
          Contact
        </div>
        <div className='optionInMore'
        onClick={() => {
          setSliderType('privacy')
          setIsSliderDisplayed(true)
        }}>
          Privacy
        </div>
        <div className='optionInMore'
        onClick={() => {
          setSliderType('rights')
          setIsSliderDisplayed(true)
        }}>
          Rights
        </div>
        <div className='optionInMore'
        onClick={() => {
          setSliderType('seo')
          setIsSliderDisplayed(true)
        }}>
          SEO
        </div>
        <div className='optionInMore'
        onClick={() => {
          setSliderType('terms')
          setIsSliderDisplayed(true)
        }}>
          Terms
        </div>
        <div className='optionInMore'
        onClick={() => {
          document.body.style.overflowY = 'auto'
          setIsMoreDisplayed(false)
        }}>
          Cancel
        </div>

        {isSliderDisplayed &&
          <Slider sliderType={sliderType} setIsSliderDisplayed={setIsSliderDisplayed} />
        }
        {!isSliderDisplayed &&
          <div style={{ flexGrow: '1', display: 'flex' }}>
            <span style={{ display: 'inlineBlock', alignSelf: 'flex-end', paddingLeft: '5px', fontSize: '12px', fontWeight: '300' }}>&copy; {currentYear} Myfoodie.ca Inc. All rights reserved.</span>
          </div>
        }
      </div>
    </div>
  )
}

export default MoreOption
