import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CurrentPageUpdateContext, TagsContext, SearchCounterDataContext, DisplayLoadingDataUpdateContext } from '../../contexts/appContext'
import Recipes from '../../components/Recipes/Recipes'
import SvgIcons from '../../components/SvgIcons/SvgIcons'
import './style.scss'

function Search() {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const query = queryParams.get('query')

  const [ isNoMatchDisplayed, setIsNoMatchDisplayed ] = useState(false)

  //alert(query)
  //{"type":"tags","data":[4,5,7]}

  const setCurrectPage = useContext(CurrentPageUpdateContext)
  const searchCounter = useContext(SearchCounterDataContext)
  //const setIsLoading = useContext(DisplayLoadingDataUpdateContext)

  //setIsLoading(true)

  useEffect(() => {
    console.log(`IN SEAR`);
    setCurrectPage('Search')
    //alert(`started: ${isSearchStarted.toString()} | tags: ${tags.some(item => item.isSelected)} | textbox: ${textboxValue}`)
  }, [])

  const pinnedList = window.localStorage.getItem('pinnedItems') === null? []: JSON.parse(window.localStorage.getItem('pinnedItems'))

  return (
    <div id="SearchPageDiv" key={searchCounter}>
      {!isNoMatchDisplayed &&
        <div style={{ position: 'relative', textAlign: 'center', margin: '40px 0', maxWidth: '100vw'}}>
          <span style={{ opacity: '0.6' }}>Search matching:</span>
          {JSON.parse(query).type === 'text' &&
            <span> {JSON.parse(query).data}</span>
          }
          {JSON.parse(query).type === 'suggestions' &&
            <span>&nbsp;&nbsp;<SvgIcons id={23} style={{ opacity: '0.7', transform: 'translateY(2px)' }} /> {JSON.parse(query).selected}</span>
          }
          {JSON.parse(query).type === 'tags' &&
            JSON.parse(query).tagNames.map((tagName, i) => {
              return (
                <span key={i}>
                  {i === 0 && <br />}
                  &nbsp;&nbsp;<span style={{ fontWeight: '300', whiteSpace: 'nowrap' }}><SvgIcons id={32} style={{ opacity: '0.7', transform: 'translateY(3px)' }} />{tagName}</span>
                </span>
              )
            })
          }
        </div>
      }
      <Recipes type='search' setIsNoMatchDisplayed={setIsNoMatchDisplayed} />
      {isNoMatchDisplayed &&
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
         No search matching <b>{JSON.parse(query).data}</b>
        </div>
      }
    </div>
  )
}

export default Search
