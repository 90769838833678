import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CurrentPageUpdateContext, ThemeContext, TagsContext, TagsUpdateContext, SearchDataContext, SearchDataUpdateContext, SearchTextboxDataContext, SearchTextboxDataUpdateContext, ScreenSizeContext, SearchCounterDataContext, SearchCounterDataUpdateContext } from '../../../contexts/appContext'
import SvgIcons from '../../SvgIcons/SvgIcons'
import SearchWindow from '../SearchWindow/SearchWindow'
import './style.scss'

function SearchSection(props) {
  const [ cssObject, setCSSObject ] = useState({})
  //const [ textboxValue, setTextboxValue ] = useState('')
  const setCurrentPage = useContext(CurrentPageUpdateContext)
  const tags = useContext(TagsContext)
  const themeContext = useContext(ThemeContext)
  const setTagsData = useContext(TagsUpdateContext)
  const searchDataContext = useContext(SearchDataContext)
  const setSearchDataContext = useContext(SearchDataUpdateContext)
  const textboxValue = useContext(SearchTextboxDataContext)
  const setTextboxValue = useContext(SearchTextboxDataUpdateContext)
  const screenSize = useContext(ScreenSizeContext)
  const searchCounter = useContext(SearchCounterDataContext)
  const setSearchCounter = useContext(SearchCounterDataUpdateContext)

  const navigate = useNavigate()

  const navigateToSearch = () => {
    const dataValue = JSON.stringify({type: 'text', data: textboxValue})
    const encodedDataValue = encodeURIComponent(dataValue)
    const url = `/search?query=${encodedDataValue}`
    screenSize.width > 1024? props.setIsSearchDivDisplayed(false): props.setIsMobileSearchDisplayed(false)
    setSearchCounter(searchCounter + 1)
    navigate(url)
  }

  useEffect(() => {
    if(document.getElementById('SearchTextbox') !== null)
      document.getElementById('SearchTextbox').value = textboxValue

    if(document.getElementById('SearchButtonForSuggstions') !== null) {
      if(textboxValue === '')
        document.getElementById('SearchButtonForSuggstions').style.display = 'none'

      else
        document.getElementById('SearchButtonForSuggstions').style.display = 'block'
    }
  }, [textboxValue])

  //if(appData.screenWidth > 1024) {
    return(
      <div id='SearchDiv'>
        {(!props.isSearchDivDisplayed || !tags.some(item => item.isSelected)) &&
          <div id='SearchTextboxDiv'>
            <input id='SearchTextbox' type='text' placeholder='search' autoComplete='off'
            onFocus={(event) => {
              if(textboxValue !== '')
                event.target.value = textboxValue

              document.body.style.overflowY = 'hidden'
              document.getElementById('NavBar').onclick = (event) => {
                if(event.target.id === 'AppOptionsButton') {
                  const tempData = [ ...tags ]

                  tempData.forEach((item, i) => {
                    item.isSelected = false
                  });
                  setTagsData(tempData)
                  document.getElementById('NavBar').onclick = null
                }
              }

              window.onclick = (event) => {
                props.setIsOptionsDivDisplayed(false)
                //window.alert(`id: ${event.target.id} class: ${event.target.className}`)
                if(event.target.id !== 'SearchTextbox' && event.target.id !== 'SearchIconInTextbox' && event.target.id !== 'SearchResultsDiv' && event.target.id !== 'TagsDiv' && event.target.className !== 'tags' && event.target.className !== 'tag' && event.target.id !== 'ClearAllTags' && event.target.id !== 'SearchButtonForTags' && event.target.id !== 'SearchDivDesktop' && event.target.id !== 'SearchDivMobile' && !(screenSize.width < 1024 && event.target.id === 'SearchResultsBackdrop')) {
                  props.setIsSearchDivDisplayed(false)
                  document.body.style.overflowY = 'auto'
                  window.onclick = null
                  //window.history.pushState({}, null, `?recipe=${recipesData.recipes[i].id}`)
                }
              }
              /*window.onclick = () => {
                window.alert("AAAAASD")
              }*/
              setCSSObject({ width: screenSize.width > 1024? event.target.getBoundingClientRect().width - 40 + 'px': 'calc(100vw - 40px)', animation: 'displaySearchSection 150ms linear forwards' })
              //setCSSObject({ animation: 'displaySearchSection 150ms linear forwards' })
              props.setIsSearchDivDisplayed(true)
            }}
            onChange={(event) => {
              //window.alert('CHANGED')

              setTextboxValue(event.target.value)

            }}
            onKeyDown={(event) => {
              //window.alert(`down: ${event.keyCode}`)
              if(textboxValue !== '' && event.keyCode === 13) {
                navigateToSearch.call(this)
                //event.target.blur()
                //document.getElementById('SubmitSearch').click()
                /*props.setIsSearchDivDisplayed(false)
                document.body.style.overflowY = 'auto'
                window.onclick = null
                props.setIsMobileSearchDisplayed(false)*/
              }
            }} />
            <div id='SearchButtonForSuggstions' className={themeContext} onClick={navigateToSearch}>
              GO
            </div>
          </div>
        }
        {
          props.isSearchDivDisplayed &&
          <SearchWindow style={cssObject}
            isSearchDivDisplayed={props.isSearchDivDisplayed}
            setIsSearchDivDisplayed={props.setIsSearchDivDisplayed}
            setIsMobileSearchDisplayed={props.setIsMobileSearchDisplayed}
            closeSearchResults={() => {
              document.getElementById('SearchResultsDiv').style.animation = 'hideSearchSection 100ms linear forwards'
              props.setIsSearchDivDisplayed(false)
            }}
          />
        }
        {(!props.isSearchDivDisplayed || !tags.some(item => item.isSelected)) &&
          <div id='SearchIconInTextbox' style={{ pointerEvents: textboxValue === ''? 'none': 'auto' }}
            onClick={() => {
              if(textboxValue !== '') {
                setTextboxValue('')
                document.getElementById('SearchTextbox').value = ''
                //document.getElementById('SearchTextbox').focus()
                setTimeout(function () {
                  document.getElementById('SearchTextbox').focus()
                }, 100);
              }
            }}
          >
            <SvgIcons id={textboxValue === ''? 23: 14} style={textboxValue === ''? {transform: screenSize.width < 1025? 'translateY(3px)': 'none'}: {transform: screenSize.width > 1024? 'translateY(-2px)': 'translateY(2px)'}} />
          </div>
        }
        {screenSize.width < 1025 &&
          <div id='CancelSearchButton'
          onClick={() => {
            props.setIsSearchDivDisplayed(false)
            document.body.style.overflowY = 'auto'
            window.onclick = null
            props.setIsMobileSearchDisplayed(false)
          }}>
            <SvgIcons id={49} />
          </div>
        }
      </div>
    )
  /*}
  else {
    return(
      <div id="SearchOptionMobile">
        <SvgIcons id={23} strokeColor='transparant' strokeWidth='0.1' />
      </div>
    )
  }*/
}

export default SearchSection
