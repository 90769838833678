function Privacy() {
  return (
    <div>
      <div className='headerInSlider'>Privacy Policy</div>
      Thank you for visiting MyFoodie.ca (the "Website"). We value your privacy and want to ensure you have a safe and enjoyable experience while exploring our content. This Privacy Policy explains how we handle information on our Website.
      <div className='headerInSlider'>Information Collection and Use</div>
      MyFoodie.ca does not collect any personal information or data from its users. We respect your privacy and provide a browsing experience that does not require any user data submission.
      <div className='headerInSlider'>Cookies Usage</div>
      MyFoodie.ca uses cookies to enhance your browsing experience and provide personalized content. Cookies are small text files that are stored on your device when you visit our Website. These cookies allow us to remember your preferences, analyze site traffic, and improve our services.
      <div className='headerInSlider'>Third-Party Cookies</div>
      In addition to our own cookies, we may also use third-party cookies provided by trusted partners. These cookies may be used for various purposes, such as analytics, advertising, and social media integration. Please note that third-party cookies are subject to the privacy policies of their respective providers.
      <div className='headerInSlider'>Cookie Consent</div>
      By using our Website, you consent to the use of cookies as described in this Privacy Policy. You can manage your cookie preferences and opt-out of non-essential cookies by adjusting your browser settings. Please note that disabling certain cookies may impact your user experience on our Website.
      <div className='headerInSlider'>Contact Us</div>
      If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at <a href='mailto:contact@myfoodie.ca'>contact@myfoodie.ca</a>.
    </div>
  )
}

export default Privacy
