import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CurrentPageUpdateContext, ThemeContext, SearchDataUpdateContext, SearchTextboxDataContext, SearchTextboxDataUpdateContext, SearchSuggesionsDataContext, ScreenSizeContext, SearchCounterDataContext, SearchCounterDataUpdateContext } from '../../../contexts/appContext'
import SvgIcons from '../../SvgIcons/SvgIcons'

//import Tag from '../Tag/Tag'
import './style.scss'

function SearchSeggesions(props) {
  const navigate = useNavigate()

  const [ suggestionsData, setSuggesionsData ] = useState([])

  const setCurrentPage = useContext(CurrentPageUpdateContext)
  const themeContext = useContext(ThemeContext)
  const setSearchDataContext = useContext(SearchDataUpdateContext)
  const textboxValue = useContext(SearchTextboxDataContext)
  const setTextboxValue = useContext(SearchTextboxDataUpdateContext)
  const suggestionsDataContext = useContext(SearchSuggesionsDataContext)
  const screenSize = useContext(ScreenSizeContext)
  const searchCounter = useContext(SearchCounterDataContext)
  const setSearchCounter = useContext(SearchCounterDataUpdateContext)
  //const setTagsData = useContext(TagsUpdateContext)


  useEffect(() => {
    //window.alert(`YY: ${textboxValue}`)
    const tempData = []

    suggestionsDataContext.forEach((item, i) => {
      if(item.text.toLowerCase().indexOf(textboxValue.toLowerCase()) === 0)
        tempData.push({text: item.text, indexes: item.indexes })
    });
    setSuggesionsData(tempData)
  }, [textboxValue])

  return(
    <div id='SearchSeggesionsDiv'>
      {
        suggestionsData.map((item, i) => {
          return(
            <div key={i}
              onClick={() => {
                //console.log(`sugg v2.0: ${item.indexes}`);
                setTextboxValue(item.text)
                const dataValue = JSON.stringify({type: 'suggestions', selected: item.text, indexes: item.indexes})
                const encodedDataValue = encodeURIComponent(dataValue)
                const url = `/search?query=${encodedDataValue}`
                setSearchCounter(searchCounter + 1)
                navigate(url)
                screenSize.width > 1024? props.setIsSearchDivDisplayed(false): props.setIsMobileSearchDisplayed(false)




                /*setCurrentPage('Search')
                setSearchDataContext({textboxValue: '', tags: [], indexes: suggestionsDataContext[item.index].indexes, fromClick: null})
                setTextboxValue(item.text)
                document.getElementById('SearchTextbox').value = item.text.toLowerCase()
                window.scrollTo(0, 0)
                //document.body.style.overflowY = 'auto'
                props.setIsSearchDivDisplayed(false)

                if(screenSize.width < 1025)
                  props.setIsMobileSearchDisplayed(false)*/
              }}
            >
              <div key={i} className={`suggesionRow ${themeContext}`}>
                <SvgIcons id={23} style={{ transform: 'translateY(5px)' }} /><span>{item.text}</span>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default SearchSeggesions
