import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../../contexts/appContext'
import logoDark from '../../images/Logo-Dark-600px.png'
import logoLight from '../../images/Logo-Light-600px.png'
import './style.scss'

function MainLogo() {
  const themeContext = useContext(ThemeContext)

  return (
    <div id="LogoDiv" onClick={() => {
      //document.cookie = 'originPath=home'
      ////////////sessionStorage.setItem('originPath', '/recipes')
      //////////window.localStorage.clear()
      //window.alert(window.localStorage.getItem('appReactedd'))
      /*for(let i = 0; i < window.localStorage.length; i++) {
        window.alert(`A: ${window.localStorage.key(i)}`)
        }*/
      }}>
      <Link to="/home">
        <img id="LogoImg" src={themeContext === 'theme-dark'? logoDark: logoDark} alt="myfoodie.ca logo" draggable={false}/>
      </Link>
    </div>
  );
}
export default MainLogo;

/*<Link to="/recipe/?recipe=1689080031206" draggable="false">

</Link>*/
