import { useContext } from 'react'
import { TagsContext, TagsUpdateContext } from '../../../contexts/appContext'
import SvgIcons from '../../SvgIcons/SvgIcons'
import './style.scss'

function Tag(props) {
  const tags = useContext(TagsContext)
  const setTags = useContext(TagsUpdateContext)

  return(
    <div key={props.index} className='tag' style={{ backgroundColor: tags[props.index].isSelected? 'rgba(229, 40, 95, 1)': 'rgba(55, 55, 55, 0.6)' }}
    onClick={() => {
      const tempData = [ ...tags ]
      //console.log(JSON.stringify(tempData));
      tempData[props.index].isSelected = tempData[props.index].isSelected? false: true
      setTags(tempData)
    }}>
      <div>
        <SvgIcons id={32} />
      </div>
      <div style={{ color: tags[props.index].isSelected? '#ffffff': '#dddddd', whiteSpace: 'nowrap' }}>
        {tags[props.index].text}
      </div>
    </div>
  )
}

export default Tag
