import { useContext, useState } from 'react'
import { RecipesDataContext, RecipesDataUpdateContext, TooltipUpdateContext } from '../../contexts/appContext'
import SvgIcons from '../SvgIcons/SvgIcons'

function RemoveSavedItem(props) {
  const recipesData = useContext(RecipesDataContext)
  const setRecipesData = useContext(RecipesDataUpdateContext)
  const setTooltipData = useContext(TooltipUpdateContext)

  return (
    <div id='DeleteConfirmationDiv'>
      <div id='DeleteConfirmationBackdrop'>
      </div>
      <div id='DeleteConfirmationBody'>
        <div style ={{ textAlign: 'center', color: 'white' }}>Are you sure you want to remove {recipesData.recipes[props.data.i].name} from the list?</div>
        <div id='ButtonsInRemoveDiv'>
          <div onClick={() => {
            const itemsToRemove = JSON.parse(window.localStorage.getItem(props.data.savedItems))
            itemsToRemove.splice(itemsToRemove.indexOf(props.data.i), 1)
            window.localStorage.setItem(props.data.savedItems, JSON.stringify(itemsToRemove))

            let tempData = { ...recipesData }

            if (props.data.savedItems === 'pinnedItems')
              tempData.recipes[props.data.i].isPinned = false

            else
              tempData.recipes[props.data.i].isSavedToWatchLater = false

            setRecipesData(tempData)

            window.localStorage.removeItem(`${props.data.localStoragePREF}_${recipesData.recipes[props.data.i].id}`)

            setTooltipData(
              {
                display: true
              }
            )
            props.setIsDeleteConfirmDisplayed(false)
          }}>
            Yes
          </div>
          <div onClick={() => {props.setIsDeleteConfirmDisplayed(false)}}>
            No
          </div>
        </div>
      </div>
    </div>
  )
}

export default RemoveSavedItem
