import { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { RecipesDataContext, ThemeContext, ScreenSizeContext, CurrentPageContext, DisplayLoadingDataUpdateContext, DisplayInitLoadingDataContext } from '../../contexts/appContext'
import ReactOption from '../ReactOption/ReactOption'
import PinOption from '../PinOption/PinOption'
import ShareOption from '../ShareOption/ShareOption'
import NumberOfViews from '../NumberOfViews/NumberOfViews'
import SvgIcons from '../SvgIcons/SvgIcons'
import RemoveSavedItemOption from '../RemoveSavedItemOption/RemoveSavedItemOption'
import './style.scss'

function Recipes(props) {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const query = queryParams.get('query')

  const [ recipesDataArr, setRecipesDataArr ] = useState([])

  const recipesData =  useContext(RecipesDataContext)
  const themeContext = useContext(ThemeContext)
  const screenSize = useContext(ScreenSizeContext)
  const currentPage = useContext(CurrentPageContext)
  const setIsLoading = useContext(DisplayLoadingDataUpdateContext)
  const isInitLoading = useContext(DisplayInitLoadingDataContext)

  useEffect(() => {
    let temp = []
    let incomingData

    if(recipesData.recipes.length > 0 && props.type === 'all') {
      document.getElementById('RecipesOptionsDivInRecipes').style.top = screenSize.width > 1024? '100px': '5px'
      temp = [...recipesData.recipes]
    }

    else if(recipesData.recipes.length > 0 && props.type === 'saved') {
      document.getElementById('RecipesOptionsDivInRecipes').style.top = 'auto'
      incomingData = JSON.parse(window.localStorage.getItem('pinnedItems'))

      for(let i = 0; i < incomingData.length; i++)
        temp.push({actualIndex: incomingData[i], ...recipesData.recipes[incomingData[i]]})
    }

    else if(recipesData.recipes.length > 0 && props.type === 'search') {
      //setIsLoading(true)
      document.getElementById('RecipesOptionsDivInRecipes').style.top = 'auto'

      if(JSON.parse(query).type === 'tags' || JSON.parse(query).type === 'suggestions') {
        incomingData = JSON.parse(query).indexes

        for(let i = 0; i < incomingData.length; i++)
          temp.push({actualIndex: incomingData[i], ...recipesData.recipes[incomingData[i]]})
      }

      else {
        const searchFor = JSON.parse(query).data.toLowerCase()

        recipesData.recipes.map((recipe, i) => {
          if(
            recipe.name.toLowerCase().indexOf(searchFor) === 0 ||
            recipe.name.toLowerCase().indexOf(` ${searchFor}`) > 0 ||
            //recipe.title.toLowerCase().indexOf(searchFor) === 0 ||
            //recipe.title.toLowerCase().indexOf(` ${searchFor}`) > 0 ||
            recipe.tags.some(tag => tag.toLowerCase().indexOf(searchFor) === 0) ||
            recipe.tags.some(tag => tag.toLowerCase().indexOf(` ${searchFor}`) > 0)
          )
            temp.push(recipe)
        })

        props.setIsNoMatchDisplayed(true)

        if(temp.length > 0)
          props.setIsNoMatchDisplayed(false)
      }
    }

    setRecipesDataArr(temp)

    console.log(`IN RECIPES`);

  }, [recipesData])

  const getSpan = (item) => {
    if(item.cardSize === 'large') {
      if(screenSize.width > 768)
        return 38

      else if(screenSize.width > 389)
        return 26

      return 22
    }

    else if(item.cardSize === 'medium') {
      if(screenSize.width > 768)
        return 34

      else if(screenSize.width > 389)
        return 24

      return 20
    }

    else if(item.cardSize === 'small') {
      if(screenSize.width > 768)
        return 31

      else if(screenSize.width > 389)
        return 20

      return 18
    }
  }

  const setElement = (item, i) => {
    return
  }

  return (
    <div id='RecipesDiv'>
      <div id="RecipesOptionsDivInRecipes" className={themeContext}>
      {
        recipesDataArr.map((item, i) => {
          return (
            <div style={{ position: 'relative', paddingBottom: '20px', gridRowEnd: `span ${getSpan.call(this, item)}` }} key={i} className='recipeCardInRecipes'>
              <Link key={i} className='recipeImagInPost' to={`/recipe/${recipesDataArr[i].id}`} draggable='false'>
                <img src={require(`../../images/${item.images[1]}`)} alt={item.altText} draggable={false} />
              </Link>
              <div style={{ position: 'relative', marginLeft: '5px' }}>
                {item.name}
                <div className='iconsInRecipeCard'>
                  <ReactOption index={i} rID={item.id} />
                  {currentPage !== 'Pinned' &&
                    <PinOption index={i} rID={item.id} />
                  }
                  <ShareOption rID={item.id} />
                  <NumberOfViews index={i} rID={recipesDataArr[i].id} />
                  {currentPage === 'Pinned' &&
                    <RemoveSavedItemOption i={item.actualIndex} localStoragePREF='recipePin' savedItems='pinnedItems' setRemoveData={props.setRemoveData} />
                  }
                </div>
                {screenSize.width > 768 &&
                  <div className='cuisine'>
                    {item.tags[0]}
                  </div>
                }
              </div>
              {screenSize.width < 769 &&
                <div className='cuisine'>
                  {item.tags[0]}
                </div>
              }
            </div>
          )
        })
      }
      </div>
    </div>
  )
}

export default Recipes
