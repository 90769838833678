import { useState, useContext, useEffect } from 'react'
import { get, onValue, set, ref } from 'firebase/database'
import { appReactsRef } from '../../firebase'
import { TooltipUpdateContext, CurrentPageContext } from '../../contexts/appContext'
import SvgIcons from '../SvgIcons/SvgIcons'
import Tooltip from '../Tooltip/Tooltip'
import './style.scss'

function AppReact(props) {
  const setTooltipData = useContext(TooltipUpdateContext)
  const currentPage = useContext(CurrentPageContext)

  const [numOfReacts, setNumOfReacts] = useState('0')

  useEffect(() => {
    window.localStorage.getItem('appReacted')
    const appReactsListener = onValue(appReactsRef, (snapshot) => {
      const appLikesData = snapshot.val()
      setNumOfReacts(appLikesData)
    })

    // Clean up the listener when the component unmounts
    return () => {
      appReactsListener()
    }
  }, [])

  const incrementAppReacts = () => {
    // Increment appReacts in Firebase by 1
    set(appReactsRef, parseInt(numOfReacts) + 1)
      .then(() => {
        // Update local state
        setNumOfReacts((prevState) => String(parseInt(prevState)))
      })
      .catch((error) => {
        console.error('Error incrementing appReacts:', error)
      })
  }


  const test = () => {
    if(parseInt(numOfReacts) > 999 && parseInt(numOfReacts) < 100000)
      return `${(parseInt(numOfReacts) / 1000).toFixed(1).replace('.0', '')}K`

    if(parseInt(numOfReacts) > 99999 && parseInt(numOfReacts) < 1000000)
      return `${(parseInt(numOfReacts) / 1000).toFixed(0).replace('.0', '')}K`

    else if(parseInt(numOfReacts) > 999999)
      return `${(parseInt(numOfReacts) / 1000000).toFixed(1).replace('.0', '')}M`

    return numOfReacts
  }

  if(currentPage !== 'Recipe') {
    return(
      <div id='AppReactDiv'>
        <div id='AppReactButton'
          onClick={(event) => {
            if(window.localStorage.getItem('appReacted') !== '1') {
              window.localStorage.setItem('appReacted', '1')
              event.target.style.animation = 'toReact 700ms linear forwards'
              incrementAppReacts()
            }

            //incremeant appReacts by 1
            if(navigator.vibrate !== undefined)
              navigator.vibrate(250)

            //props.setReactFromClient.call()
            setTooltipData(
              {
                display: true,
                position: 'fixed',
                text: window.localStorage.getItem('appReacted') == '1'? 'reacted': 'react',
                left: 'auto',
                top: 'auto',
                right: window.localStorage.getItem('appReacted') == '1'? '7': '12',
                bottom: '80'
              }
            )
          }}
          onMouseEnter={(event) => {
            setTooltipData(
              {
                display: true,
                position: 'fixed',
                text: window.localStorage.getItem('appReacted') == '1'? 'reacted': 'react',
                left: 'auto',
                top: 'auto',
                right: window.localStorage.getItem('appReacted') == '1'? '7': '12',
                bottom: '80'
              }
            )
          }}

          onMouseLeave={(event) => {
            setTooltipData(
              {
                display: false
              }
            )
          }}>
          <SvgIcons id={window.localStorage.getItem('appReacted') == '1'? 12: 11} style={{ strokeWidth: '0' }} />
        </div>
        <div id='NumOfAppReacts'>
          {test.call(this)}
        </div>
      </div>
    )
  }
  else
    return false
}

export default AppReact
