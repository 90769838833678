function Terms() {
  return (
    <>
      <div className='headerInSlider'>Terms of Service</div>
      Thank you for visiting MyFoodie.ca (the "Website"). By accessing and using this Website, you agree to comply with the following Terms of Service. Please read these terms carefully before using our services.
      <div className='headerInSlider'>1. Acceptance of Terms</div>
      By using MyFoodie.ca, you acknowledge that you have read, understood, and agreed to these Terms of Service. If you do not agree to these terms, please refrain from using the Website.
      <div className='headerInSlider'>2. Use of Website</div>
      2.1. Personal Use: MyFoodie.ca is intended for personal, non-commercial use only. You may not use this Website for any commercial purposes without our explicit written consent.

      2.2. Content Ownership: All content on MyFoodie.ca, including but not limited to text, images, videos, and graphics, is the property of MyFoodie.ca Inc. and is protected by copyright and other intellectual property laws.

      2.3. Prohibited Activities: You are prohibited from engaging in any of the following activities:

      Posting or transmitting any unlawful, harmful, defamatory, or otherwise objectionable content.
      Attempting to gain unauthorized access to our servers, networks, or user accounts.
      Interfering with the proper functioning of the Website or disrupting its services.
      Engaging in any activity that violates applicable laws or regulations.
      <div className='headerInSlider'>3. Disclaimer</div>
      3.1. Content Accuracy: While we strive to provide accurate and up-to-date information, MyFoodie.ca does not guarantee the accuracy, completeness, or reliability of the content on the Website. We reserve the right to make changes and updates to the content without prior notice.

      3.2. External Links: MyFoodie.ca may contain links to third-party websites. These links are provided for your convenience, but we do not endorse or take responsibility for the content or practices of any third-party sites.
      <div className='headerInSlider'>4. Limitation of Liability</div>
      MyFoodie.ca Inc., its directors, employees, or affiliates shall not be held liable for any damages, losses, or expenses arising from the use or inability to use the Website or its content.
      <div className='headerInSlider'>5. Indemnification</div>
      You agree to indemnify and hold harmless MyFoodie.ca Inc. and its directors, employees, or affiliates from any claims, losses, damages, liabilities, or expenses arising from your use of the Website or violation of these Terms of Service.
      <div className='headerInSlider'>6. Changes to Terms of Service</div>
      We reserve the right to modify, update, or replace these Terms of Service at any time. The revised terms will be effective immediately upon posting on this page. Your continued use of the Website after any changes constitutes your acceptance of the revised Terms of Service.
      <div className='headerInSlider'>7. Governing Law</div>
      These Terms of Service shall be governed by and construed in accordance with the laws of Canada, without regard to its conflict of laws principles.
    </>
  )
}

export default Terms
