function About() {
  return (
    <>
      <div className='headerInSlider'>About MyFoodie.ca</div>
      Welcome to MyFoodie.ca, your delightful destination for authentic, exotic, and mouthwatering recipes. Our mission is to explore and share a diverse collection of delectable dishes, while our vision is to see people across the globe savoring and enjoying the recipes we share on MyFoodie.ca.
      <div className='headerInSlider'>Our Founding Story</div>
      MyFoodie.ca is a heartwarming family venture, created with love and passion for good food. The team behind MyFoodie.ca comprises dedicated members - mom, dad, and son - each playing a unique role in crafting this culinary haven. Mom's culinary expertise brings the magic to our recipes, while dad, a skilled computer programmer, brought MyFoodie.ca to life as a web application. Our son, the videos editor and social media accounts admin, ensures that our culinary adventures reach and inspire a wide audience.
      <div className='headerInSlider'>Our Promise</div>
      Though a young web application, MyFoodie.ca is committed to serving the finest recipes and captivating your taste buds with every dish. We are always on the lookout for special recipes that have captivated the culinary community - from viral sensations on social media to signature dishes exclusive to MyFoodie.ca.
      <div className='headerInSlider'>A Homely Affair</div>
      MyFoodie.ca is a web application created with love at home, dedicated to connecting with the vast family of food lovers on the internet. We take pride in being a small family endeavor that aims to bring joy to your dining experiences.
      <div className='headerInSlider'>Join Our Culinary Journey</div>
      Come, be a part of our culinary journey! Explore our treasure trove of exclusive recipes, discover the magic of flavors from around the world, and indulge in the delights that MyFoodie.ca has to offer.
    </>
  )
}

export default About
