import { useState, useEffect, useContext } from 'react'
import { listenToPostLikesChange, updatePostLikesCount } from '../../firebase'
import { RecipesDataContext, RecipesDataUpdateContext, TooltipUpdateContext } from '../../contexts/appContext'
import SvgIcons from '../SvgIcons/SvgIcons'
import Tooltip from '../Tooltip/Tooltip'
//import { updatePostLikesCount } from '../../firebase'
import './style.scss'

function ReactOption(props) {
  //const [numberOfLikes, setNumberOfLikes] = useState(0)
  const recipesData = useContext(RecipesDataContext)
  const setRecipesData = useContext(RecipesDataUpdateContext)
  const setTooltipData = useContext(TooltipUpdateContext)

  useEffect(() => {
    const postId = props.rID

    const unsubscribe = listenToPostLikesChange(postId, (numberOfLikes) => {
      //console.log('Updated likes data:', numberOfLikes)
      const tempData = { ...recipesData }

      tempData.recipes[tempData.recipes.findIndex(recipe => recipe.id === props.rID)].numberOfLikes = numberOfLikes
      setRecipesData(tempData)

      //setNumberOfLikes(likesData || 0)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const setNumOfLikes = (numberOfLikes) => {
    if(numberOfLikes > 999 && numberOfLikes < 100000)
      return `${(numberOfLikes / 1000).toFixed(1).replace('.0', '')}K`

    if(numberOfLikes > 99999 && numberOfLikes < 1000000)
      return `${(numberOfLikes / 1000).toFixed(0).replace('.0', '')}K`

    else if(numberOfLikes > 999999)
      return `${(numberOfLikes / 1000000).toFixed(1).replace('.0', '')}M`


    else if(numberOfLikes === 0)
    return ''

    return numberOfLikes.toString()
  }

  return(
    <div className='recipeReactDiv'>
      <div className='recipesIcons'
      onClick={(event) => {
        const tempData = { ...recipesData }
        console.log('TEST: ' + JSON.stringify(tempData))
        if(! tempData.recipes[props.index].isReacted) {
          //console.log("++++++");
          if(navigator.vibrate !== undefined)
            navigator.vibrate(150)

          window.localStorage.setItem(`recipeReact_${tempData.recipes[props.index].id}`, '')
          tempData.recipes[props.index].isReacted = true
          setRecipesData(tempData)
          event.target.style.animation = 'toReact 700ms linear forwards'

          const localStorageArray = []

          for(let i = 0; i < window.localStorage.length; i++) {
            localStorageArray.push(window.localStorage.key(i))
          }
          //console.log(`ARRRRR!: ${JSON.stringify(localStorageArray)}`);
          updatePostLikesCount(tempData.recipes[props.index].id, 'increase')
        }
        else {
          //console.log("????????????");
          if(navigator.vibrate !== undefined)
            navigator.vibrate(50)

          window.localStorage.removeItem(`recipeReact_${tempData.recipes[props.index].id}`)
          tempData.recipes[props.index].isReacted = false
          setRecipesData(tempData)
          event.target.style.animation = 'toRemoveReact 400ms linear forwards'

          const localStorageArray = []

          for(let i = 0; i < window.localStorage.length; i++) {
            localStorageArray.push(window.localStorage.key(i))
          }
          //console.log(`ARRRRR!: ${JSON.stringify(localStorageArray)}`);
          updatePostLikesCount(tempData.recipes[props.index].id, 'decrease')
        }

        setTooltipData(
          {
            display: true,
            position: 'absolute',
            text: window.localStorage.getItem(`recipeReact_${recipesData.recipes[props.index].id}`) === null? 'react': 'reacted',
            left: window.localStorage.getItem(`recipeReact_${recipesData.recipes[props.index].id}`) === null? event.target.getBoundingClientRect().x - 10: event.target.getBoundingClientRect().x - 14,
            top: event.target.getBoundingClientRect().y - 44 + window.pageYOffset,
            right: 'auto',
            bottom: 'auto'
          }
        )
      }}
      onMouseEnter={(event) => {
        setTooltipData(
          {
            display: true,
            position: 'absolute',
            text: window.localStorage.getItem(`recipeReact_${recipesData.recipes[props.index].id}`) === null? 'react': 'reacted',
            left: window.localStorage.getItem(`recipeReact_${recipesData.recipes[props.index].id}`) === null? event.target.getBoundingClientRect().x - 10: event.target.getBoundingClientRect().x - 14,
            top: event.target.getBoundingClientRect().y - 44 + window.pageYOffset,
            right: 'auto',
            bottom: 'auto'
          }
        )
      }}

      onMouseLeave={(event) => {
        setTooltipData(
          {
            display: false
          }
        )
      }}>
        <SvgIcons id={recipesData.recipes[props.index].isReacted? 12: 11} style={{ strokeWidth: '0' }} />
      </div>
      <div className='numberOfReactsDiv'>
        {setNumOfLikes.call(this, recipesData.recipes[recipesData.recipes.findIndex(recipe => recipe.id === props.rID)].numberOfLikes)}
      </div>
    </div>
  )
}

export default ReactOption
