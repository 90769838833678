//import TopSection from '../../components/TopSection/TopSection'
import { useContext, useEffect } from 'react'
import MiddleSection from "./components/MiddleSection/MiddleSection"
import { CurrentPageUpdateContext } from '../../contexts/appContext'

function Home(props) {
  const setCurrectPage = useContext(CurrentPageUpdateContext)

  useEffect(() => {
    setCurrectPage('Home')
    window.onbeforeunload = () => sessionStorage.removeItem('groupCounter')
  }, [])

  return (
    <>
      <div id="MiddleSection">
        <MiddleSection />
      </div>
    </>
  )
}

export default Home


/*

<div className="App">
  <TopSection />
  <MiddleSection />
  <div>
  </div>
  <div style={{ position: 'fixed', bottom: '80px', right: '20px', minWidth: '36px', maxWidth: '36px', zIndex: '999999999999' }}>
    <div id='AppReactButton' className='recipesIcons'
      onClick={() => {
      if(! reacted) {
        socket.emit("reactFromClient")
        setReacted(true)
        window.localStorage.setItem('reacted', '1')
      }
    }}>
      <SvgIcons id={window.localStorage.getItem('reacted') == '1'? 12: 11} w='18' h='18' fill='#2b2b2b' stroke='#2b2b2b' strokeWidth='1'/>
    </div>
    <div style={{ textAlign: 'center' }}>
    {numOfReacts}
    </div>
  </div>
</div>

*/
