import { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { RecipesDataContext, RecipesDataUpdateContext, TooltipUpdateContext, ScreenSizeContext, CurrentPageContext } from '../../../../contexts/appContext'
import ReactOption from '../../../../components/ReactOption/ReactOption.js'
import ShareOption from '../../../../components/ShareOption/ShareOption.js'
import SvgIcons from '../../../../components/SvgIcons/SvgIcons'
import Tooltip from '../../../../components/Tooltip/Tooltip'
import RemoveSavedItemOption from '../../../../components/RemoveSavedItemOption/RemoveSavedItemOption'
import './style.scss'

function VideoActions(props) {
  const recipesData = useContext(RecipesDataContext)
  const setRecipesData = useContext(RecipesDataUpdateContext)
  const setTooltipData = useContext(TooltipUpdateContext)
  const screenSize = useContext(ScreenSizeContext)
  const currentPage = useContext(CurrentPageContext)

  useEffect(() => {
    const tempData = { ...recipesData }
    tempData.recipes[props.index].isSavedToWatchLater = window.localStorage.getItem(`recipeSavedToWatchLater_${tempData.recipes[props.index].id}`) !== null
    setRecipesData(tempData)
  }, [])

  const displayTooltip = (event, text, x, y) => {
    setTooltipData(
      {
        display: true,
        position: 'absolute',
        text: text,
        left: event.target.getBoundingClientRect().x + x,
        top: event.target.getBoundingClientRect().y + y + window.pageYOffset,
        right: 'auto',
        bottom: 'auto'
      }
    )
  }

  const hideTooltip = () => {
    setTooltipData(
      {
        display: false
      }
    )
  }

  //if(props.isOptionsDisplayed)
    return(
      <div className='videoActionsDiv'>
        <div className='actionsRowDiv'>
          <ReactOption index={props.index} rID={recipesData.recipes[props.index].id} />
          <ShareOption rID={recipesData.recipes[props.index].id} />
          {currentPage === 'Videos' &&
            <div className='videoActionIcon' onClick={(event) => {
              const savedToWatchLaterArray = window.localStorage.getItem('savedToWatchLaterItems') === null? []: JSON.parse(window.localStorage.getItem('savedToWatchLaterItems'))
              const tempData = { ...recipesData }

              if(! recipesData.recipes[props.index].isSavedToWatchLater) {
                if(navigator.vibrate !== undefined)
                  navigator.vibrate(150)
                //console.log(`vdos: ${window.localStorage.getItem('savedToWatchLaterItems')}`);
                //<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-film" viewBox="0 0 16 16">
                  //<path d="M0 1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1zm4 0v6h8V1H4zm8 8H4v6h8V9zM1 1v2h2V1H1zm2 3H1v2h2V4zM1 7v2h2V7H1zm2 3H1v2h2v-2zm-2 3v2h2v-2H1zM15 1h-2v2h2V1zm-2 3v2h2V4h-2zm2 3h-2v2h2V7zm-2 3v2h2v-2h-2zm2 3h-2v2h2v-2z"/>
                //</svg>
                const img = document.createElement('img')
                img.setAttribute('src', require(`../../../../images/${recipesData.recipes[props.index].images[4]}`))
                img.style.position = 'fixed'
                img.style.zIndex = '999999999999999999999'

                if(screenSize.width > 1024) {
                  img.style.right = '50px'
                  img.style.top = '70px'
                }

                else {
                  img.style.left = 'calc(50% - 32px)'
                  img.style.bottom = '120px'
                }
                img.style.width = '70px'
                img.style.borderRadius = '10px'
                //img.style.left = `${event.target.getBoundingClientRect().x - 10}px`
                //img.style.top = `${event.target.getBoundingClientRect().y - 80}px`

                document.body.append(img)

                if(screenSize.width > 1024)
                  img.style.animation = 'addImgToPin 1500ms linear forwards'

                else
                  img.style.animation = 'addImgToPinMobile 1500ms linear forwards'

                setTimeout(function () {
                  img.remove();
                }, 2000);

                displayTooltip.call(this, event, 'added to watch later', -45, -41)
                window.localStorage.setItem(`recipeSavedToWatchLater_${recipesData.recipes[props.index].id}`, Date.now())

                //const tempData = { ...recipesData }
                tempData.recipes[props.index].isSavedToWatchLater = true
                setRecipesData(tempData)
                savedToWatchLaterArray.splice(0, 0, props.index)
                window.localStorage.setItem('savedToWatchLaterItems', JSON.stringify(savedToWatchLaterArray))
              }

              else {
                if(navigator.vibrate !== undefined)
                  navigator.vibrate(50)
                displayTooltip.call(this, event, 'add to watch later', -41, -41)

                //const tempData = { ...recipesData }
                tempData.recipes[props.index].isSavedToWatchLater = false
                setRecipesData(tempData)

                window.localStorage.removeItem(`recipeSavedToWatchLater_${recipesData.recipes[props.index].id}`)

                savedToWatchLaterArray.splice(savedToWatchLaterArray.indexOf(props.index), 1)
                window.localStorage.setItem('savedToWatchLaterItems', JSON.stringify(savedToWatchLaterArray))
              }
            }} onMouseEnter={(event) => displayTooltip.call(this, event, window.localStorage.getItem(`recipeSavedToWatchLater_${recipesData.recipes[props.index].id}`) !== null? 'added to watch later': 'add to watch later', window.localStorage.getItem(`recipeSavedToWatchLater_${recipesData.recipes[props.index].id}`) !== null? -45: -41, -41)} onMouseLeave={hideTooltip}>
              <SvgIcons id={recipesData.recipes[props.index].isSavedToWatchLater? 29: 21} />
            </div>
          }
          <Link
          className='recipeImagInPost'
          to={`/recipe/${recipesData.recipes[props.index].id}`}
          draggable='false'
          className='videoActionIcon'
          onMouseEnter={(event) => displayTooltip.call(this, event, 'open recipe card', -35, -41)}
          onMouseLeave={hideTooltip}
          onMouseDown={hideTooltip}>
            <SvgIcons id={22} />
          </Link>
          {currentPage !== 'Videos' &&
            <RemoveSavedItemOption i={props.index} localStoragePREF='recipeSavedToWatchLater' savedItems='savedToWatchLaterItems' setRemoveData={props.setRemoveData} />
          }
        </div>
      </div>
    )

  //else {
    //return false
  //}
}

export default VideoActions
