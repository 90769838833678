import { useState, useContext, useEffect } from 'react'
//import { useNavigate } from 'react-router-dom'
import SvgIcons from '../SvgIcons/SvgIcons'
import { ThemeContext, ScreenSizeContext } from '../../contexts/appContext'
import './style.scss'

function BackButton(props) {
  const [ originPath, setOriginPath ] = useState('/home')
  const themeContext = useContext(ThemeContext)
  const screenSize = useContext(ScreenSizeContext)
  //const navigate = useNavigate()

  useEffect(() => {
    //window.alert(`props: ${props}`)
    if(sessionStorage.getItem('originPath') !== null) {
      setOriginPath(sessionStorage.getItem('originPath'))
      sessionStorage.removeItem('originPath')
    }
  }, [])

  ;

  return(
    <div id='BackButton' className={`backBotton ${themeContext}`}
    onClick={() => window.history.back()}>
      <SvgIcons id={49} />
    </div>
  )
}

export default BackButton
