import { useContext } from 'react'
import { TooltipUpdateContext, DisplayShareDataUpdateContext, ShareURLDataUpdateContext } from '../../contexts/appContext'
import SvgIcons from '../SvgIcons/SvgIcons'
import Tooltip from '../Tooltip/Tooltip'

function ShareOption(props) {
  const setTooltipData = useContext(TooltipUpdateContext)
  const setIsShareDisplayed = useContext(DisplayShareDataUpdateContext)
  const setShareURL = useContext(ShareURLDataUpdateContext)

  return(
    <>
      <div id='ShareButtonInRecipe' style={{ cursor: 'pointer', transform: 'scale(0.85) translateX(-2px)' }}
      onMouseEnter={(event) => {
        setTooltipData(
          {
            display: true,
            position: 'absolute',
            text: 'share',
            left: event.target.getBoundingClientRect().x - 12,
            top: event.target.getBoundingClientRect().y - 42 + window.pageYOffset,
            right: 'auto',
            bottom: 'auto'
          }
        )
      }}
      onMouseLeave={() => {
        setTooltipData(
          {
            display: false
          }
        )
      }}
      onClick={() => {
        setShareURL(`https://myfoodie.ca/recipe/${props.rID}`)
        setIsShareDisplayed(true)
      }}>
        <SvgIcons id={50} style={{ pointerEvents: 'none' }} />
      </div>
    </>
  )
}

export default ShareOption
