import { useEffect, useContext } from 'react'
import { ThemeContext, DisplayFollowUsDataUpdateContext } from '../../contexts/appContext'
import SvgIcons from '../SvgIcons/SvgIcons'
import Backdrop from '../Backdrop/Backdrop'
import './style.scss'

function FollowUs(props) {
  const themeContext = useContext(ThemeContext)
  const setIsFollowUsDisplayed = useContext(DisplayFollowUsDataUpdateContext)

  useEffect(() => {
    window.onkeydown = (event) => {
      if(event.key === 'Escape') {
        window.onkeydown = null
        setIsFollowUsDisplayed(false)
      }
    }
  }, [])

  const onClickHandler = () => {
    if(document.getElementById('BackButton') !== null)
      document.getElementById('BackButton').style.display = 'block'

    document.body.style.overflowY = 'auto'
    setIsFollowUsDisplayed(false)
  }

  return(
    <div id='FollowUsBox'>
      <Backdrop  onClickHandler={onClickHandler} />
      <div id='FollowUsDiv'>
        <div style={{ textAlign: 'center', color: 'white' }}>FOLLOW US</div>
        <div id='FollowUsList'>
          <a target='_blank' href='https://www.pinterest.ca/myfoodiecanada/'>
            <div id='FollowUsOnPinterestDiv'>
              <SvgIcons id={0} />
            </div>
          </a>
          <a target='_blank' href='https://www.youtube.com/@myfoodiecanada/'>
            <div id='FollowUsOnYouTubeDiv'>
              <SvgIcons id={1} />
            </div>
          </a>
          <a target='_blank' href='https://www.instagram.com/myfoodiecanada/'>
            <div id='FollowUsOnInstagramDiv'>
              <SvgIcons id={2} />
            </div>
          </a>
          <a target='_blank' href='https://www.facebook.com/myfoodiecanada/'>
            <div id='FollowUsOnFacebookDiv'>
              <SvgIcons id={3} />
            </div>
          </a>
          <a target='_blank' href='https://www.tiktok.com/@myfoodiecanada/'>
            <div id='FollowUsOnTikTokDiv'>
              <SvgIcons id={9} />
            </div>
          </a>
          <a target='_blank' href='https://twitter.com/myfoodiecanada'>
            <div id='FollowUsOnTwitterDiv'>
              <SvgIcons id={17} />
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default FollowUs
