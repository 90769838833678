import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ThemeContext, SearchTextboxDataContext, CurrentPageUpdateContext, SearchDataUpdateContext, TagsContext, TagsUpdateContext, ScreenSizeContext, SearchCounterDataContext, SearchCounterDataUpdateContext } from '../../../contexts/appContext'
import Tags from '../Tags/Tags'
import SearchSeggesions from '../SearchSeggesions/SearchSeggesions'
import SvgIcons from '../../SvgIcons/SvgIcons'
import './style.scss'

function SearchWindow(props) {
  const themeContext = useContext(ThemeContext)
  const textboxValue = useContext(SearchTextboxDataContext)
  const setSearchDataContext = useContext(SearchDataUpdateContext)
  const tags = useContext(TagsContext)
  const setTagsData = useContext(TagsUpdateContext)
  const screenSize = useContext(ScreenSizeContext)
  const searchCounter = useContext(SearchCounterDataContext)
  const setSearchCounter = useContext(SearchCounterDataUpdateContext)

  const navigate = useNavigate()
  //const indexes = new Set() ?????for some reason it return an empty object
  const indexes = []
  const tagNames = []

  const navigateToSearch = () => {
    tags.forEach(tag => {
      if(tag.isSelected) {
        tagNames.push(tag.text)
        tag.indexes.forEach(index => {
          if(!indexes.some(recordedIndex => recordedIndex === index))
            indexes.push(index)
        })
      }
    })

    //alert(`set: ${JSON.stringify(indexes)}`)

    const dataValue = JSON.stringify({type: 'tags', tagNames: tagNames, indexes: indexes})
    const encodedDataValue = encodeURIComponent(dataValue)
    const url = `/search?query=${encodedDataValue}`
    setSearchCounter(searchCounter + 1)
    navigate(url)
    screenSize.width > 1024? props.setIsSearchDivDisplayed(false): props.setIsMobileSearchDisplayed(false)
  }

  return(
    <>
      <div id="SearchResultsBackdrop">
      </div>
      <div id='SearchResultsDiv' style={props.style} className={themeContext}>
        {textboxValue === '' && <Tags />}
        {textboxValue !== '' && <SearchSeggesions setIsSearchDivDisplayed={props.setIsSearchDivDisplayed} setIsMobileSearchDisplayed={props.setIsMobileSearchDisplayed} />}
        {props.isSearchDivDisplayed &&
          <div>
            {(tags.some(item => item.isSelected)) &&
            <div id='SearchButtonForTags' className={themeContext} onClick={navigateToSearch}>
              <SvgIcons id={23} />
            </div>
          }
          </div>
        }
        {tags.some(item => item.isSelected) &&
          <div id='ClearAllTags'
            onClick={() => {
              const tempDate = [ ...tags ]

              tempDate.forEach((item, i) => {
                item.isSelected = false
              })
              setTagsData(tempDate)
            }}
          >
            clear selection
          </div>
        }
      </div>
    </>
  )
}

export default SearchWindow
