import { useContext, useEffect } from 'react'
import MiddleSection from './components/MiddleSection/MiddleSection'
import { CurrentPageUpdateContext } from '../../contexts/appContext'

function Vidoes() {
  const setCurrectPage = useContext(CurrentPageUpdateContext)

  useEffect(() => {
    setCurrectPage('Vidoes')
    window.onbeforeunload = () => sessionStorage.removeItem('groupCounter')
  }, [])

  return (
    <>
      <div id="MiddleSection">
        <MiddleSection className='inVideos' type='all' />
      </div>
    </>
  )
}

export default Vidoes
