import FollowUs from '../FollowUs/FollowUs'

function Contact() {
  return (
    <div>
      <div className='headerInSlider'>Contact Us</div>
      We are delighted to connect with you and welcome your valuable feedback, questions, and inquiries. Whether you're a passionate foodie, a business looking for advertising opportunities, or simply someone who wants to share their culinary experiences, we'd love to hear from you.
      <div className='headerInSlider'>General Inquiries</div>
      For general inquiries or to share your thoughts with us, please email us at <a href='mailto:info@myfoodie.ca'>info@myfoodie.ca</a>. Our dedicated team will get back to you promptly.
      <div className='headerInSlider'>Feedback and Suggestions</div>
      Your feedback is invaluable to us as we strive to improve and enhance your experience on MyFoodie.ca. Please feel free to share your suggestions and ideas at <a href='mailto:feedback@myfoodie.ca'>feedback@myfoodie.ca</a>. We are always eager to hear how we can make your culinary journey even more delightful.
      <div className='headerInSlider'>Partnerships and Collaborations</div>
      We believe in the power of partnerships and collaborations to create meaningful connections. If you are interested in partnering with MyFoodie.ca for events, content creation, or any other collaborative opportunities, please email us at <a href='mailto:partnerships@myfoodie.ca'>partnerships@myfoodie.ca</a>.
      <div className='headerInSlider'>Social Media</div>
      <FollowUs />
    </div>
  )
}

export default Contact
