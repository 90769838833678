import Fish from './Fish'

const YouTubeImages = [
  {
    id: 'R00001',
    base64: Fish
  }
]

export default YouTubeImages
