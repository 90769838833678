import { useEffect, useContext, useState } from 'react'
//import { useAppData } from '../../contexts/appContext'
import { ScreenSizeContext, CurrentPageContext, CurrentPageUpdateContext, ThemeContext, SearchTextboxDataContext, SearchDataUpdateContext, TagsContext, TagsUpdateContext, SearchTextboxDataUpdateContext } from '../../../contexts/appContext'
import { Link } from 'react-router-dom'
import Logo from '../../MainLogo/MainLogo'
import SvgIcons from '../../SvgIcons/SvgIcons'
import SearchSection from '../SearchSection/SearchSection'
import AppOptionsButton from '../AppOptionsButton/AppOptionsButton'
//import AppPins from '../../AppPins/AppPins'
//import AppWatchLater from '../../AppWatchLater/AppWatchLater'

import './style.scss'

function NavBar() {
  const [ backgroundColor, setBackgroundColor ] = useState('transparent')
  const [ isSearchDivDisplayed, setIsSearchDivDisplayed ] = useState(false)
  const [ isOptionsDivDisplayed, setIsOptionsDivDisplayed ] = useState(false)
  const [ isMobileSearchDisplayed, setIsMobileSearchDisplayed ] = useState(false)

  const screenSize = useContext(ScreenSizeContext)
  const currentPage = useContext(CurrentPageContext)
  const setCurrentPage = useContext(CurrentPageUpdateContext)
  const themeContext = useContext(ThemeContext)
  const setSearchDataContext = useContext(SearchDataUpdateContext)
  const textboxValue = useContext(SearchTextboxDataContext)
  const setTextboxValue = useContext(SearchTextboxDataUpdateContext)
  const tags = useContext(TagsContext)
  const setTagsData = useContext(TagsUpdateContext)

  useEffect(() => {
    if(window.location.href.toLowerCase().indexOf('/home') > -1)
      setCurrentPage('Home')

    else if(window.location.href.toLowerCase().indexOf('/recipes') > -1)
      setCurrentPage('Recipes')

    else if(window.location.href.toLowerCase().indexOf('/videos') > -1)
      setCurrentPage('Videos')

    else if(window.location.href.toLowerCase().indexOf('/search') > -1)
      setCurrentPage('Search')
  }, [currentPage])

  useEffect(() => {
    if(window.innerWidth > 1024) {
      if(themeContext === 'theme-dark')
        setBackgroundColor('rgba(0, 0, 0, 1)')

      else
        setBackgroundColor('rgba(255, 255, 255, 1)')
    }

    else
      setBackgroundColor('rgba(0, 0, 0, 0.85)')
  }, [themeContext, screenSize])



  /*const updateNav = (newIndex, newName) => {
    sessionStorage.removeItem('groupCounter')
    const tempTagsData = [ ...tags ]

    tempTagsData.forEach((item, i) => {
      item.isSelected = false
    });

    setTagsData(tempTagsData)
    setCurrentPage(newName)
    setTextboxValue('')
    document.getElementById('SearchTextbox').value = ''
    setSearchDataContext({textboxValue: '', tag: []})
    window.scrollTo(0, 0)
  }*/

  const getNavStyle = (src) => {
    if(src === currentPage) {
      if(themeContext === 'theme-dark')
        return { color: 'white', fontWeight: '500' }

      else
        return { color: 'black', fontWeight: '500' }
    }

    else {
      if(themeContext === 'theme-dark')
        return { color: 'rgba(255, 255, 255, 0.5)', fontWeight: '400' }

      else
      return { color: 'rgba(0, 0, 0, 0.5)', fontWeight: '400' }
    }
  }

  //if(screenSize.width > 1024) {
    return (
      <div id='MainNavDiv' style={{ backgroundColor: backgroundColor }}>
        <div id='NavBar'>
          {screenSize.width > 1024 && <Logo />}
          <Link className='likeButton navButton' to="/home" style={{ textDecoration: 'none', color: 'inherit' }}>
            {screenSize.width > 1024?
              <div id='HomeOptionDiv' style={getNavStyle.call(this, 'Home')} className='navOption'>Home</div>
              :
              <div className='navOptionMobile'>
                <div style={{ transform: 'scale(1.5)' }}>
                  <SvgIcons id={39} style={{ opacity: currentPage === 'Home'? '1': '0.7', strokeWidth: currentPage === 'Home'? '0.4': '0.1' }} />
                </div>
                <div className='navTextMobile' style={{ opacity: currentPage === 'Home'? '1': '0.7' }}>Home</div>
              </div>
            }
          </Link>
          <Link className='likeButton navButton' to="/recipes" style={{ textDecoration: 'none', color: 'inherit' }}>
            {screenSize.width > 1024?
              <div id='RecipesOptionDiv' style={getNavStyle.call(this, 'Recipes')} className='navOption'>Recipes</div>
              :
              <div className='navOptionMobile'>
                <div style={{position: 'relative', transform: 'scale(1.4) translate(0px, -5px)' }}>
                  <div style={{ position: 'absolute' }}>
                    <SvgIcons id={42} style={{ opacity: currentPage === 'Recipes'? '1': '0.7', strokeWidth: currentPage === 'Recipes'? '0.4': '0.1' }} />
                  </div>
                  <div style={{ position: 'absolute', transform: 'scale(0.5) translate(-8px, -3px)' }}>
                    <SvgIcons id={41} style={{ opacity: currentPage === 'Recipes'? '1': '0.7', strokeWidth: currentPage === 'Recipes'? '0.4': '0.1' }} />
                  </div>
                </div>
                <div className='navTextMobile' style={{ opacity: currentPage === 'Recipes'? '1': '0.7' }}>Recipes</div>
              </div>
            }
          </Link>
          <Link className='likeButton navButton' to="/videos" style={{ textDecoration: 'none', color: 'inherit' }}>
            {screenSize.width > 1024?
              <div id='VideosOptionDiv' style={getNavStyle.call(this, 'Videos')} className='navOption'>Videos</div>
              :
              <div className='navOptionMobile'>
                <div style={{ transform: 'scale(1.3) translate(-2px, -2px)' }}>
                  <SvgIcons id={43} style={{ opacity: currentPage === 'Videos'? '1': '0.7', strokeWidth: currentPage === 'Videos'? '0.4': '0.1' }} />
                </div>
                <div className='navTextMobile' style={{ opacity: currentPage === 'Videos'? '1': '0.7' }}>Videos</div>
              </div>
            }
          </Link>
          {screenSize.width > 1024?
            <div id='SearchDivDesktop'>
              <SearchSection isSearchDivDisplayed={isSearchDivDisplayed} setIsSearchDivDisplayed={setIsSearchDivDisplayed} isOptionsDivDisplayed={isOptionsDivDisplayed} setIsOptionsDivDisplayed={setIsOptionsDivDisplayed} />
            </div>
            :
            <a className='likeButton navButton' onClick={() => {
              setIsMobileSearchDisplayed(true)

              setTimeout(function () {
                //document.body.style.overflowY = 'hidden'
                document.getElementById('SearchTextbox').focus()

                //window.alert(`val: ${textboxValue}`)

                if(textboxValue === '')
                  document.getElementById('SearchTextbox').blur()
                //setIsSearchDivDisplayed(true)
              }, 200);
            }}>
              <div className='navOptionMobile'>
                <div style={{ transform: 'scale(1.5)', cursor: 'pointer' }}>
                  <SvgIcons id={23} style={{ opacity: currentPage === 'Search'? '1': '0.7', strokeWidth: currentPage === 'Search'? '0.4': '0.1', transform: 'translateY(2px)' }} />
                </div>
                <div className='navTextMobile' style={{ opacity: currentPage === 'Search'? '1': '0.7' }}>Search</div>
              </div>
            </a>
          }
          <span className='likeButton navButton'><AppOptionsButton isSearchDivDisplayed={isSearchDivDisplayed} setIsSearchDivDisplayed={setIsSearchDivDisplayed} isOptionsDivDisplayed={isOptionsDivDisplayed} setIsOptionsDivDisplayed={setIsOptionsDivDisplayed} /></span>
        </div>
        {screenSize.width < 1025 && isMobileSearchDisplayed &&
          <div>
            <div id='SearchDivMobile' style={{ backgroundColor: themeContext === 'theme-dark'? 'black': 'white' }}>
            </div>
            <SearchSection isSearchDivDisplayed={isSearchDivDisplayed} setIsSearchDivDisplayed={setIsSearchDivDisplayed} isOptionsDivDisplayed={isOptionsDivDisplayed} setIsOptionsDivDisplayed={setIsOptionsDivDisplayed} setIsMobileSearchDisplayed={setIsMobileSearchDisplayed} />
          </div>
        }
      </div>
    )
  /*}

  else {
    return(
      <div id='MobileMainNavDiv' style={{ zIndex: currentPage === "2"? '99999999': '1000' }}>
        <div style={{ transform: 'scale(1.5)' }}>
          <SvgIcons id={40} />
        </div>
        <div style={{ transform: 'scale(1.5)' }}>
          <SvgIcons id={42} />
          <div style={{ position: 'absolute', transform: 'scale(0.5)' }}>
            <SvgIcons id={41} />
          </div>
        </div>
        <AppOptionsButton isSearchDivDisplayed={isSearchDivDisplayed} setIsSearchDivDisplayed={setIsSearchDivDisplayed} isOptionsDivDisplayed={isOptionsDivDisplayed} setIsOptionsDivDisplayed={setIsOptionsDivDisplayed} />
        <div style={{ transform: 'scale(1.5)' }}>
          <SvgIcons id={27} />
        </div>
        <div style={{ transform: 'scale(1.5)' }}>
          <SvgIcons id={23} />
        </div>
      </div>
    )
  }*/
}

export default NavBar;

/*<AppPins />
<AppWatchLater />*/
