import { useEffect } from 'react'
import SvgIcons from '../SvgIcons/SvgIcons'
import './style.scss'

function Backdrop(props) {
  useEffect(() => {
    if(document.getElementById('BackButton') !== null)
      document.getElementById('BackButton').style.display = 'none'
  }, [])
  return(
    <div className='backdrop' onClick={props.onClickHandler}>
      <span className='existButtonInBackdrop'>
        <SvgIcons id={47} style={{ stroke: 'white', strokeWidth: '0.1' }} />
      </span>
    </div>
  )
}

export default Backdrop
