import { useContext, useEffect } from 'react'
import Recipes from '../../components/Recipes/Recipes'
import { CurrentPageUpdateContext,  } from '../../contexts/appContext'
import './style.scss'

function RecipesPage(props) {
  const setCurrectPage = useContext(CurrentPageUpdateContext)

  useEffect(() => {
    setCurrectPage('Recipes')
    window.onbeforeunload = () => sessionStorage.removeItem('groupCounter')
  }, [])

  return (
    <>
      <div id="MiddleSection">
        <Recipes type='all' />
      </div>
    </>
  )
}

export default RecipesPage
