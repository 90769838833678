import { useContext, useEffect } from 'react'
import { ThemeContext, TooltipContext, TooltipUpdateContext, ScreenSizeContext } from '../../contexts/appContext'
import './style.scss'

function TooTip(props) {
  const themeContext = useContext(ThemeContext)
  const tooltipData = useContext(TooltipContext)
  const setTooltipData = useContext(TooltipUpdateContext)
  const screenSize = useContext(ScreenSizeContext)

  const hideTooltip = () => {
    document.getElementById('PinnedListDiv').onscroll = null

    setTooltipData({
      display: false
    })
  }

  useEffect(() => {
    if(document.getElementById('PinnedListDiv') !== null) {
      document.getElementById('PinnedListDiv').onscroll = () => {
        hideTooltip.call(this)
      }
    }
  }, [])

  if(tooltipData.text === 'views' || screenSize.width > 1024)
    return(
      <div id='ButtonOptionTooltip' style={
        {
          position: tooltipData.position,
          display: tooltipData.display? 'block': 'none',
          backgroundColor: themeContext === 'theme-dark'? 'white': 'black',
          color: themeContext === 'theme-dark'? 'black': 'white',
          left: tooltipData.left + 'px',
          right: tooltipData.right + 'px',
          top: tooltipData.top + 'px',
          bottom: tooltipData.bottom + 'px'
        }
      }>
        {tooltipData.text}
      </div>
    )
  else {
    return false
  }
}

export default TooTip
