import { useState, createContext, useContext } from "react"
//import { RecipesData } from '../data/recipesData'

/*const appDefaultData = {
  currentPageIndex: 0,
  currentPageName: 'Home',
  screenWidth: 0,
  screenHeight: 0
}*/

const defaultSearchData = {
  textboxValue: '',
  tags: []
}

const tooltipDefaultData = {
  display: false,
  position: 'fixed',
  text: 'Default Value',
  left: '0',
  top: '0',
  right: 'auto',
  bottom: 'auto'
}

//export const AppDataContext = createContext(appDefaultData)
//export const AppDataUpdateContext = createContext()
export const ScreenSizeContext = createContext({width: 0, height: 0})
export const ScreenSizeUpdateContext = createContext()
export const CurrentPageContext = createContext('Home')
export const CurrentPageUpdateContext = createContext()
export const SearchDataContext = createContext(defaultSearchData)
export const SearchDataUpdateContext = createContext()
export const RecipesDataContext = createContext({recipes: []})
export const RecipesDataUpdateContext = createContext()
export const DisplayFollowUsDataContext = createContext(null)
export const DisplayFollowUsDataUpdateContext = createContext()
export const DisplayMoreDataContext = createContext(null)
export const DisplayMoreDataUpdateContext = createContext()
export const SavedVideosDataContext = createContext([])
export const SavedVideosDataUpdateContext = createContext()
export const WorkerContext = createContext(null)
export const WorkerUpdateContext = createContext()
export const ThemeContext = createContext('theme-dark')
export const ThemeUpdateContext = createContext()
export const TooltipContext = createContext(tooltipDefaultData)
export const TooltipUpdateContext = createContext()
export const SeachSuggesionContext = createContext([])
export const SeachSuggesionUpdateContext = createContext()
export const TagsContext = createContext([])
export const TagsUpdateContext = createContext()
export const SearchSuggesionsDataContext = createContext([])
export const SearchSuggesionsDataUpdateContext = createContext()
export const SearchTextboxDataContext = createContext('')
export const SearchTextboxDataUpdateContext = createContext()
export const DisplayShareDataContext = createContext(null)
export const DisplayShareDataUpdateContext = createContext()
export const ShareURLDataContext = createContext(null)
export const ShareURLDataUpdateContext = createContext()
export const SearchCounterDataContext = createContext(null)
export const SearchCounterDataUpdateContext = createContext()
export const DisplayLoadingDataContext = createContext(null)
export const DisplayLoadingDataUpdateContext = createContext()
export const DisplayInitLoadingDataContext = createContext(null)
export const DisplayInitLoadingDataUpdateContext = createContext()
export const DisplayMediaPlayerDataContext = createContext(null)
export const DisplayMediaPlayerDataUpdateContext = createContext()
export const MediaPlayerURLDataContext = createContext(null)
export const MediaPlayerURLDataUpdateContext = createContext()

export function AppDataProvider({ children }) {
  //const [appData, setAppData] = useState(appDefaultData)
  const [screenSize, setScreenSize] = useState({width: 0, height: 0})
  const [currentPage, setCurrentPage] = useState('Home')
  const [searchData, setSearchData] = useState(defaultSearchData)
  const [recipesData, setRecipesData] = useState({recipes: []})
  const [isFollowUsDisplayed, setIsFollowUsDisplayed] = useState(false)
  const [isMoreDisplayed, setIsMoreDisplayed] = useState(false)
  const [savedVideosData, setSavedVideosData] = useState([])
  const [worker, setWorker] = useState(null)
  const [theme, setSetTheme] = useState('theme-dark')
  const [tooltipData, setTooltipData] = useState(tooltipDefaultData)
  const [searchSuggsion, setSearchSuggsion] = useState([])
  const [tags, setTags] = useState([])
  const [searchSuggesions, setSearchSuggesions] = useState([])
  const [searchTextboxData, setSearchTextboxData] = useState('')
  const [ isShareDisplayed, setIsShareDisplayed ] = useState(false)
  const [ shareURL, setShareURL ] = useState('')
  const [ searchCounter, setSearchCounter ] = useState(0)
  const [isLoading, setIsLoading] = useState(true);
  const [ isInitLoading, setIsInitLoading ] = useState(true)
  const [ isMediaPlayerDisplayed, setIsMediaPlayerDisplayed ] = useState(false)
  const [ mediaPlayerURL, setMediaPlayerURL ] = useState('')

  return (
    //<AppDataContext.Provider value={appData}>
      //<AppDataUpdateContext.Provider value={setAppData}>
        <SearchDataContext.Provider value={searchData}>
          <SearchDataUpdateContext.Provider value={setSearchData}>
            <RecipesDataContext.Provider value={recipesData}>
              <RecipesDataUpdateContext.Provider value={setRecipesData}>
                <DisplayFollowUsDataContext.Provider value={isFollowUsDisplayed}>
                  <DisplayFollowUsDataUpdateContext.Provider value={setIsFollowUsDisplayed}>
                    <DisplayMoreDataContext.Provider value={isMoreDisplayed}>
                      <DisplayMoreDataUpdateContext.Provider value={setIsMoreDisplayed}>
                        <SavedVideosDataContext.Provider value={savedVideosData}>
                          <SavedVideosDataUpdateContext.Provider value={setSavedVideosData}>
                            <WorkerContext.Provider value={worker}>
                              <WorkerUpdateContext.Provider value={setWorker}>
                                <ThemeContext.Provider value={theme}>
                                  <ThemeUpdateContext.Provider value={setSetTheme}>
                                    <TooltipContext.Provider value={tooltipData}>
                                      <TooltipUpdateContext.Provider value={setTooltipData}>
                                        <SeachSuggesionContext.Provider value={searchSuggsion}>
                                          <SeachSuggesionUpdateContext.Provider value={setSearchSuggsion}>
                                            <TagsContext.Provider value={tags}>
                                              <TagsUpdateContext.Provider value={setTags}>
                                                <SearchSuggesionsDataContext.Provider value={searchSuggesions}>
                                                  <SearchSuggesionsDataUpdateContext.Provider value={setSearchSuggesions}>
                                                    <SearchTextboxDataContext.Provider value={searchTextboxData}>
                                                      <SearchTextboxDataUpdateContext.Provider value={setSearchTextboxData}>
                                                        <ScreenSizeContext.Provider value={screenSize}>
                                                          <ScreenSizeUpdateContext.Provider value={setScreenSize}>
                                                            <CurrentPageContext.Provider value={currentPage}>
                                                              <CurrentPageUpdateContext.Provider value={setCurrentPage}>
                                                                <DisplayShareDataContext.Provider value={isShareDisplayed}>
                                                                  <DisplayShareDataUpdateContext.Provider value={setIsShareDisplayed}>
                                                                    <ShareURLDataContext.Provider value={shareURL}>
                                                                      <ShareURLDataUpdateContext.Provider value={setShareURL}>
                                                                        <SearchCounterDataContext.Provider value={searchCounter}>
                                                                          <SearchCounterDataUpdateContext.Provider value={setSearchCounter}>
                                                                            <DisplayLoadingDataContext.Provider value={isLoading}>
                                                                              <DisplayLoadingDataUpdateContext.Provider value={setIsLoading}>
                                                                                <DisplayInitLoadingDataContext.Provider value={isInitLoading}>
                                                                                  <DisplayInitLoadingDataUpdateContext.Provider value={setIsInitLoading}>
                                                                                    <DisplayMediaPlayerDataContext.Provider value={isMediaPlayerDisplayed}>
                                                                                      <DisplayMediaPlayerDataUpdateContext.Provider value={setIsMediaPlayerDisplayed}>
                                                                                        <MediaPlayerURLDataContext.Provider value={mediaPlayerURL}>
                                                                                          <MediaPlayerURLDataUpdateContext.Provider value={setMediaPlayerURL}>
                                                                                            {children}
                                                                                          </MediaPlayerURLDataUpdateContext.Provider>
                                                                                        </MediaPlayerURLDataContext.Provider>
                                                                                      </DisplayMediaPlayerDataUpdateContext.Provider>
                                                                                    </DisplayMediaPlayerDataContext.Provider>
                                                                                  </DisplayInitLoadingDataUpdateContext.Provider>
                                                                                </DisplayInitLoadingDataContext.Provider>
                                                                              </DisplayLoadingDataUpdateContext.Provider>
                                                                            </DisplayLoadingDataContext.Provider>
                                                                          </SearchCounterDataUpdateContext.Provider>
                                                                        </SearchCounterDataContext.Provider>
                                                                      </ShareURLDataUpdateContext.Provider>
                                                                    </ShareURLDataContext.Provider>
                                                                  </DisplayShareDataUpdateContext.Provider>
                                                                </DisplayShareDataContext.Provider>
                                                              </CurrentPageUpdateContext.Provider>
                                                            </CurrentPageContext.Provider>
                                                          </ScreenSizeUpdateContext.Provider>
                                                        </ScreenSizeContext.Provider>
                                                      </SearchTextboxDataUpdateContext.Provider>
                                                    </SearchTextboxDataContext.Provider>
                                                  </SearchSuggesionsDataUpdateContext.Provider>
                                                </SearchSuggesionsDataContext.Provider>
                                              </TagsUpdateContext.Provider>
                                            </TagsContext.Provider>
                                          </SeachSuggesionUpdateContext.Provider>
                                        </SeachSuggesionContext.Provider>
                                      </TooltipUpdateContext.Provider>
                                    </TooltipContext.Provider>
                                  </ThemeUpdateContext.Provider>
                                </ThemeContext.Provider>
                              </WorkerUpdateContext.Provider>
                            </WorkerContext.Provider>
                          </SavedVideosDataUpdateContext.Provider>
                        </SavedVideosDataContext.Provider>
                      </DisplayMoreDataUpdateContext.Provider>
                    </DisplayMoreDataContext.Provider>
                  </DisplayFollowUsDataUpdateContext.Provider>
                </DisplayFollowUsDataContext.Provider>
              </RecipesDataUpdateContext.Provider>
            </RecipesDataContext.Provider>
          </SearchDataUpdateContext.Provider>
        </SearchDataContext.Provider>
      //</AppDataUpdateContext.Provider>
    //</AppDataContext.Provider>
  )
}
