import { useContext, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'
import { RecipesDataContext, ThemeContext } from '../../../../contexts/appContext'
import NumberOfViews from '../../../../components/NumberOfViews/NumberOfViews'
import ReactOption from '../../../../components/ReactOption/ReactOption'
import PinOption from '../../../../components/PinOption/PinOption'
import ShareOption from '../../../../components/ShareOption/ShareOption'
import SvgIcons from '../../../../components/SvgIcons/SvgIcons'
import './style.scss'

function Posts(props) {
  const { ref, inView, entry } = useInView({
    threshold: 0.2,
    triggerOnce: true
  })

  const [ isShareDisplayed, setIsShareDisplayed ] = useState(false)

  const recipesData = useContext(RecipesDataContext)
  const themeContext = useContext(ThemeContext)

  useEffect(() => {
    if(inView && props.groupCounter < recipesData.recipes.length) {
      props.setGroupCounter(props.groupCounter + 5)
      sessionStorage.setItem('groupCounter', props.groupCounter + 5)
    }
  }, [inView])

  const getTimeDifference = (dateValue) => {
    const givenDate = new Date(dateValue)
    const currentDate = new Date()

    const timeDifference = currentDate - givenDate
    const secondsDifference = Math.floor(timeDifference / 1000)
    const minutesDifference = Math.floor(secondsDifference / 60)
    const hoursDifference = Math.floor(minutesDifference / 60)
    const daysDifference = Math.floor(hoursDifference / 24)
    const monthsDifference = Math.floor(daysDifference / 30)
    const yearsDifference = Math.floor(monthsDifference / 12)

    if (secondsDifference < 60) {
        return `${secondsDifference} sec. ago`
    } else if (minutesDifference < 2) {
        return `1 min. ago`
    } else if (minutesDifference < 60) {
        return `${minutesDifference} min. ago`
    } else if (hoursDifference < 2) {
        return `1 hr. ago`
    } else if (hoursDifference < 24) {
        return `${hoursDifference} hr. ago`
    } else if (daysDifference === 1) {
        return `1 day ago`
    } else if (daysDifference < 30) {
        return `${daysDifference} days ago`
    } else if (monthsDifference === 1) {
        return `1 month ago`
    } else if (monthsDifference < 12) {
        return `${monthsDifference} months ago`
    } else if (yearsDifference === 1) {
        return `1 year ago`
    } else {
        return `${yearsDifference} years ago`
    }
  }

// Given date and time
//const givenDateTime = "2023-11-24T19:00" // Custom date and time

//const timeDifferenceOutput = getTimeDifference(givenDateTime)
//console.log(`The time difference between ${givenDateTime} and now is approximately ${timeDifferenceOutput}.`)


  return(
    <div ref={(props.i + 1) === props.groupCounter && props.groupCounter < recipesData.recipes.length? ref: null} className={ `post ${themeContext}` }>
      <div style={{ position: 'relative', display: 'flex', margin: '5px', gap: '5px' }}>
        <div style={{ width: '40px' }}>
          <img src={require('../../../../images/Logo-Dark-600px.png')} alt='myfoodie.ca logo' draggable={false} />
        </div>
        <div style={{ marginTop: '5px' }}>
          myfoodie.ca
        </div>
        <div style={{ position: 'absolute', right: '10px', top: '10px', fontSize: '12px', color: 'rgb(178, 178, 178)', fontWeight: '200' }}>
          {getTimeDifference.call(this, recipesData.recipes[props.index].postedOn)}
        </div>
      </div>
      <Link className='recipeImagInPost' to={`/recipe/${recipesData.recipes[props.index].id}`} draggable='false'>
        <img src={require(`../../../../images/${recipesData.recipes[props.index].images[2]}`)} alt={recipesData.recipes[props.index].altText} draggable={false} />
      </Link>
      <div style={{ fontSize: '22px', marginLeft: '5px', marginTop: '10px' }}>
        {recipesData.recipes[props.index].name}
      </div>
      <div style={{ fontSize: '14px', marginLeft: '5px', fontWeight: '200', opacity: '0.8' }}>
        {recipesData.recipes[props.index].title}
      </div>
      <div className='postIcons'>
        <ReactOption index={props.index} rID={recipesData.recipes[props.index].id} />
        <PinOption index={props.index} rID={recipesData.recipes[props.index].id} />
        <ShareOption rID={recipesData.recipes[props.index].id} publicImgURL={recipesData.recipes[props.index].images[5]} />
        <NumberOfViews index={props.index} rID={recipesData.recipes[props.index].id} />
        <div className='bottomRightPost'>
          <div className='originPost'>
            <SvgIcons id={26} /><div>{recipesData.recipes[props.index].tags[0]}</div>
          </div>
          <div className='totalTimePost'>
            <SvgIcons id={15} /><div>{recipesData.recipes[props.index].totalTime}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Posts
