import { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { RecipesDataContext, ThemeContext, WorkerContext, DisplayShareDataUpdateContext, ShareURLDataUpdateContext, DisplayFollowUsDataUpdateContext, DisplayMoreDataContext, DisplayMoreDataUpdateContext, ScreenSizeContext } from '../../../contexts/appContext'
import Theme from '../Theme/Theme'
import SvgIcons from '../../SvgIcons/SvgIcons'
import './style.scss'

function AppOptions(props) {
  const recipesData = useContext(RecipesDataContext)
  const themeContext = useContext(ThemeContext)
  const worker = useContext(WorkerContext)
  const setIsShareDisplayed = useContext(DisplayShareDataUpdateContext)
  const setShareURL = useContext(ShareURLDataUpdateContext)
  const setIsFollowUsDisplayed = useContext(DisplayFollowUsDataUpdateContext)
  const setIsMoreDisplayed = useContext(DisplayMoreDataUpdateContext)
  //const screenSize = useContext(ScreenSizeContext)

  /*useEffect(() => {
    setTimeout(function () {
      document.getElementById('OptionsDiv').style.animation = 'none'
    }, 1000);

  }, [])*/

  return(
    <div id='OptionsDiv' className={themeContext}>
      <Link to='/pinned' className='optionRow'>
        <div className='iconInOption'><SvgIcons id={6} /></div>
        <div className='textInOption'>Pinned items</div>
      </Link>
      <Link to='/watchlater' className='optionRow'>
        <div className='iconInOption'><SvgIcons id={21} /></div>
        <div className='textInOption'>Watch later</div>
      </Link>
      <Theme />
      <div className='optionRow'
        onClick={() => {
          document.body.style.overflowY = 'hidden'
          setShareURL('https://myfoodie.ca')
          setIsShareDisplayed(true)
          //props.setIsShareDisplayed(true)
        }}
      >
        <div className='iconInOption' style={{ transform: 'scale(0.85) translateX(-2px)' }}><SvgIcons id={50} style={{ fill: 'transparent', stroke: themeContext === 'theme-dark'? 'rgb(190, 190, 190)': 'rgb(34, 34, 34)', strokeWidth: '1' }} /></div>
        <div className='textInOption'>Share</div>
      </div>
      <div className='optionRow' style={{ order: 5}}
        onClick={() => {
          document.body.style.overflowY = 'hidden'
          setIsFollowUsDisplayed(true)
        }}
       >
        <div className='iconInOption' style={{ transform: 'rotate(-25deg)' }}><SvgIcons id={35} /></div>
        <div className='textInOption'>Follow us</div>
      </div>
      <div className='optionRow' style={{ order: 6 }}
        onClick={() => {
          document.body.style.overflowY = 'hidden'
          setIsMoreDisplayed(true)
        }}
      >
        <div className='iconInOption' style={{ transform: 'scale(1.2) translate(1px, 1px)' }}><SvgIcons id={53} /></div>
        <div className='textInOption'>More</div>
      </div>
    </div>
  )
}

export default AppOptions
