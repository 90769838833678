import { useContext } from 'react'
import { TagsContext, TagsUpdateContext, ScreenSizeContext } from '../../../contexts/appContext'
import Tag from '../Tag/Tag'
import './style.scss'

function Tags() {
  const tags = useContext(TagsContext)
  const screenSize = useContext(ScreenSizeContext)

  return(
    <div id='TagsDiv'>
      <div className='tagsTitle'>CUISINE</div>
      <div className='tags'>
        {
          tags.map((item, i) => {
            if(item.type === 'cuisine') {
              return(
                <Tag key={i} index={i} />
              )
            }
          })
        }
      </div>
      <div className='tagsTitle'>MEAL</div>
      <div className='tags'>
        {
          tags.map((item, i) => {
            if(item.type === 'meal') {
              return(
                <Tag key={i} index={i} />
              )
            }
          })
        }
      </div>
      <div className='tagsTitle'>DISH TYPE</div>
      <div className='tags'>
        {
          tags.map((item, i) => {
            if(item.type === 'dish type') {
              return(
                <Tag key={i} index={i} />
              )
            }
          })
        }
      </div>
      <div className='tagsTitle'>SPECIAL DIET</div>
      <div className='tags'>
        {
          tags.map((item, i) => {
            if(item.type === 'special diet') {
              return(
                <Tag key={i} index={i} />
              )
            }
          })
        }
      </div>
      <div className='tagsTitle'>OCCASION</div>
      <div className='tags'>
        {
          tags.map((item, i) => {
            if(item.type === 'occasion') {
              return(
                <Tag key={i} index={i} />
              )
            }
          })
        }
      </div>
      <div className='tagsTitle'>COOKING METHOD</div>
      <div className='tags'>
        {
          tags.map((item, i) => {
            if(item.type === 'cooking method') {
              return(
                <Tag key={i} index={i} />
              )
            }
          })
        }
      </div>
      {screenSize.width < 1025 &&
        <div style={{ minHeight: '300px', pointerEvents: 'none' }}></div>
      }
    </div>
  )
}

export default Tags
