import { useContext, useEffect, useState } from 'react'
import { FacebookShareButton, PinterestShareButton } from 'react-share'
import { DisplayShareDataUpdateContext, RecipesDataContext,  ShareURLDataContext, ScreenSizeContext } from '../../contexts/appContext'
import SvgIcons from '../SvgIcons/SvgIcons'
import Backdrop from '../Backdrop/Backdrop'
import './style.scss'

function Share(props) {
  const [ isCopySectionDisplayed, setIsCopySectionDisplayed ] = useState(false)
  //const [ emailObject, setEmailObject ] = useState({subject: '', body: ''})

  const setIsShareDisplayed = useContext(DisplayShareDataUpdateContext)
  const recipesData = useContext(RecipesDataContext)
  const shareURL = useContext(ShareURLDataContext)
  const screenSize = useContext(ScreenSizeContext)

  async function writeToClipboard(shareURL) {
    try {
        if (navigator.clipboard) {
            await navigator.clipboard.writeText(shareURL);
            console.log('URL copied to clipboard successfully!');
        } else {
            console.log('Clipboard API not available');
        }
    } catch (err) {
        console.error('Failed to copy:', err);
    }
  }

  useEffect(() => {
    //alert(shareURL)
    document.body.style.overflowY = 'hidden'
    window.twttr.widgets.load(
      document.getElementById("TwitterPlaceholder")
    )
    //alert(window.twttr.ready())

    window.twttr.widgets.createShareButton(
    shareURL,
    document.getElementById('TwitterPlaceholder'),
    {
      text: 'Try this recipe! '
    }
  )
  //setEmailObject({subject: 'Shared From', body: 'Ahmad <b>AAAA</b>'})
  }, [])

  const onClickHandler = () => {
    if(document.getElementById('BackButton') !== null)
      document.getElementById('BackButton').style.display = 'block'

    document.body.style.overflowY = 'auto'
    setIsShareDisplayed(false)
  }

  const handlePinterestShare = () => {
    const url = 'https://www.pinterest.com/pin/create/button/?url=' +
                encodeURIComponent(window.location.href) +
                '&media=' + encodeURIComponent(recipesData.recipes[1].images[5]) +
                '&description=' + encodeURIComponent('Your Page Description')

    window.open(url, '_blank', 'width=750,height=550')
  }

  return(
    <div id='ShareDiv'>
      <Backdrop  onClickHandler={onClickHandler} />
      <div id='ShareBodyDiv' style={{ top: isCopySectionDisplayed? 'calc(50% - 63px)': 'calc(50% - 0px)' }}>
        <div style={{ textAlign: 'center' }}>Share</div>
        <div className='iconsSection'>
          <div className='recipesShareIcons'>
            <a target='_blank '
            data-pin-do="buttonPin"
            data-pin-count="beside"
            data-pin-custom="true"
            data-pin-save="false"
            href="https://www.pinterest.com/pin/create/button/?url=https://www.pinterest.ca/pin/997758492423293864/&media=https://i.pinimg.com/750x/f4/fc/4a/f4fc4a1187b98932075befa5eba6c715.jpg&description=So%20delicious!"
            data-pin-custom="true"
            >
              <SvgIcons id={0} />
            </a>

            <PinterestShareButton url={'https://myfoodie.ca'} media={'https://i.pinimg.com/750x/f4/fc/4a/f4fc4a1187b98932075befa5eba6c715.jpg'} description={'description'} />
          </div>
          <FacebookShareButton url={shareURL} quote={'quote'}>
            <div className="fb-share-button recipesShareIcons">
              <SvgIcons id={3} />
            </div>
          </FacebookShareButton>
          <div id='AppTwitterShare' className='recipesShareIcons'>
            <div onClick={() => {
              document.getElementById('TwitterPlaceholder').click()
            }}>
              <SvgIcons id={17} />
            </div>
            <div id='TwitterPlaceholder' style={{ position: 'absolute', transform: 'scale(0.2, 1)', left: '-30px', top: '0', opacity: '0' }}>
            </div>
          </div>
          <a className='recipesShareIcons'
            href={`mailto:?&subject=&body=${shareURL}`}>
            <SvgIcons id={54} />
          </a>
          <div className='recipesShareIcons' onClick={() => setIsCopySectionDisplayed(true)}>
            <SvgIcons id={18} />
          </div>
        </div>
        {isCopySectionDisplayed &&
          <div id='URLInShare' style={{ maxWidth: shareURL === 'https://myfoodie.ca'? '200px': '300px' }}>
            <span>{shareURL}</span>
            <div onClick={(e) => {
              writeToClipboard(shareURL)
              //window.alert(navigator.clipboard)
              //if(navigator.clipboard !== undefined)
              //  navigator.clipboard.writeText(shareURL)

              e.target.style.pointerEvents = 'none'
              const msgDiv = document.getElementById('ClipboardMSGConfrim')

              msgDiv.style.animation = screenSize.width > 1024? 'bringUpConfirmation 200ms linear forwards': 'bringUpConfirmationSMLScrn 200ms linear forwards'

              setTimeout(function () {
                e.target.style.pointerEvents = 'auto'
                  msgDiv.style.animation = screenSize.width > 1024? 'bringDownConfirmation 200ms linear forwards': 'bringDownConfirmationSMLScrn 200ms linear forwards'
              }, 4000);
            }}>
              Copy
            </div>
          </div>
        }
      </div>
      <div id='ClipboardMSGConfrim'>
        Link copied to clipboard
      </div>
    </div>
  )
}

export default Share
