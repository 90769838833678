import { useEffect, useState, useContext } from 'react'
import { fetchPostsOnce } from './firebase'
import { recipiesObj } from './data/recipesData'
import { Route, Routes, Navigate, useLocation } from "react-router-dom"
import { ScreenSizeContext, ScreenSizeUpdateContext, RecipesDataContext, RecipesDataUpdateContext, WorkerUpdateContext, ThemeContext, ThemeUpdateContext, SavedVideosDataUpdateContext, TooltipContext, TagsUpdateContext, SearchSuggesionsDataUpdateContext, DisplayShareDataContext, DisplayFollowUsDataContext, DisplayMoreDataContext, CurrentPageContext, DisplayLoadingDataContext, DisplayLoadingDataUpdateContext, DisplayInitLoadingDataUpdateContext, DisplayMediaPlayerDataContext, MediaPlayerURLDataContext } from './contexts/appContext'
import NavBar from './components/TopSection/NavBar/NavBar'
import AppReact from './components/AppReact/AppReact'
import Tooltip from './components/Tooltip/Tooltip'
import Home from './pages/Home/Home'
import Recipes from './pages/Recipes/Recipes'
import Videos from './pages/Videos/Videos'
import Recipe from './pages/Recipe/Recipe'
import Search from './pages/Search/Search'
import Pinned from './pages/Pinned/Pinned'
import WatchLater from './pages/WatchLater/WatchLater'
import Share from './components/Share/Share'
import FollowUs from './components/FollowUs/FollowUs'
import MoreOption from './components/MoreOption/MoreOption'
import BackButton from './components/BackButton/BackButton'
import Loading from './components/Loading/Loading'
import MediaPlayer from './components/MediaPlayer/MediaPlayer'

function App() {
  const [ reacted, setReacted ] = useState(false)
  const [ worker, setWorker ] = useState(null)
  const [posts, setPosts] = useState({})

  const location = useLocation()

  const screenSize = useContext(ScreenSizeContext)
  const setScreenSize = useContext(ScreenSizeUpdateContext)
  const recipesData = useContext(RecipesDataContext)
  const setRecipesData = useContext(RecipesDataUpdateContext)
  const setWorkerInContext = useContext(WorkerUpdateContext)
  const themeContext = useContext(ThemeContext)
  const setThemeContext = useContext(ThemeUpdateContext)
  //const setSavedVideosData = useContext(SavedVideosDataUpdateContext)
  const tooltipData = useContext(TooltipContext)
  const setTagsData = useContext(TagsUpdateContext)
  const setSuggestionsData = useContext(SearchSuggesionsDataUpdateContext)
  const isShareDisplayed = useContext(DisplayShareDataContext)
  const isFollowUsDisplayed = useContext(DisplayFollowUsDataContext)
  const isMoreDisplayed = useContext(DisplayMoreDataContext)
  const isLoading = useContext(DisplayLoadingDataContext)
  const setIsLoading = useContext(DisplayLoadingDataUpdateContext)
  const setIsInitLoading = useContext(DisplayInitLoadingDataUpdateContext)
  const isMediaPlayerDisplayed = useContext(DisplayMediaPlayerDataContext)
  const mediaPlayerURL = useContext(MediaPlayerURLDataContext)

  //const  = useContext(DisplayBackButtonDataContext)
  //const  = useContext(DisplayBackButtonDataUpdateContext)
  const currentPage = useContext(CurrentPageContext)

  let workerObj
  const localStorageArray = []

  const fetchPostsData = () => {
    //console.log("!!!:: " + JSON.stringify(updatedPostsData));
    fetchPostsOnce()
      .then((postsData) => {
        if (postsData) {
          // Handle the fetched "posts" data
          //console.log('Fetched "posts" object:', postsData);
          workerObj.postMessage({type: 'updateRecipesValues', localStorageArray: localStorageArray, postsData: postsData})
          //workerObj.postMessage({type: 'updateRecipesLikesAndViews', recipesData: recipesData, postsData: postsData})
          // Set the "posts" data in your component state or context if needed
        } else {
          console.log('No "posts" data found.');
        }
      })
      .catch((error) => {
        console.error('Error fetching "posts":', error);
      });
  }

  async function simpleAsyncFunction() {
    const tempData = { ...recipiesObj }
    try {
      const result = await new Promise((resolve) => {
        fetchPostsOnce()
          .then((postsData) => {
            if (postsData) {
              // Handle the fetched "posts" data
              console.log('Fetched "posts" object:', postsData);
              console.log("DDD" + JSON.stringify(recipesData));

              for(let i = 0; i < window.localStorage.length; i++) {
                localStorageArray.push(window.localStorage.key(i))
              }

              console.log(tempData);
              tempData.recipes.forEach((item, i) => {
                tempData.recipes[i].numberOfLikes = postsData[tempData.recipes[i].id].numberOfLikes
                tempData.recipes[i].numberOfViews = postsData[tempData.recipes[i].id].numberOfViews
                tempData.recipes[i].isReacted = localStorageArray.some(item => item.indexOf(`recipeReact_${tempData.recipes[i].id}`) === 0)
                tempData.recipes[i].isPinned = localStorageArray.some(item => item.indexOf(`recipePin_${tempData.recipes[i].id}`) === 0)
                tempData.recipes[i].isSavedToWatchLater = localStorageArray.some(item => item.indexOf(`recipeSavedToWatchLater_${tempData.recipes[i].id}`) === 0)
              })

              //workerObj.postMessage({type: 'updateRecipesValues', localStorageArray: localStorageArray, postsData: postsData})
              //workerObj.postMessage({type: 'updateRecipesLikesAndViews', recipesData: recipesData, postsData: postsData})
              // Set the "posts" data in your component state or context if needed
            } else {
              console.log('No "posts" data found.');
            }
          })
          .catch((error) => {
            console.error('Error fetching "posts":', error);
          })


        setRecipesData(tempData)
      })

      console.log(result)
    } catch (error) {
      setRecipesData(tempData)
      console.error('Error in async operation:', error)
    }
  }

  useEffect(() => {
    //alert("AA: " + getAppLikes.call(this))
    if(window.localStorage.getItem('appTheme') === 'theme-light') {
      setThemeContext('theme-light')
      document.body.style.backgroundColor = '#eeeeee'
    }

    setRecipesData(recipiesObj)
    simpleAsyncFunction()

  workerObj = new Worker(new URL("./components/WebWorker/WebWorker.js", import.meta.url))
  workerObj.postMessage({type: 'updateSearchDataContext'})
  workerObj.onmessage = (message) => {
    if(message.data.type === 'updateSearchDataContext') {
      setTagsData(message.data.tagsArray)
      setSuggestionsData(message.data.searchSuggesionsArray)
    }
  }

  /*
      workerObj.onmessage = (message) => {
        //console.log(`mexx: ${message.data.type}`);

        if(message.data.type === 'updateRecipesData') {
          setRecipesData(message.data.recipesData)
          //console.log(`##: ${JSON.stringify(message.data.recipesData)}`);
        }

        else if(message.data.type === 'updatePinnedList') {
          console.log(`dddaaa**:: ${JSON.stringify(message.data.pinnedListData)}`);
          setPinnedListData(message.data.pinnedListData)
        }

        else if(message.data.type === 'updateRecipesLocalStorage') {
          setSavedVideosData(message.data.savedVideosData)
        }

        else if(message.data.type === 'updateSearchDataContext') {
          setTagsData(message.data.tagsArray)
          setSuggestionsData(message.data.searchSuggesionsArray)
        }
      }


    //workerObj.postMessage({type: 'updateRecipesLocalStorage', localStorageArray: localStorageArray, recipesData: recipesData})

    workerObj.postMessage({type: 'updateRecipesData'})

    fetchPostsData.call(this)
    //workerObj.postMessage({type: 'getPinnedList', localStorageArray: localStorageArray})

    setWorker(workerObj)
    setWorkerInContext(workerObj)*/

    // Clean up the listener when component unmounts or changes

    if(window.localStorage.getItem('appReacted') === '1')
      setReacted(true)

      let screenS = {width: window.innerWidth, height: window.innerHeight}
      setScreenSize(screenS)

    window.onresize = () => {
      //alert(`width: ${window.innerWidth}, height: ${window.innerHeight}`)
      let screenS = {width: window.innerWidth, height: window.innerHeight}
      setScreenSize(screenS)
    }

    document.body.style.overflowY = 'hidden'
    //document.getElementById('LoadingDiv').style.animation = 'changeOpacity 1.5s linear forwards'
    setTimeout(function () {
      document.body.style.overflowY = 'auto'
      setIsLoading(false)
      setIsInitLoading(false)
      //document.getElementById('InitLoading').remove()
      //const loadingDiv = document.getElementById('MyFoodieLogoInLoading')
      //loadingDiv.style.opacity = 0
      //loadingDiv.style.transition = 'opacity 1s'
      //setIsLoading(false)
    }, 1700);

    /*const unsubscribe = listenToPostsChanges((postsData) => {
      // Update posts data in your React state when changes occur in Firebase

      updatePosts(postsData)
    })

    return () => {
      unsubscribe() // Unsubscribe from Firebase listener
    }*/
    //alert(window.location)
    //if (window.location === 'https://myfoodie.ca/') {
      // Redirect to 'https://myfoodie.ca/home'
      //window.location.href = 'https://myfoodie.ca/home';
    //}
  }, [])

  useEffect(() => {
    const { pathname } = location
    const currentRecipe = recipiesObj.recipes.find(recipe => `/recipe/${recipe.id}` === pathname)

    let title = 'Tasting the World with My Foodie Canada'
    let description = 'Explore an array of global recipes at MyFoodie.ca. From Thai to Italian, savor diverse and tantalizing flavors!'
    let keywords = "My Foodie Canada, MyFoodie, Foodie, myfoodie.ca, Thai Cuisine, Thai Recipes, Mango Salad, Thai Salads, Thai Fruits, Mango Recipes, Asian Salads, Fruit Salads, Peanut Dressing, Asian Cuisine, Middle Eastern Recipes, European Dishes, Latin American Cooking, African Cuisine, Mediterranean Flavors, Chinese Recipes, Japanese Cuisine, Indian Cooking, Italian Gastronomy, French Cuisine, Mexican Food, Greek Recipes, Spanish Delicacies, Korean Culinary, Vietnamese Cuisine, Moroccan Meals, Brazilian Recipes, Fusion Foods, International Cooking, Global Gastronomy, Exotic Recipes, Ethnic Dishes, Culinary Diversity, World Flavors, Authentic Cooking, Traditional Recipes, Multicultural Cuisine, Gourmet Delights, Culinary Adventures, Culinary Traditions, Diverse Flavors, Cultural Cooking, Continental Recipes, Taste of the World, Fusion Cuisine, Culinary Exploration, Gastronomic Experience"
    let url = 'https://myfoodie.ca'
    let image = 'https://myfoodie.ca/static/media/ThaiMangoSalad_2x3.69981ba81d89f38b7378.png'
    let twitterURL = ''

    try {
      const titleMeta = document.querySelector('meta[name="keywords"]')
      const descriptionMeta = document.querySelector('meta[name="description"]')
      const keywordsMeta = document.querySelector('meta[name="keywords"]')

      const existingOGTitle = document.querySelector('meta[property="og:title"]')
      const existingOGDescription = document.querySelector('meta[property="og:description"]')
      const existingOGUrl = document.querySelector('meta[property="og:url"]')
      const existingOGImage = document.querySelector('meta[property="og:image"]')

      const twitterCardMeta = document.querySelector('meta[name="twitter:card"]')
      const twitterTitleMeta = document.querySelector('meta[name="twitter:title"]')
      const twitterDescriptionMeta = document.querySelector('meta[name="twitter:description"]')
      const twitterImageMeta = document.querySelector('meta[name="twitter:image"]')
      const twitterPlayerMeta = document.querySelector('meta[name="twitter:player"]')
      const twitterPlayerWidthMeta = document.querySelector('meta[name="twitter:player:width"]')
      const twitterPlayerHeightMeta = document.querySelector('meta[name="twitter:player:height"]')
      const twitterUrlMeta = document.querySelector('meta[name="twitter:url"]')

      if (currentRecipe) {
        title = currentRecipe.title
        description = currentRecipe.shortDescription
        keywords = currentRecipe.tags.join(', ')
        url = `https://myfoodie.ca/recipe/${currentRecipe.id}`
        image = currentRecipe.images[5]
        twitterURL = currentRecipe.socialMedia[5]?.url || ''
      }

      document.title = title
      //window.alert(document.body.childNodes[1].nodeName)
      document.querySelector('h1').innerText = title

      titleMeta.setAttribute('content', title)
      descriptionMeta.setAttribute('content', description)
      keywordsMeta.setAttribute('content', keywords)

      existingOGTitle.setAttribute('content', title)
      existingOGDescription.setAttribute('content', description)
      existingOGUrl.setAttribute('content', url)
      existingOGImage.setAttribute('content', image)

      twitterTitleMeta.setAttribute('content', currentRecipe?.title || '')
      twitterDescriptionMeta.setAttribute('content', currentRecipe?.shortDescription || '')
      twitterImageMeta.setAttribute('content', currentRecipe?.images[5] || '')
      twitterUrlMeta.setAttribute('content', `https://myfoodie.ca/recipe/${currentRecipe?.id}` || '')

      if (currentRecipe?.socialMedia[5]?.type === 'video') {
        twitterCardMeta.setAttribute('content', 'player')
        twitterPlayerMeta.setAttribute('content', twitterURL)
        twitterPlayerWidthMeta.setAttribute('content', '333')
        twitterPlayerHeightMeta.setAttribute('content', '500')
      } else if (currentRecipe?.socialMedia[5]?.type === 'image') {
        twitterCardMeta.setAttribute('content', 'summary_large_image')
      }
    } catch (error) {
      console.error('Error updating meta tags:', error)
    }
  }, [location])


  const setReactFromClient = () => {
    if(! reacted) {
      worker.postMessage({type: 0})
      setReacted(true)

      if(window.localStorage.getItem('appReacted') === null)
        window.localStorage.setItem('appReacted', '1')
    }
  }

  return (
    <div id='AppDiv' className={themeContext}>
      <NavBar />
      <Routes>
        <Route path='/home' element={<Home />} />
        <Route path='/recipes' element={<Recipes />} />
        {recipesData.recipes.map(recipe => (
          <Route key={recipe.id} path={`/recipe/${recipe.id}`} element={<Recipe />} />
        ))}
        <Route path='/videos' element={<Videos />} />
        <Route path='/search' element={<Search />} />
        <Route path='/pinned' element={<Pinned />} />
        <Route path='/watchlater' element={<WatchLater />} />
        <Route path='/' element={<Navigate to='/home' />} />
      </Routes>

      <AppReact setReactFromClient={setReactFromClient} />
      { tooltipData.display && <Tooltip /> }
      { (currentPage === 'Pinned' || currentPage === 'WatchLater' || currentPage === 'TEMPSearch' || currentPage === 'Recipe') && <BackButton /> }
      { isShareDisplayed && <Share /> }
      { isFollowUsDisplayed && <FollowUs  /> }
      { isMediaPlayerDisplayed && <MediaPlayer url={mediaPlayerURL} /> }
      { isMoreDisplayed && <MoreOption /> }
      { isLoading && <Loading /> }
    </div>
  )
}

export default App
