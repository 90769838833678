import { useState, useContext, useEffect } from 'react'
import { CurrentPageUpdateContext } from '../../contexts/appContext'
import Recipes from '../../components/Recipes/Recipes'
import RemoveSavedItem from '../../components/RemoveSavedItem/RemoveSavedItem'
import './style.scss'

function Pinned() {
  const [ isDeleteConfirmDisplayed, setIsDeleteConfirmDisplayed ] = useState(false)
  const [ data, setData ] = useState()

  const setCurrectPage = useContext(CurrentPageUpdateContext)

  useEffect(() => {
    setCurrectPage('Pinned')
  }, [])

  const pinnedList = window.localStorage.getItem('pinnedItems') === null? []: JSON.parse(window.localStorage.getItem('pinnedItems'))

  return (
    <div id="PinnedPageDiv">
      {pinnedList.length > 0 &&
        <div style={{ position: 'relative', textAlign: 'center', margin: '40px 0'}}>
         Your pinned items
        </div>
      }
      {pinnedList.length === 0 &&
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
         You have no pinned items
        </div>
      }
      {pinnedList.length > 0 &&
        <Recipes type='saved' setRemoveData={(props) => {setData(props); setIsDeleteConfirmDisplayed(true)}} />
      }
      {isDeleteConfirmDisplayed &&
        <RemoveSavedItem setIsDeleteConfirmDisplayed={setIsDeleteConfirmDisplayed} data={data} />
      }
    </div>
  )
}

export default Pinned
