import { useState, useEffect, useContext } from 'react'
import { listenToPostViewsChange } from '../../firebase'
import { RecipesDataContext, RecipesDataUpdateContext, TooltipUpdateContext } from '../../contexts/appContext'
import SvgIcons from '../SvgIcons/SvgIcons'
import Tooltip from '../Tooltip/Tooltip'
import './style.scss'

function ReactOption(props) {
  const recipesData = useContext(RecipesDataContext)
  const setTooltipData = useContext(TooltipUpdateContext)
  const setRecipesData = useContext(RecipesDataUpdateContext)

  useEffect(() => {
    const removeTooltip = () => {
      console.log("ASDER$#$#");
      setTooltipData(
        {
          display: false
        }
      )
      window.onscroll = null
    }

    document.onscroll = removeTooltip.call(this)
      //alert(props.rID)
      const postId = props.rID

    const unsubscribe = listenToPostViewsChange(postId, (numberOfViews) => {
      const tempData = { ...recipesData }
      console.log("VIEWS UPDATED");
      tempData.recipes[tempData.recipes.findIndex(recipe => recipe.id === props.rID)].numberOfViews = numberOfViews
      setRecipesData(tempData)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const test = (numberOfViews) => {
    if(numberOfViews > 999 && numberOfViews < 100000)
      return `${(numberOfViews / 1000).toFixed(1).replace('.0', '')}K`

    if(numberOfViews > 99999 && numberOfViews < 1000000)
      return `${(numberOfViews / 1000).toFixed(0).replace('.0', '')}K`

    else if(numberOfViews > 999999)
      return `${(numberOfViews / 1000000).toFixed(1).replace('.0', '')}M`


    else if(numberOfViews === 0)
    return ''

    return numberOfViews.toString()
  }

  return(
    <div className='numberOfViewsSection'
    onMouseEnter={(event) => {
      setTooltipData(
        {
          display: true,
          position: 'absolute',
          text: 'views',
          left: event.target.getBoundingClientRect().x - 10,
          top: event.target.getBoundingClientRect().y - 43 + window.pageYOffset,
          right: 'auto',
          bottom: 'auto'
        }
      )
    }}

    onMouseLeave={(event) => {
      setTooltipData(
        {
          display: false
        }
      )
    }}>
      <div style={{ pointerEvents: 'none' }}>
        <SvgIcons id={38} style={{ pointerEvents: 'none' }} />
      </div>
      <div className='numberOfViewsDiv' style={{ pointerEvents: 'none' }}>
        {test.call(this, recipesData.recipes[recipesData.recipes.findIndex(recipe => recipe.id === props.rID)].numberOfViews)}
      </div>
    </div>
  )
}

export default ReactOption
