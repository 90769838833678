import { useContext } from 'react'
import { RecipesDataContext } from '../../../../../contexts/appContext'
import SvgIcons from '../../../../../components/SvgIcons/SvgIcons'
import './style.scss'

function Texts(props) {
  const recipeData = useContext(RecipesDataContext)

  const getTimeDifference = (dateValue) => {
    const givenDate = new Date(dateValue)
    const currentDate = new Date()

    const timeDifference = currentDate - givenDate
    const secondsDifference = Math.floor(timeDifference / 1000)
    const minutesDifference = Math.floor(secondsDifference / 60)
    const hoursDifference = Math.floor(minutesDifference / 60)
    const daysDifference = Math.floor(hoursDifference / 24)
    const monthsDifference = Math.floor(daysDifference / 30)
    const yearsDifference = Math.floor(monthsDifference / 12)

    if (secondsDifference < 60) {
        return `${secondsDifference} sec. ago`
    } else if (minutesDifference < 2) {
        return `1 min. ago`
    } else if (minutesDifference < 60) {
        return `${minutesDifference} min. ago`
    } else if (hoursDifference < 2) {
        return `1 hr. ago`
    } else if (hoursDifference < 24) {
        return `${hoursDifference} hr. ago`
    } else if (daysDifference === 1) {
        return `1 day ago`
    } else if (daysDifference < 30) {
        return `${daysDifference} days ago`
    } else if (monthsDifference === 1) {
        return `1 month ago`
    } else if (monthsDifference < 12) {
        return `${monthsDifference} months ago`
    } else if (yearsDifference === 1) {
        return `1 year ago`
    } else {
        return `${yearsDifference} years ago`
    }
  }

  return(
    <>
      <div id='RecipeCardTextTitle'>
        {recipeData.recipes[props.index].name}
      </div>
      <div id='RecipeCardTextBody'>
        {recipeData.recipes[props.index].description}
        <div style={{ textAlign: 'left', marginLeft: '5px', fontWeight: '200', color: '#aaaaaa', marginTop : '15px', marginBottom: '30px', fontSize: '12px' }}>{getTimeDifference.call(this, recipeData.recipes[props.index].postedOn)} <SvgIcons id={56} style={{ pointerEvents: 'none', fill: '#cccccc', transform: 'translateY(4px) scale(0.7)' }} /><SvgIcons id={38} style={{ pointerEvents: 'none', fill: 'gray', transform: 'translateY(4px) scale(0.7)' }} /> {recipeData.recipes[props.index].numberOfViews}</div>
        <div className='headerInRecipePage' style={{ marginTop: '20px' }}>
          <SvgIcons id={15} w='18' h='18' fill='#8f8786' stroke='#8f8786' strokeWidth='0.1' /> Time
        </div>
        <div style={{ display: 'flex', gap: '2vw' }}>
          <div><span style={{ fontWeight: '500' }}>prep</span>: <span style={{ fontWeight: '200' }}>{recipeData.recipes[props.index].prepTime}</span></div>
          <div><span style={{ fontWeight: '500' }}>cook</span>: <span style={{ fontWeight: '200' }}>{recipeData.recipes[props.index].cookTime}</span></div>
          <div><span style={{ fontWeight: '500' }}>total</span>: <span style={{ fontWeight: '200' }}>{recipeData.recipes[props.index].totalTime}</span></div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <div className='headerInRecipePage'><SvgIcons id={16} w='18' h='18' fill='#8f8786' stroke='#8f8786' strokeWidth='0.1' /> Servings</div>
          <div style={{ marginLeft: '2vw', fontWeight: '200' }}>{recipeData.recipes[props.index].servings}</div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <div className='headerInRecipePage'>Ingredients</div>
          <ul style={{ display: 'blcok', gap: '2vw' }}>{recipeData.recipes[props.index].ingredients.map((item, i) => {
            return(
              <li key={i}><span style={{ fontWeight: '500' }}>{item.name}</span>: <span style={{ fontWeight: '200' }}>{item.amount}</span></li>
            )
          })}
          </ul>
          <div className='headerInRecipePage' style={{ marginTop: '20px' }}>Cooking Directions</div>
          <ul>{recipeData.recipes[props.index].cookingInstructions.map((item, i) => {
            return(
              <li key={i}><span style={{ fontWeight: '500' }}>step {item.stepNumber}</span>: <span style={{ fontWeight: '200' }}>{item.text}</span></li>
            )
          })}
          </ul>
        </div>
        <div style={{ minHeight: '200px' }}>
        </div>
      </div>
    </>
  )
}

export default Texts


/*
<div id='TextsInRecipe'>
  <div>
    <div style={{ fontSize: '2.5vw' }}>{recipeData.recipes[props.index].name}</div>
    <div style={{ minWidth: '30vw', maxWidth: '30vw' }}>{recipeData.recipes[props.index].description}</div>
    <div className='headerInRecipePage'><SvgIcons id={15} w='18' h='18' fill='#8f8786' stroke='#8f8786' strokeWidth='0.1' />Time</div>
    <div style={{ display: 'flex', gap: '2vw' }}>
      <div><span style={{ fontWeight: '500' }}>prep</span>: {recipeData.recipes[props.index].prepTime}</div>
      <div><span style={{ fontWeight: '500' }}>cook</span>: {recipeData.recipes[props.index].cookTime}</div>
      <div><span style={{ fontWeight: '500' }}>total</span>: {recipeData.recipes[props.index].totalTime}</div>
    </div>
    <div>
      <div className='headerInRecipePage'><SvgIcons id={16} w='18' h='18' fill='#8f8786' stroke='#8f8786' strokeWidth='0.1' /> Servings</div>
      <div style={{ marginLeft: '2vw' }}>{recipeData.recipes[props.index].servings}</div>
    </div>
    <div style={{ maxWidth: '20vw', maxHeight: '20vw', overflowY: 'auto', overflowX: 'hidden' }}>
      <div className='headerInRecipePage'>Ingredients</div>
      <div style={{ display: 'blcok', gap: '2vw' }}>{recipeData.recipes[props.index].ingredients.map((item, i) => {
        return(
          <div key={i}><span style={{ fontWeight: '500' }}>{item.name}</span>: {item.amount}</div>
        )
      })}
      </div>
      <div className='headerInRecipePage'>Cooking Directions</div>
      <div>{recipeData.recipes[props.index].cookingInstructions.map((item, i) => {
        return(
          <div key={i}><span style={{ fontWeight: '500' }}>step {item.stepNumber}</span>: {item.text}</div>
        )
      })}
      </div>
    </div>
  </div>
</div>
*/
