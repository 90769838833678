export const tagArr = [
  {
    isSelected: false,
    type: 'dish type',
    text: '20 minute recipes',
    indexes: []
  },
  {
    isSelected: false,
    type: 'dish type',
    text: 'salad',
    indexes: []
  },
  {
    isSelected: false,
    type: 'dish type',
    text: 'soup/stew',
    indexes: []
  },
  {
    isSelected: false,
    type: 'dish type',
    text: 'drink',
    indexes: []
  },
  {
    isSelected: false,
    type: 'cooking method',
    text: 'fry',
    indexes: []
  },
  {
    isSelected: false,
    type: 'cooking method',
    text: 'air fry',
    indexes: []
  },
  {
    isSelected: false,
    type: 'cooking method',
    text: 'stir-fry',
    indexes: []
  },
  {
    isSelected: false,
    type: 'cooking method',
    text: 'stovetop',
    indexes: []
  },
  {
    isSelected: false,
    type: 'cooking method',
    text: 'no-cook',
    indexes: []
  },
  {
    isSelected: false,
    type: 'meal',
    text: 'appetizer',
    indexes: []
  },
  {
    isSelected: false,
    type: 'meal',
    text: 'breakfast',
    indexes: []
  },
  {
    isSelected: false,
    type: 'meal',
    text: 'brunch',
    indexes: []
  },
  {
    isSelected: false,
    type: 'meal',
    text: 'lunch',
    indexes: []
  },
  {
    isSelected: false,
    type: 'meal',
    text: 'dinner',
    indexes: []
  },
  {
    isSelected: false,
    type: 'meal',
    text: 'snack',
    indexes: []
  },
  {
    isSelected: false,
    type: 'cuisine',
    text: 'Thai',
    indexes: []
  },
  {
    isSelected: false,
    type: 'cuisine',
    text: 'International',
    indexes: []
  },
  {
    isSelected: false,
    type: 'occasion',
    text: 'any time',
    indexes: []
  }
]

export const recipiesObj = {
  recipes: [
    {
      id: 'LmNoP9Qr',
      numberOfViews: 0,
      numberOfLikes: 0,
      isReacted: false,
      isSavedToWatchLater: false,
      isPinned: false,
      cardSize: 'small',
      name: "Vanilla Berry Smoothie 🥤",
      title: "Berrylicious Blend: Strawberry Vanilla Smoothie",
      tags: [
        "International", "healthy", "smoothie", "fruit smoothie", "Vanilla Berry Smoothie",
        "refreshing drink", "blended drinks", "healthy drink", "homemade smoothie",
        "creamy smoothie", "fruity delight", "any time", "drink", "no-cook", "Smoothie Recipes",
        "Fruit Smoothies", "Healthy Drinks", "Refreshing Smoothies", "Strawberry Recipes", "Blackburry Smoothie",
        "Blended Drinks", "Creamy Smoothies", "Fruity Delights", "strawberries", "blackberries",
        "milk", "ice", "vanilla ice cream"
      ],
      prepTime: "5 min",
      cookTime: "2 min",
      totalTime: "7 min",
      postedOn: '2023-12-19T22:11',
      servings: 1,
      description: "Quench your thirst with our tantalizing Strawberry Vanilla Smoothie! 🍓🥤 This delightful blend boasts the vibrant flavors of fresh strawberries, juicy blackberries, creamy milk, and a scoop of heavenly vanilla ice cream. Stay tuned to witness the creation of this refreshing treat that promises a burst of fruity sweetness in every sip! Join us on this flavor-packed journey into the world of our Strawberry Vanilla Smoothie",
      shortDescription: "Savor the fruity bliss of our Strawberry Vanilla Smoothie! 🍓🥤 A refreshing mix of strawberries, blackberries, milk & vanilla cream.",
      altText: "Strawberry Vanilla Smoothie in a 3 separate glasses",
      ingredients: [
        {
          id: "I00001",
          name: 'strawberries',
          amount: "9 strawberries"
        },
        {
          id: "I00002",
          name: 'blackberries',
          amount: "10 blackberries"
        },
        {
          id: "I00003",
          name: 'milk',
          amount: "1/2 cup"
        },
        {
          id: "I00004",
          name: 'ice',
          amount: "1/2 cup"
        },
        {
          id: "I00005",
          name: 'vanilla ice cream',
          amount: "1 scoop"
        }
      ],
      cookingInstructions: [
        {
          stepNumber: 1,
          text: 'In a blender, combine strawberries, blackberries, milk, icm cream, and ice. Start blending until smooth.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 2,
          text: 'Once the ice and other ingredients are fully blended, pour the smoothie into a cup and serve immediately. Enjoy your refreshing Strawberry Vanilla Smoothie! 🥤🍓',
          additionalText: '',
          notice: '',
          base64Image: ''
        }
      ],
      images: [
        'VanillaBerrySmoothie_2x3.png',
        'VanillaBerrySmoothie_1x1.png',
        'VanillaBerrySmoothie_1x1.png',
        'VanillaBerrySmoothie_9x16.png',
        'VanillaBerrySmoothie_1x1.png',
        'https://myfoodie.ca/static/media/VanillaBerrySmoothie_2x3.d5625d2a52da2ea69ff2.png'
      ],
      socialMedia: [
        {
          name: "YouTube",
          url: "https://youtube.com/shorts/5hcgpHr-yog",
          postedOn: '2023-12-14T11:01',
          timeLength: '0:23'
        },
        {
          name: "Pinterest",
          title: "Pinterest",
          description: "Green Curry Pinterest description",
          url: "https://pin.it/6XE91Ix",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "IG",
          title: "IG",
          description: "Green Curry Pinterest description",
          url: "https://www.instagram.com/reel/C1F6_3juB4f/?utm_source=ig_web_copy_link",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "TikTok",
          title: "TikTok",
          description: "Green Curry Pinterest description",
          url: "https://www.tiktok.com/@myfoodiecanada/video/7314736800088575238?is_from_webapp=1&sender_device=pc&web_id=7201893885827499525",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "Facebook",
          title: "Facebook",
          description: "Green Curry Pinterest description",
          url: "https://www.facebook.com/reel/223813997344797",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "Twitter",
          title: "Twitter",
          type: "video",
          description: "Green Curry Pinterest description",
          url: "https://twitter.com/myfoodiecanada/status/1737317490030420415",
          postedOn: '2022-06-11T00:01'
        }
      ]
    },
    {
      id: 'XyZaB8Cd',
      numberOfViews: 0,
      numberOfLikes: 0,
      isReacted: false,
      isSavedToWatchLater: false,
      isPinned: false,
      cardSize: 'medium',
      name: "Thai Crispy Fish Mango Salad",
      title: "Exploring the Exotic: Thai Mango Salad",
      tags: ["Thai", "Thailand", "appetizer", "snack", "fish", "crispy", "salad", "sour", "sweet", "spicy", "any time", "20 minute recipes", "Thai Cuisine", "Thai Recipes", "Mango Salad", "Thai Salads", "Thai Fruits", "Mango Recipes", "Asian Salads", "Fruit Salads", "Peanut Dressing",      "tamarind", "coconut sugar", "fish sauce", "shallots", "mix crispy fish", "cashew nut", "dry chilli", "lime", "managoes"],
      prepTime: "10 min",
      cookTime: "15 min",
      totalTime: "25 min",
      postedOn: '2023-12-14T11:01',
      servings: 2,
      description: "Thai Mango Salad is not just a dish; it's a burst of tropical sunshine on your plate. It's ideal as a refreshing appetizer, a zesty side dish, or even a light and healthy meal. Join me in this culinary adventure as we explore the unique ingredients and techniques that make this salad so special.",
      shortDescription: "A refreshing Thai mango salad bursting with flavors of sweet mango, peanuts, and a tangy dressing.",
      altText: "Thai Mango Salad a bowl",
      ingredients: [
        {
          id: "I00001",
          name: 'tamarind',
          amount: "4 tbsp"
        },
        {
          id: "I00002",
          name: 'coconut sugar',
          amount: "4 tbsp"
        },
        {
          id: "I00003",
          name: 'fish sauce',
          amount: "4 tbsp"
        },
        {
          id: "I00004",
          name: 'shallots',
          amount: "4"
        },
        {
          id: "I00005",
          name: 'mix crispy fish',
          amount: "1 cup"
        },{
          id: "I00006",
          name: 'cashew nut',
          amount: "1 cup"
        },
        {
          id: "I00007",
          name: 'dry chilli',
          amount: "1 tsp"
        },
        {
          id: "I00008",
          name: 'lime',
          amount: "1 medium size"
        },
        {
          id: "I00009",
          name: 'managoes',
          amount: "1 large size"
        }
      ],
      cookingInstructions: [
          {
              stepNumber: 1,
              text: 'In a saucepan, heat tamarind, coconut sugar, and fish sauce over medium heat. Stir to combine.',
              additionalText: '',
              notice: '',
              base64Image: ''
          },
          {
              stepNumber: 2,
              text: 'When the mixture starts to boil, set heat to low.',
              additionalText: '',
              notice: '',
              base64Image: ''
          },
          {
              stepNumber: 3,
              text: 'Peel the mango and rinse it thoroughly with water.',
              additionalText: '',
              notice: '',
              base64Image: ''
          },
          {
              stepNumber: 4,
              text: 'Slice the shallots thinly into half-moon shapes.',
              additionalText: '',
              notice: '',
              base64Image: ''
          },
          {
              stepNumber: 5,
              text: 'Return the saucepan to medium-high heat and bring the mixture to a boil. Once it starts boiling, remove it from heat and set it aside to cool.',
              additionalText: '',
              notice: '',
              base64Image: ''
          },
          {
              stepNumber: 6,
              text: 'Cut the mango into lengthwise slices and add them to a large bowl.',
              additionalText: '',
              notice: '',
              base64Image: ''
          },
          {
              stepNumber: 7,
              text: 'Into the saucepan, add the dried chili, sliced shallots, lime juice, cashew nuts, and crispy mix. Do not apply heat.',
              additionalText: '',
              notice: '',
              base64Image: ''
          },
          {
              stepNumber: 8,
              text: 'Pour the mixture from the saucepan into the bowl with mango slices and stir to combine.',
              additionalText: '',
              notice: '',
              base64Image: ''
          },
          {
              stepNumber: 9,
              text: 'Transfer the prepared mixture to serving bowls.',
              additionalText: '',
              notice: '',
              base64Image: ''
          },
          {
              stepNumber: 10,
              text: 'Enjoy!',
              additionalText: '',
              notice: '',
              base64Image: ''
          }
      ],
      images: [
        'ThaiMangoSalad_2x3.png',
        'ThaiMangoSalad_2x3.png',
        'ThaiMangoSalad_1x1.png',
        'ThaiMangoSalad_2x3.png',
        'ThaiMangoSalad_1x1.png',
        'https://myfoodie.ca/static/media/ThaiMangoSalad_2x3.69981ba81d89f38b7378.png'
      ],
      socialMedia: [
        {
          name: "YouTube",
          url: "https://youtu.be/-_sj_ur1t3c",
          postedOn: '2023-12-14T11:01',
          timeLength: '3:33'
        },
        {
          name: "Pinterest",
          title: "Pinterest",
          description: "Green Curry Pinterest description",
          url: "https://pin.it/4tnWsax",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "IG",
          title: "IG",
          description: "Green Curry Pinterest description",
          url: "https://www.instagram.com/reel/C1S-N-PLsbt/?utm_source=ig_web_copy_link",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "TikTok",
          title: "TikTok",
          description: "Green Curry Pinterest description",
          url: "https://www.tiktok.com/@myfoodiecanada/video/7286643052830117126?is_from_webapp=1&sender_device=pc&web_id=7201893885827499525",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "Facebook",
          title: "Facebook",
          description: "Green Curry Pinterest description",
          url: "https://www.facebook.com/reel/670523448574128",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "Twitter",
          title: "Twitter",
          type: "video",
          description: "Green Curry Pinterest description",
          url: "https://twitter.com/myfoodiecanada/status/1735494937368490092",
          postedOn: '2022-06-11T00:01'
        }
      ]
    },
    {
      id: 'eSpgyj3m',
      numberOfViews: 0,
      numberOfLikes: 0,
      isReacted: false,
      isSavedToWatchLater: false,
      isPinned: false,
      cardSize: 'small',
      name: "Homemade Chicken Tenders",
      title: "From My Kitchen to Yours: Homemade Chicken Tenders",
      tags: ["Thai", "Thailand", "Homemade Chicken Tenders", "chicken", "fried", "fry", "crispy", "chicken tenders", "crispy chicken tenders", "special", "delicious", "deep fry", "20 minute recipes", "appetizer", "breadcrumb", "black pepper", "any time", "Chicken Recipes", "Chicken Strips", "Chicken Finger Recipes", "Easy Chicken Recipes", "Homemade Fast Food",     "chicken tenders", "thin soy sauce", "seasoning sauce", "salt", "black pepper", "eggs", "breadcrumb", "flour", "cooking oil"],
      prepTime: "10 min",
      cookTime: "10 min",
      totalTime: "20 min",
      postedOn: '2023-12-14T11:01',
      servings: 2,
      description: "We're excited to share with you my very own recipe for homemade chicken tenders. These aren't your typical chicken tenders; they're a delicious creation straight from MyFoodie.Ca's kitchen to yours.",
      shortDescription: "Make crispy and delicious homemade chicken tenders with this easy recipe that everyone will love.",
      altText: "Chicken Tenders arranged in a plate",
      ingredients: [
        {
          id: "I00001",
          name: 'chicken tenders',
          amount: "200 grams"
        },
        {
          id: "I00002",
          name: 'thin soy sauce',
          amount: "1 tbsp"
        },
        {
          id: "I00003",
          name: 'seasoning sauce',
          amount: "1 tbsp"
        },
        {
          id: "I00004",
          name: 'salt',
          amount: "1/4 tsp"
        },
        {
          id: "I00005",
          name: 'black pepper',
          amount: "1/2 tsp"
        },
        {
          id: "I00006",
          name: 'eggs',
          amount: "1"
        },
        {
          id: "I00007",
          name: 'breadcrumb',
          amount: "1 cup"
        },
        {
          id: "I00008",
          name: 'flour',
          amount: "1 cup"
        },
        {
          id: "I00009",
          name: 'cooking oil',
          amount: "2 cups"
        }
      ],
      cookingInstructions: [
        {
          stepNumber: 1,
          text: 'In a bowl, mix together thin sauce, oyster sauce, seasoning sauce, salt, black pepper, and egg until well combined.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 2,
          text: 'Add chicken tenders to the sauce mixture and coat them thoroughly.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 3,
          text: 'Prepare two separate bowls, one with breadcrumbs and the other with flour.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 4,
          text: 'Preheat cooking oil in a frying pan or deep fryer.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 5,
          text: 'Coat each marinated chicken tender first in flour, then in breadcrumbs, and allow them to rest. Repeat for all chicken tenders.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 6,
          text: 'Once the cooking oil reaches around 350°F (175°C), carefully begin frying the coated chicken tenders in batches.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 7,
          text: 'Fry the chicken tenders until they reach a golden brown color, approximately 10 minutes. Remove from the fryer and drain excess oil on a paper towel.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 8,
          text: 'Serve the crispy golden chicken tenders and enjoy!',
          additionalText: '',
          notice: '',
          base64Image: ''
        }
      ],
      images: [
        'HomemadeChickenTenders_2x3.png',
        'HomemadeChickenTenders_1x1.png',
        'HomemadeChickenTenders_3x2.png',
        'HomemadeChickenTenders_1x1.png',
        'HomemadeChickenTenders_1x1.png',
        'https://myfoodie.ca/static/media/HomemadeChickenTenders_2x3.0d507a409558f1b1c9da.png'
      ],
      socialMedia: [
        {
          name: "YouTube",
          url: "https://youtu.be/4ewHmMhUhsQ",
          postedOn: '2023-12-14T11:01',
          timeLength: '3:08'
        },
        {
          name: "Pinterest",
          title: "Pinterest",
          description: "Green Curry Pinterest description",
          url: "https://pin.it/3wigvXA",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "IG",
          title: "IG",
          description: "Green Curry Pinterest description",
          url: "https://www.instagram.com/p/CyKOMGCOxKP/",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "TikTok",
          title: "TikTok",
          description: "Green Curry Pinterest description",
          url: "https://www.tiktok.com/@myfoodiecanada/video/7287763084700175622?is_from_webapp=1&sender_device=pc&web_id=7201893885827499525",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "Facebook",
          title: "Facebook",
          description: "Green Curry Pinterest description",
          url: "https://fb.watch/oXu_ltm-CN/",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "Twitter",
          title: "Twitter",
          type: "video",
          description: "Green Curry Pinterest description",
          url: "https://twitter.com/myfoodiecanada/status/1735496888843952273",
          postedOn: '2022-06-11T00:01'
        }
      ]
    },
    {
      id: '112456',
      numberOfViews: 0,
      numberOfLikes: 0,
      isReacted: false,
      isSavedToWatchLater: false,
      isPinned: false,
      cardSize: 'medium',
      name: "Thai Rice Porridge with Fish",
      title: "Thai Rice Porridge with Fish: Taste of Thailand at Home",
      tags: ["Thai", "Thailand", "soup", "soup/stew", "hot", "comfort food", "fish", "breakfast", "stovetop", "Rice Porridge", "Thai Soups", "Congee", "Rice Dishes",       "Thai jasmine rice", "tilapia fish", "garlic", "cilantro roots", "cilantro", "green onion", "black pepper", "stock Cube", "cooking oil", "water", "thin soy sauce", "seasoning sauce"],
      prepTime: "15 min",
      cookTime: "15 min",
      totalTime: "30 min",
      postedOn: '2023-12-14T11:01',
      servings: 2,
      description: "We will diving deep into the heart of Thai cuisine to bring you a beloved classic: Thai Rice Porridge with Fish. Join us on a culinary journey as we explore the art of crafting this comforting and flavorful dish.",
      shortDescription: "Experience the comfort of Thai rice porridge with fish, a warm and comforting dish perfect for any meal.",
      altText: "Porridge with Fish in a bowl with cilantro at the top",
      ingredients: [
        {
          id: "I00001",
          name: 'Thai jasmine rice',
          amount: "1 cup"
        },
        {
          id: "I00002",
          name: 'tilapia fish',
          amount: "200g"
        },
        {
          id: "I00003",
          name: 'garlic',
          amount: "5 cloves"
        },
        {
          id: "I00004",
          name: 'cilantro roots',
          amount: "3 roots"
        },
        {
          id: "I00005",
          name: 'cilantro',
          amount: "1/4 cup"
        },{
          id: "I00006",
          name: 'green onion',
          amount: "1/4 cup"
        },
        {
          id: "I00007",
          name: 'black pepper',
          amount: "2 tsp"
        },
        {
          id: "I00008",
          name: 'stock Cube',
          amount: "1 cube"
        },
        {
          id: "I00009",
          name: 'cooking oil',
          amount: "2 tbsp"
        },
        {
          id: "I00010",
          name: 'water',
          amount: "2 cups"
        },
        ,
        {
          id: "I00011",
          name: 'thin soy sauce',
          amount: "1 tbsp"
        },
        {
          id: "I00012",
          name: 'seasoning sauce',
          amount: "1 tbsp"
        },
      ],
      cookingInstructions: [
        {
          stepNumber: 1,
          text: 'Rinse the rice in cold water until the water runs clear.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 2,
          text: 'In a saucepan, combine the rice and water. Bring to a boil and simmer until the rice is fully cooked.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 3,
          text: 'Cut the fish into bite-sized pieces and set aside.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 4,
          text: 'Finely chop the green onions and cilantro.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 5,
          text: 'Place garlic cloves in a mortar and pound until crushed. Reserve half for later use.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 6,
          text: 'Add cilantro roots and black pepper to the remaining crushed garlic in the mortar. Continue pounding into a paste.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 7,
          text: 'Once the rice is cooked, remove the saucepan from heat and set aside.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 8,
          text: 'Heat 1 tbsp of cooking oil in a separate pan over medium heat.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 9,
          text: 'When the oil reaches about 300°C or 175°F, fry the reserved garlic until golden brown. Remove from the pan and set aside.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 10,
          text: 'Use the same pan (without cleaning) to fry the mixture from the mortar (reserved garlic, cilantro roots, black pepper) with 1 tbsp of cooking oil.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 11,
          text: 'Add the prepared tilapia fish to the pan and fry until about 3/4 cooked.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 12,
          text: 'Combine the cooked fish with the fully cooked rice in the saucepan. Add 1/2 cup of water and bring it to a boil.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 13,
          text: 'Add the stock cube, thin sauce, and seasoning sauce. Let it simmer for 3-4 minutes.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 14,
          text: 'Serve the dish in bowls. Garnish with fried garlic, chopped green onions, and cilantro on top.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 15,
          text: 'Enjoy your flavorful rice and fish dish!',
          additionalText: '',
          notice: '',
          base64Image: ''
        }
      ],
      images: [
        'ThaiRicePorridgewithFish_2x3.png',
        'ThaiRicePorridgewithFish_9x16.png',
        'ThaiRicePorridgewithFish_3x2.png',
        'ThaiRicePorridgewithFish_2x3.png',
        'ThaiRicePorridgewithFish_1x1.png',
        'https://myfoodie.ca/static/media/ThaiRicePorridgewithFish_2x3.13596f00c20c93e8aea5.png'
      ],
      socialMedia: [
        {
          name: "YouTube",
          url: "https://youtu.be/C5v0wMsAWUI",
          postedOn: '2023-12-14T11:01',
          timeLength: '4:44'
        },
        {
          name: "Pinterest",
          title: "Pinterest",
          description: "Green Curry Pinterest description",
          url: "https://pin.it/2MOQmM7",
          postedOn: '2023-12-14T11:01',
        },
        {
          name: "IG",
          title: "IG",
          description: "Green Curry Pinterest description",
          url: "https://www.instagram.com/p/C1TFAecLq7p/?utm_source=ig_web_copy_link",
          postedOn: '2023-12-14T11:01',
        },
        {
          name: "TikTok",
          title: "TikTok",
          description: "Green Curry Pinterest description",
          url: "https://www.tiktok.com/@myfoodiecanada/video/7286274164116262150?is_from_webapp=1&sender_device=pc&web_id=7201893885827499525",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "Facebook",
          title: "Facebook",
          description: "Green Curry Pinterest description",
          url: "https://fb.watch/oXwteZn5OC/",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "Twitter",
          title: "Twitter",
          type: "video",
          description: "Green Curry Pinterest description",
          url: "https://twitter.com/myfoodiecanada/status/1735502557219369130",
          postedOn: '2022-06-11T00:01'
        }
      ]
    },
    {
      id: 'GhMnRs2T',
      numberOfViews: 0,
      numberOfLikes: 0,
      isReacted: false,
      isSavedToWatchLater: false,
      isPinned: false,
      cardSize: 'medium',
      name: "Thai Basil with Beef",
      title: "Thai Basil Stir-Fried Beef with Sunny-Side-Up Egg and Lime Chili Fish Sauce 🤤🤤",
      tags: ["Thai", "Thailand", "delicious", "brunch", "lunch", "dinner", "stir-fry", "stovetop", "Thai Cuisine", "Thai Recipes", "Beef Stir-Fry", "Thai Basil Beef", "Thai Spices", "Thai Cooking", "Southeast Asian Cuisine", "Stir-Fry Recipes", "Beef Recipes", "Thai Food",     "beef", "coconut sugar", "chilli paste with soya bean oil", "oyster sauce", "soya sauce", "seasoning sauce", "red chilli", "Thai spicy chilli", "garlic", "lime", "cucumber", "Thai sweet basil", "dried chilli", "dried chilli powder", "egg", "cooking oil", "water", "fully cooked Thai jasmine rice"],
      prepTime: "10 min",
      cookTime: "15 min",
      totalTime: "25 min",
      postedOn: '2023-12-14T11:01',
      servings: 2,
      description: "Thai Basil Stir-Fried Beef with Sunny-Side-Up Egg and Lime Chili Fish Sauce is a delightful Thai dish that combines tender slices of beef stir-fried with aromatic Thai basil leaves, minced garlic, and chili peppers. This flavorful mixture is then topped with a perfectly cooked sunny-side-up egg, adding a rich and creamy texture to the dish. To elevate the taste, a tangy and spicy lime chili fish sauce is drizzled over the top, creating a harmonious blend of savory, spicy, and citrusy flavors that make this dish a Thai culinary favorite.",
      shortDescription: "Delicious Thai basil with beef stir-fry recipe with aromatic Thai spices and flavors.",
      altText: "Thai Basil with Beef, rice, cucumber slices, an egg, and fish sauce in a small bowl",
      ingredients: [
        {
          id: "I00001",
          name: 'beef',
          amount: "250 grams"
        },
        {
          id: "I00002",
          name: 'coconut sugar',
          amount: "1 tsp"
        },
        {
          id: "I00003",
          name: 'chilli paste with soya bean oil',
          amount: "1 tbsp"
        },
        {
          id: "I00004",
          name: 'oyster sauce',
          amount: "1 tbsp"
        },{
          id: "I00005",
          name: 'soya sauce',
          amount: "1 tbsp"
        },
        {
          id: "I00006",
          name: 'seasoning sauce',
          amount: "1 tbsp"
        },
        {
          id: "I00007",
          name: 'red chilli',
          amount: "1 chilli"
        },
        {
          id: "I00008",
          name: 'Thai spicy chilli',
          amount: "5 chillies"
        },
        {
          id: "I00009",
          name: 'garlic',
          amount: "4 cloves"
        },
        ,
        {
          id: "I00010",
          name: 'lime',
          amount: "1 medium size"
        },
        {
          id: "I00011",
          name: 'cucumber',
          amount: "1/8 large size"
        },
        {
          id: "I00012",
          name: 'Thai sweet basil',
          amount: "1 cup"
        },
        {
          id: "I00013",
          name: 'dried chilli',
          amount: "1 cup"
        },
        {
          id: "I00014",
          name: 'dried chilli powder',
          amount: "1 tsp"
        },
        {
          id: "I00015",
          name: 'egg',
          amount: "1 medium size"
        },
        {
          id: "I00016",
          name: 'cooking oil',
          amount: "3 tbsp"
        },
        {
          id: "I00017",
          name: 'dried chilli powder',
          amount: "1 tsp"
        },
        {
          id: "I00018",
          name: 'water',
          amount: "4 tbsp"
        },
        {
          id: "I00019",
          name: 'fully cooked Thai jasmine rice',
          amount: "1.5 cups"
        }
      ],
      cookingInstructions: [
        {
          stepNumber: 1,
          text: 'In a mortar, combine 3 Thai spicy chilies and 3 cloves of garlic. Pound the mixture until the garlic and chilies are smashed and the garlic turns slightly red in color.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 2,
          text: 'Preheat 2 tbsp of cooking oil in a pan over medium heat. Fry the egg to your desired level of doneness. Set aside once cooked.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 3,
          text: 'Using the same pan and oil, add 3/4 of the mixture from the mortar. Begin frying and incorporate chili paste, coconut sugar, oyster sauce, seasoning sauce, and soya sauce. Continue frying.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 4,
          text: 'Add chili powder and 1 tbsp of water to the pan. Continue frying until it starts bubbling, then set aside.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 5,
          text: 'In the same pan, add 1 tbsp of cooking oil and the remaining 1/4 of the mixture from the mortar. Include dried chilies and beef, then start frying. Add 1 tbsp of water and continue frying.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 6,
          text: 'Once bubbling, reintroduce the mixed sauce from step 4 and add red chili. Continue frying.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 7,
          text: 'Add basil to the pan along with 1 tbsp of water. Fry for an additional 3 minutes, then set aside.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 8,
          text: 'Chop 2 Thai spicy chilies, 1 clove of garlic, mix with 2 tbsp of fish sauce, and the juice of 1/2 medium-sized lime in a small sauce bowl.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 9,
          text: 'Serve with Thai jasmine rice, fried egg, chili sauce, and sliced cucumbers.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 10,
          text: 'Enjoy your delicious Thai Basil Beef!',
          additionalText: '',
          notice: '',
          base64Image: ''
        }
      ],
      images: [
        'ThaiBasilwithBeef_2x3.png',
        'ThaiBasilwithBeef_2x3.png',
        'ThaiBasilwithBeef_3x4.png',
        'ThaiBasilwithBeef_1x1.png',
        'ThaiBasilwithBeef_1x1.png',
        'https://myfoodie.ca/static/media/ThaiBasilwithBeef_2x3.b90aca8f71585819926e.png'
      ],
      socialMedia: [
        {
          name: "YouTube",
          url: "https://youtu.be/UVENmcpF33c",
          postedOn: '2023-12-14T11:01',
          timeLength: '4:21'
        },
        {
          name: "Pinterest",
          title: "Pinterest",
          description: "Green Curry Pinterest description",
          url: "https://pin.it/3IXWC28",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "IG",
          title: "IG",
          description: "Green Curry Pinterest description",
          url: "https://www.instagram.com/p/C1TCimDLEJn/?utm_source=ig_web_copy_link",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "TikTok",
          title: "TikTok",
          description: "Green Curry Pinterest description",
          url: "https://www.tiktok.com/@myfoodiecanada/video/7288510199718055174?is_from_webapp=1&sender_device=pc&web_id=7201893885827499525",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "Facebook",
          title: "Facebook",
          description: "Green Curry Pinterest description",
          url: "https://www.facebook.com/reel/878958180401552",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "Twitter",
          title: "Twitter",
          type: "image",
          description: "Green Curry Pinterest description",
          url: "https://x.com/myfoodiecanada/status/1735505522835468729?s=20",
          postedOn: '2022-06-11T00:01'
        }
      ]
    },
    {
      id: 'JnKpQ7sR',
      numberOfViews: 0,
      numberOfLikes: 0,
      isReacted: false,
      isSavedToWatchLater: false,
      isPinned: false,
      cardSize: 'large',
      name: "Strawberry Vanilla Smoothie 🥤",
      title: "Fruitful Fusion: Strawberry Vanilla Smoothie",
      tags: ["International", "healthy", "smoothie", "fruit smoothie", "strawberry banana smoothie", "honey smoothie", "refreshing drink", "blended drinks", "healthy drink", "homemade smoothie", "creamy smoothie", "fruity delight", "any time", "drink", "no-cook", "Smoothie Recipes", "Fruit Smoothies", "Healthy Drinks", "Refreshing Smoothies", "Strawberry Recipes", "Banana Smoothie", "Honey Smoothie", "Blended Drinks", "Creamy Smoothies", "Fruity Delights",         "strawberries", "bananas", "milk", "honey", "ice", "vanilla icm cream"],
      prepTime: "5 min",
      cookTime: "5 min",
      totalTime: "10 min",
      postedOn: '2023-12-14T11:01',
      servings: 2,
      description: "Indulge in the luscious medley of fresh strawberries, ripe bananas, and golden honey fused into our delectable 'Strawberry Banana Honey Bliss Smoothie'! 🍓🍌🍯 Blended to perfection with creamy ice cream, chilled milk, and ice, this vibrant concoction offers a symphony of flavors and velvety texture in every sip. Bursting with natural sweetness and enriched with wholesome ingredients, this smoothie is an ideal refresher for any time of the day. Elevate your taste buds with this delightful blend and embark on a journey of fruity goodness and creamy delight! 🥤✨",
      shortDescription: "Indulge in a luscious strawberry vanilla smoothie blended to perfection with fresh fruits and creamy ice cream.",
      altText: "Strawberry Vanilla Smoothie in a 3 separate glasses",
      ingredients: [
        {
          id: "I00001",
          name: 'strawberries',
          amount: "9 strawberries"
        },
        {
          id: "I00002",
          name: 'bananas',
          amount: "1 banana"
        },
        {
          id: "I00003",
          name: 'milk',
          amount: "1/2 cup"
        },
        {
          id: "I00004",
          name: 'honey',
          amount: "1 tbsp"
        },
        {
          id: "I00005",
          name: 'ice',
          amount: "1 cup"
        },
        {
          id: "I00006",
          name: 'vanilla icm cream',
          amount: "1 scoop"
        }
      ],
      cookingInstructions: [
        {
          stepNumber: 1,
          text: 'In a blender, combine strawberries, banana, milk, honey, and ice. Start blending until smooth.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 2,
          text: 'Once the ice and other ingredients are fully blended, add the vanilla ice cream to the blender and continue blending until well combined.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 3,
          text: 'Pour the smoothie into cups and serve immediately. Enjoy your refreshing Strawberry Vanilla Smoothie! 🥤🍓🍌',
          additionalText: '',
          notice: '',
          base64Image: ''
        }
      ],
      images: [
        'Strawberry-BananaHoneyMilkshakeSmoothie_2x3.png',
        'Strawberry-BananaHoneyMilkshakeSmoothie_1x1.png',
        'Strawberry-BananaHoneyMilkshakeSmoothie_3x2.png',
        'Strawberry-BananaHoneyMilkshakeSmoothie_9x16.png',
        'Strawberry-BananaHoneyMilkshakeSmoothie_1x1.png',,
        'https://myfoodie.ca/static/media/Strawberry-BananaHoneyMilkshakeSmoothie_2x3.71605880c1715967f4b7.png'
      ],
      socialMedia: [
        {
          name: "YouTube",
          url: "https://youtube.com/shorts/u3Nikefr1Mo",
          postedOn: '2023-12-14T11:01',
          timeLength: '3:09'
        },
        {
          name: "Pinterest",
          title: "Pinterest",
          description: "Green Curry Pinterest description",
          url: "https://pin.it/5wDPfpl",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "IG",
          title: "IG",
          description: "Green Curry Pinterest description",
          url: "https://www.instagram.com/reel/C03CNN8Al8c/?utm_source=ig_web_copy_link",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "TikTok",
          title: "TikTok",
          description: "Green Curry Pinterest description",
          url: "https://www.tiktok.com/@myfoodiecanada/video/7312684805588569350?is_from_webapp=1&sender_device=pc&web_id=7201893885827499525",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "Facebook",
          title: "Facebook",
          description: "Green Curry Pinterest description",
          url: "https://www.facebook.com/reel/207971472361306",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "Twitter",
          title: "Twitter",
          type: "video",
          description: "Green Curry Pinterest description",
          url: "https://twitter.com/myfoodiecanada/status/1735515176839917950",
          postedOn: '2022-06-11T00:01'
        }
      ]
    },
    {
      id: 'EfGhI6Jk',
      numberOfViews: 0,
      numberOfLikes: 0,
      isReacted: false,
      isSavedToWatchLater: false,
      isPinned: false,
      cardSize: 'medium',
      name: "Eggs Breakfast Salad",
      title: "Energize Your Morning with Eggs Breakfast Salad",
      tags: ["International", "healthy", "fruits", "any time", "breakfast", "salad", "delicious", "morning", "fresh", "vegetable", "Breakfast Salad", "Healthy Breakfast Ideas", "Fresh Salad Recipes", "Morning Salads", "Vegetarian Breakfast", "Egg Recipes", "Healthy Salads", "Vegetable Salads", "Fresh Ingredients", "Morning Freshness",          "red lettuce", "tomatoes", "cucumbers", "sweet peppers", "onions", "eggs", "milk", "butter", "black pepper", "salt", "mozzarella cheese", "Japanese mayo", "ketchup", "olive oil", "lemons", "grapes", "raspberries"],
      prepTime: "10 min",
      cookTime: "10 min",
      totalTime: "20 min",
      postedOn: '2023-12-14T11:01',
      servings: 2,
      description: "Eggs Breakfast Salad is not only a treat for your taste buds but also a great way to get your day off to a healthy start. It's packed with protein, vitamins, and that essential morning freshness we all crave.",
      shortDescription: "Start your morning with an energizing eggs breakfast salad packed with fresh vegetables and delicious flavors.",
      altText: "Eggs Breakfast Salad with grapes, raspberries, egg, and vegitables",
      ingredients: [
        {
          id: "I00001",
          name: 'red lettuce',
          amount: "2 cups"
        },
        {
          id: "I00002",
          name: 'tomatoes',
          amount: "1 medium size"
        },
        {
          id: "I00003",
          name: 'cucumbers',
          amount: "1 medium size"
        },
        {
          id: "I00004",
          name: 'sweet peppers',
          amount: "3-4 slices"
        },
        {
          id: "I00005",
          name: 'onions',
          amount: "1/2 medium size"
        },{
          id: "I00006",
          name: 'eggs',
          amount: "3"
        },
        {
          id: "I00007",
          name: 'milk',
          amount: "1/4 cup"
        },
        {
          id: "I00008",
          name: 'butter',
          amount: "1 tbsp"
        },
        {
          id: "I00009",
          name: 'black pepper',
          amount: "1/4 tsp"
        },
        {
          id: "I00010",
          name: 'salt',
          amount: "1/4 tsp"
        },
        ,
        {
          id: "I00011",
          name: 'mozzarella cheese',
          amount: "2 tbsp"
        },
        {
          id: "I00013",
          name: 'Japanese mayo',
          amount: "1/2 tsp"
        },
        {
          id: "I00014",
          name: 'ketchup',
          amount: "1 tsp"
        },
        {
          id: "I00015",
          name: 'olive oil',
          amount: "1/2 tbsp"
        },
        {
          id: "I00016",
          name: 'lemons',
          amount: "1/2 tbsp"
        },
        {
          id: "I00017",
          name: 'grapes',
          amount: "15 grapes"
        },
        {
          id: "I00018",
          name: 'raspberries',
          amount: "10 raspberries"
        }
      ],
      cookingInstructions: [
        {
          stepNumber: 1,
          text: 'Prepare the base by chopping the red lettuce into strips and arrange it on the plate.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 2,
          text: 'Slice the tomato into circular pieces and place them on top of the red lettuce.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 3,
          text: 'Slice the cucumber into rounds and add them to the plate, creating a layered effect.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 4,
          text: 'Chop the sweet pepper into small pieces. Add half of the sliced amount to the plate and reserve the remaining half for later.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 5,
          text: 'Finely chop the onion into small pieces and sprinkle half of it over the arranged ingredients on the plate.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 6,
          text: 'Crack three eggs into a bowl, add milk, and whisk thoroughly.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 7,
          text: 'Preheat a pan over medium heat, add butter, and pour in the whisked eggs.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 8,
          text: 'Season the omelette with black pepper, salt, the remaining chopped onion, the remaining sweet pepper, and half of the mozzarella cheese. Fold the omelette and place it onto the prepared plate.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 9,
          text: 'Sprinkle the remaining mozzarella cheese, chopped onion, and sweet pepper on top of the omelette.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 10,
          text: 'Drizzle mayo, ketchup, olive oil, and a squeeze of lemon over the assembled salad.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 11,
          text: 'Garnish the plate with grapes, raspberries, and a slice of sweet pepper for added freshness.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 12,
          text: 'Your delightful Eggs Breakfast Salad is ready to be savored!',
          additionalText: '',
          notice: '',
          base64Image: ''
        }
      ],
      images: [
        'EggsBreakfastSalad_2x3.png',
        'EggsBreakfastSalad_1x1.png',
        'EggsBreakfastSalad_1x1.png',
        'EggsBreakfastSalad_1x1.png',
        'EggsBreakfastSalad_1x1.png',
        'https://myfoodie.ca/static/media/AirFryerGarlicCheeseBread_2x3.10ae0e1cf4f0e5fe5918.png'
      ],
      socialMedia: [
        {
          name: "YouTube",
          url: "https://youtu.be/wFwMpfLxp70",
          postedOn: '2023-12-14T11:01',
          timeLength: '4:00'
        },
        {
          name: "Pinterest",
          title: "Pinterest",
          description: "Green Curry Pinterest description",
          url: "https://pin.it/7HNjSpf",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "IG",
          title: "IG",
          description: "Green Curry Pinterest description",
          url: "https://www.instagram.com/reel/C03EFjGAWe1/?utm_source=ig_web_copy_link",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "TikTok",
          title: "TikTok",
          description: "Green Curry Pinterest description",
          url: "https://www.tiktok.com/@myfoodiecanada/video/7286628091156303110?is_from_webapp=1&sender_device=pc&web_id=7201893885827499525",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "Facebook",
          title: "Facebook",
          description: "Green Curry Pinterest description",
          url: "https://www.facebook.com/photo/?fbid=335348582778704&set=a.335348709445358",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "Twitter",
          title: "Twitter",
          type: "video",
          description: "Green Curry Pinterest description",
          url: "https://twitter.com/myfoodiecanada/status/1737335891935285397",
          postedOn: '2022-06-11T00:01'
        }
      ]
    },
    {
      id: 'PqRsTu5V',
      numberOfViews: 0,
      numberOfLikes: 0,
      isReacted: false,
      isSavedToWatchLater: false,
      isPinned: false,
      cardSize: 'l',
      name: "Air Fryer Garlic Cheese Bread",
      title: "Air Fryer Garlic Cheese Bread: Crispy, Cheesy, and Flavorful",
      tags: ["International", "garlic bread", "bread", "garlic", "delicious", "air fry", "Garlic Bread", "Cheese Bread", "Air Fryer Recipes", "Snack Ideas", "Appetizer Recipes", "Easy Garlic Bread", "Italian Recipes", "Quick Breads", "Air Fryer Snacks", "Cheesy Bread",          "Italian bun", "everything bagel", "white bread", "garlic powder", "dried parsley", "mozzarella cheese", "margarine"],
      prepTime: "10 min",
      cookTime: "15 min",
      totalTime: "25 min",
      postedOn: '2023-12-14T11:01',
      servings: 2,
      description: "Get ready to tantalize your taste buds with the ultimate garlic cheese bread, made effortlessly in the air fryer. This crispy, cheesy delight is a flavor explosion you won't want to miss. Watch as we show you how to create the perfect combination of golden-brown, crunchy crust and ooey-gooey, melted cheese. With a garlicky kick that's simply irresistible, this air-fried garlic cheese bread will become your new favorite snack or side dish. Join us as we guide you through the simple steps to make this mouthwatering treat. It's quick, easy, and guaranteed to leave you craving for more. Don't miss out on this delectable air fryer recipe that's perfect for any occasion!",
      shortDescription: "Make crispy, cheesy garlic bread effortlessly in the air fryer for a flavorful snack or side dish.",
      altText: "sliced garlic bread",
      ingredients: [
        {
          id: "I00001",
          name: 'Italian bun',
          amount: "1 bun"
        },
        {
          id: "I00002",
          name: 'everything bagel',
          amount: "1 bagel"
        },
        {
          id: "I00003",
          name: 'white bread',
          amount: "2 slices"
        },
        {
          id: "I00004",
          name: 'garlic powder',
          amount: "3 roots"
        },
        {
          id: "I00005",
          name: 'dried parsley',
          amount: "1/4 cup"
        },{
          id: "I00006",
          name: 'mozzarella cheese',
          amount: "1/4 cup"
        },
        {
          id: "I00007",
          name: 'margarine',
          amount: "2 tsp"
        }
      ],
      cookingInstructions: [
        {
          stepNumber: 1,
          text: 'Slice the Italian bun in half horizontally.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 2,
          text: 'In a bowl, combine margarine, garlic powder, and dried parsley, and mix thoroughly.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 3,
          text: 'Using a brush, generously coat the cut sides of the Italian bun, bagel, and white bread with the prepared mixture.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 4,
          text: 'Preheat the air fryer to low heat. Place the coated bread in the air fryer for about 2.5 minutes, then add mozzarella cheese on top of the bread and continue air frying for an additional 5 minutes or until the cheese is melted and bubbly.',
          additionalText: '',
          notice: '',
          base64Image: ''
        },
        {
          stepNumber: 5,
          text: 'Remove from the air fryer, serve, and enjoy your delicious Air Fryer Garlic Cheese Bread!',
          additionalText: '',
          notice: '',
          base64Image: ''
        }
      ],
      images: [
        'AirFryerGarlicCheeseBread_2x3.png',
        'AirFryerGarlicCheeseBread_9x16.png',
        'AirFryerGarlicCheeseBread_3x2.png',
        'AirFryerGarlicCheeseBread_3x2.png',
        'AirFryerGarlicCheeseBread_1x1.png',
        'https://myfoodie.ca/static/media/AirFryerGarlicCheeseBread_2x3.10ae0e1cf4f0e5fe5918.png'
      ],
      socialMedia: [
        {
          name: "YouTube",
          url: "https://youtu.be/2uIFB3jK954",
          postedOn: '2023-12-14T11:01',
          timeLength: '8:09'
        },
        {
          name: "Pinterest",
          title: "Pinterest",
          description: "Green Curry Pinterest description",
          url: "https://pin.it/4BkxEuN",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "IG",
          title: "IG",
          description: "Green Curry Pinterest description",
          url: "https://www.instagram.com/reel/Cyw2iTwuEeM/?utm_source=ig_web_copy_link",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "TikTok",
          title: "TikTok",
          description: "Green Curry Pinterest description",
          url: "https://www.tiktok.com/@myfoodiecanada/video/7297035605861616901?is_from_webapp=1&sender_device=pc&web_id=7201893885827499525",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "Facebook",
          title: "Facebook",
          description: "Green Curry Pinterest description",
          url: "https://fb.watch/oXBI1r_uU6/",
          postedOn: '2022-06-11T00:01'
        },
        {
          name: "Twitter",
          title: "Twitter",
          type: "video",
          description: "Green Curry Pinterest description",
          url: "https://twitter.com/myfoodiecanada/status/1735522266291613975",
          postedOn: '2022-06-11T00:01'
        }
      ]
    }
  ]
}
