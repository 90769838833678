import { useState, useEffect, useContext } from 'react'
import { useInView } from 'react-intersection-observer'
import ReactPlayer from 'react-player/lazy'
import { RecipesDataContext, ThemeContext, ScreenSizeContext, DisplayMediaPlayerDataUpdateContext, MediaPlayerURLDataUpdateContext } from '../../../../contexts/appContext'
import VideoActions from '../VideoActions/VideoActions'
import SvgIcons from '../../../../components/SvgIcons/SvgIcons'
import ShareOption from '../../../../components/ShareOption/ShareOption.js'
import './style.scss'

function Video(props) {
  //const setrecipesData = useContext(RecipesDataUpdateContext)
  const [ index, setIndex ] = useState(0)
  const recipesData = useContext(RecipesDataContext)
  const themeContext = useContext(ThemeContext)
  const screenSize = useContext(ScreenSizeContext)
  const setIsMediaPlayerDisplayed = useContext(DisplayMediaPlayerDataUpdateContext)
  const setMediaPlayerURL = useContext(MediaPlayerURLDataUpdateContext)
  //const [ isVideoPlaying, setIsVideoPlaying ] = useState(true)

  const { ref, inView, entry } = useInView({
    threshold: 0.5
  })

  return(
    <div className={`videoCard ${themeContext}`}
    onMouseEnter={(event) => {
      if(screenSize.width > 1024) {
        const svgElement = event.currentTarget.childNodes[0].getElementsByTagName('svg')[0]

        if(svgElement !== null && svgElement !== undefined)
          svgElement.style.display = 'block'
      }
    }}
    onMouseLeave={(event) => {
      if(screenSize.width > 1024) {
        const svgElement = event.currentTarget.childNodes[0].getElementsByTagName('svg')[0]

        if(svgElement !== null && svgElement !== undefined)
          svgElement.style.display = 'none'
      }
    }}>
      <div className='thumbnailInVideos'>
        {recipesData.recipes[props.index].images[3].indexOf('9x16') > -1 &&
          <div ref={ref} id={`ShortVDO-${recipesData.recipes[props.index].id}`} className='shortVideoDiv'>
            <ReactPlayer
              url={recipesData.recipes[props.index].socialMedia[0].url} muted={true}
              playing={inView}
              controls={false}
              width={props.width}
              height={props.height}
              loop={true}
              light={false}
            />
          </div>
        }

        {recipesData.recipes[props.index].images[3].indexOf('9x16') < 0 &&
        <div style={{ position: 'relative' }}
        onClick={() => {
          if(document.getElementById('BackButton') !== null)
            document.getElementById('BackButton').style.display = 'none'

          setMediaPlayerURL(recipesData.recipes[props.index].socialMedia[0].url)
          setIsMediaPlayerDisplayed(true)
          //props.openMediadPlayer.call(this, recipesData.recipes[props.index].socialMedia[0].url)
        }}>
          <img src={require(`../../../../images/${recipesData.recipes[props.index].images[props.imgIndex]}`)} alt={recipesData.recipes[props.index].altText} draggable={false} />
          <div className='playVideoButton'>
            <SvgIcons id={1} style={{ display: screenSize.width > 1024? 'none': 'block' }} />
          </div>
          <div className={'theme-dark'} style={{ position: 'absolute', right: '7px', bottom: '10px', fontSize: '12px', fontWeight: '200', padding: '0 4px 2px 4px', borderRadius: '2px' }}>
            {recipesData.recipes[props.index].socialMedia[0].timeLength}
          </div>
        </div>
        }
      </div>
      <div style={{ marginLeft: '3px' }}>{recipesData.recipes[props.index].name}</div>
      <VideoActions index={props.index} videoIndicator={props.videoIndicator} setTooltipData={props.setTooltipData} removeVideoFromList={props.removeVideoFromList} openMediadPlayer={props.openMediadPlayer} setRemoveData={props.setRemoveData} />
    </div>
  )
}

export default Video
