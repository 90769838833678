import MainLogo from '../../components/MainLogo/MainLogo'
import SvgIcons from '../../components/SvgIcons/SvgIcons'
import './style.scss'

const Loading = () => {
  return (
    <div id='LoadingDiv'>
      <div id='AnimationInLoading'>
        <SvgIcons id={52}/>
        <img src={require('../../images/Logo-Black-600px.png')} alt="logo" draggable={false}/>
      </div>
    </div>
  )
}

export default Loading
