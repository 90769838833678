import { useState, useContext, useEffect } from 'react'
import { CurrentPageUpdateContext } from '../../contexts/appContext'
import MiddleSection from '../Videos/components/MiddleSection/MiddleSection'
import RemoveSavedItem from '../../components/RemoveSavedItem/RemoveSavedItem'
import './style.scss'

function Pinned() {
  const [ isDeleteConfirmDisplayed, setIsDeleteConfirmDisplayed ] = useState(false)
  const [ data, setData ] = useState()

  const setCurrectPage = useContext(CurrentPageUpdateContext)

  useEffect(() => {
    setCurrectPage('WatchLater')
  }, [])

  const savedToWatchLaterList = window.localStorage.getItem('savedToWatchLaterItems') === null? []: JSON.parse(window.localStorage.getItem('savedToWatchLaterItems'))

  return (
    <div id="WatchLaterPageDiv">
      {savedToWatchLaterList.length > 0 &&
        <div style={{ position: 'relative', textAlign: 'center', margin: '40px 0'}}>
         Your saved videos
        </div>
      }
      {savedToWatchLaterList.length === 0 &&
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
         You have no saved videos
        </div>
      }
      {savedToWatchLaterList.length > 0 &&
        <MiddleSection className='inSavedVideos' type='saved' setRemoveData={(props) => {setData(props); setIsDeleteConfirmDisplayed(true)}} />
      }
      {isDeleteConfirmDisplayed &&
        <RemoveSavedItem setIsDeleteConfirmDisplayed={setIsDeleteConfirmDisplayed} data={data} />
      }
    </div>
  )
}

export default Pinned
