import { useState, useContext, useEffect } from 'react'
import { RecipesDataContext, TooltipUpdateContext, ScreenSizeContext, ThemeContext } from '../../../../../contexts/appContext'
import SvgIcons from '../../../../../components/SvgIcons/SvgIcons'
import ReactOption from '../../../../../components/ReactOption/ReactOption.js'
import ShareOption from '../../../../../components/ShareOption/ShareOption.js'
import PinOption from '../../../../../components/PinOption/PinOption.js'
import Tooltip from '../../../../../components/Tooltip/Tooltip'
//import MoreOptions from '../../MoreOptions/MoreOptions'
import './style.scss'

function Buttons(props) {
  const [ isMoreOptionsDisplayed, setIsMoreOptionsDisplayed ] = useState(false)

  const recipesData = useContext(RecipesDataContext)
  const setTooltipData = useContext(TooltipUpdateContext)
  const screenSize = useContext(ScreenSizeContext)
  const themeContext = useContext(ThemeContext)

  const displayTooltip = (event, text, x, y) => {
    setTooltipData(
      {
        display: true,
        position: 'absolute',
        text: text,
        left: event.target.getBoundingClientRect().x + x,
        top: event.target.getBoundingClientRect().y + y + window.pageYOffset,
        right: 'auto',
        bottom: 'auto'
      }
    )
  }

  const hideTooltip = () => {
    setTooltipData(
      {
        display: false
      }
    )
  }

  if(recipesData.recipes.length > 0) {
    return(
      <div id='ButtonsInRecipesCardDiv'>
        <div id='RecipesIconsDiv'>
          <div className='buttonInRecipe'>
            <ReactOption index={props.index} rID={recipesData.recipes[props.index].id} />
          </div>
          <div className={`buttonInRecipe ${themeContext}`} style={{ cursor: 'pointer', transform: 'scale(1.2)' }} onClick={() => window.open(recipesData.recipes[props.index].socialMedia[0].url)} onMouseEnter={(event) => displayTooltip.call(this, event, 'watch on YouTube', -45, -44 )} onMouseLeave={hideTooltip}>
            <SvgIcons id={1}  translateX={1} translateY={2} scale={1}/>
          </div>
          <div className={`buttonInRecipe ${themeContext}`} onClick={() => window.open(recipesData.recipes[props.index].socialMedia[1].url)} onMouseEnter={(event) => displayTooltip.call(this, event, 'view on Pinterest', -45, -44)} onMouseLeave={hideTooltip}>
            <SvgIcons id={0} />
          </div>
          <div className={`buttonInRecipe ${themeContext}`} onClick={() => window.open(recipesData.recipes[props.index].socialMedia[2].url)} onMouseEnter={(event) => displayTooltip.call(this, event, 'view on Instagram', -45, -44)} onMouseLeave={hideTooltip}>
            <SvgIcons id={2} />
          </div>
          <div className={`buttonInRecipe ${themeContext}`} onClick={() => window.open(recipesData.recipes[props.index].socialMedia[3].url)} onMouseEnter={(event) => displayTooltip.call(this, event, 'watch on TikTok', -35, -44)} onMouseLeave={hideTooltip}>
            <SvgIcons id={9} />
          </div>
          <div className={`buttonInRecipe ${themeContext}`} onClick={() => window.open(recipesData.recipes[props.index].socialMedia[4].url)} onMouseEnter={(event) => displayTooltip.call(this, event, 'view on Facebook', -43, -44)} onMouseLeave={hideTooltip}>
            <SvgIcons id={3} />
          </div>
          <div className={`buttonInRecipe ${themeContext}`} onClick={() => window.open(recipesData.recipes[props.index].socialMedia[5].url)} onMouseEnter={(event) => displayTooltip.call(this, event, 'view on Twitter', -33, -40)} onMouseLeave={hideTooltip}>
            <SvgIcons id={17} />
          </div>
          <div className={`buttonInRecipe ${themeContext}`} onMouseEnter={(event) => displayTooltip.call(this, event, 'share', -43, 0)} onMouseLeave={hideTooltip}>
            <ShareOption rID={recipesData.recipes[props.index].id} />
          </div>
          {!props.inPinnedItems &&
            <div className={`buttonInRecipe ${themeContext}`}>
              <PinOption index={props.index} rID={recipesData.recipes[props.index].id} />
            </div>
          }
        </div>
      </div>
    )
  }
  else
    return false
}
export default Buttons
