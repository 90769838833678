import Sausage from './Sausage'

const PostImages = [
  {
    id: 'R00001',
    base64: Sausage
  }
]

export default PostImages
