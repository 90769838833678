import { useContext, useState } from 'react'
import { ThemeContext, ScreenSizeContext, RecipesDataContext } from '../../../../contexts/appContext'
import RecipeCard from '../RecipeCard/RecipeCard'
import Buttons from '../RecipeCard/Buttons/Buttons'
import './style.scss'

function MiddleSection(props) {
  const [ isShareDataReady, setIsShareDataReady ] = useState(false)
  const [ index, setIndex ] = useState(0)

  const themeContext = useContext(ThemeContext)
  const screenSize = useContext(ScreenSizeContext)
  const recipesData = useContext(RecipesDataContext)

  return(
    <div id='MiddleSectionRecipePage' className={themeContext}>
      <RecipeCard
        index={index}
        setIndex={setIndex}
        isShareDataReady={isShareDataReady}
        setIsShareDataReady={setIsShareDataReady}
      />
      {screenSize.width <= 700 &&
        <Buttons inPinnedItems={props.inPinnedItems} index={index} openShare={props.openShare} />
      }
    </div>
  )
}

export default MiddleSection
