import { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { ScreenSizeContext, RecipesDataContext, ThemeContext } from '../../../../contexts/appContext'
//import { RecipesData } from '../../../../data/recipesData'
//import Recipe from '../../../../components/Recipe/Recipe'
import Image from '../../../../components/Image/Image'
import Posts from '../Posts/Posts'
import './style.scss'

function MiddleSection(props) {
  //window.alert(sessionStorage.getItem('groupCounter') === null)
  const screenSize = useContext(ScreenSizeContext);
  const recipesData = useContext(RecipesDataContext)
  const themeContext = useContext(ThemeContext)
  //let id = 'aaa';
  const [ isRecipeOpen, setRecipeOpen ] = useState(false)
  const [ index, setIndex ] = useState(0)
  const [ currentIndex, setCurrentIndex ] = useState(0)
  const [ postsArray, setPostsArray ] = useState([])
  const [ signatureCounter, setSignatureCounter ] = useState(5)
  const [ groupCounter, setGroupCounter ] = useState(sessionStorage.getItem('groupCounter') === null? 5: sessionStorage.getItem('groupCounter'))
  //window.alert(`now: ${groupCounter}`)
  //sessionStorage.setItem('groupCounter', groupCounter)
  //console.log(`DATA: ${JSON.stringify(recipesData)}`);

  //window.alert(inView)

  useEffect(() => {
    //const val = parseInt(sessionStorage.getItem('groupCounter') === '0'? 5: parseInt(sessionStorage.getItem('groupCounter')))
    //setGroupCounter(val)
    //window.alert(`valss: ${val === 0}|${sessionStorage.getItem('groupCounter')}`)
  }, [])

  //useEffect(() => {
    //window.alert(`val: ${sessionStorage.getItem('groupCounter')}`)
    //sessionStorage.setItem('groupCounter', groupCounter === 0? 5: groupCounter)
  //}, [groupCounter])

  useEffect(() => {
    /*const tempArr = [ ...postsArray ]
    for(let i = currentIndex; i < 5; i++) {
      if(i < recipesData.recipes.length) {
        //console.log("AAA");
        tempArr.push(recipesData.recipes[i])
        setPostsArray(tempArr)
        setCurrentIndex(i)
      }
    }*/

    if(screenSize.width < 1025)
      setSignatureCounter(4)

    else
      setSignatureCounter(5)

    //window.onresize = () => {
      if(screenSize.width < 1025)
        setSignatureCounter(4)

      else
        setSignatureCounter(5)
    //}
  }, [screenSize])

  /*window.onscroll = () => {
    if(groupCounter <= recipesData.recipes.length && document.body.getBoundingClientRect().height - window.scrollY - window.innerHeight < 100) {
      setGroupCounter(groupCounter + 5)
      sessionStorage.setItem('groupCounter', groupCounter)
      //window.alert(`EE: ${groupCounter}`)
    }
    //console.log(`test: ${document.body.getBoundingClientRect().height} | ${window.scrollY} | ${window.innerHeight}`);
  }*/

  return(
    <div style={ { marginBottom: '80px' } }>
      <div id='TopTextInHome'>
        Authentic . Unique . Exotic . Recipes
      </div>
      <div id='SignatureOptionsDiv' className={themeContext}>
        {
          recipesData.recipes.map((item, i) => {
            if(i < signatureCounter) {
              return(
                <Link key={i + 1} className='recipesOptionDiv' to={`/recipe/${recipesData.recipes[i + 1].id}`} draggable='false'>
                    <img src={require(`../../../../images/${recipesData.recipes[i + 1].images[0]}`)} alt={recipesData.recipes[i + 1].altText} draggable={false} />
                  <span className={`recipeTitle ${themeContext}`}>{recipesData.recipes[i + 1].tags[0]}</span>
                </Link>
              )
            }
          })
        }
      </div>
      <div id='PostsDiv'>
        {
          recipesData.recipes.map((element, i) => {
            if(i < groupCounter) {
              return(
                <Posts i={i} groupCounter={groupCounter} setGroupCounter={setGroupCounter} key={i} index={i} />
              )
            }
          })
        }
      </div>
    </div>
  )
}

export default MiddleSection
