import { getAuth, signInAnonymously } from 'firebase/auth'
import { getDatabase, ref, onValue, get, set, child } from 'firebase/database'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig)
const database = getDatabase(app)
const auth = getAuth(app)
const analytics = getAnalytics(app)

signInAnonymously(auth)
  .then((userCredential) => {
    const user = userCredential.user
  })
  .catch((error) => {
    console.error('Error signing in anonymously:', error)
  })

export const appReactsRef = ref(database, 'appReacts')
export const postsRef = ref(database, 'posts')

export const fetchPostsOnce = () => {
  return get(postsRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val()
      } else {
        return null
      }
    })
    .catch((error) => {
      console.error('Error fetching "posts":', error)
      return null
    })
}

export const listenToPostsChanges = (callback) => {
  onValue(postsRef, (snapshot) => {
    const postsData = snapshot.val()
    callback(postsData)
  })
}

export function listenToPostLikesChange(postId, callback) {
  const postRef = ref(database, `posts/${postId}/numberOfLikes`)
  const unsubscribe = onValue(postRef, (snapshot) => {
    const postData = snapshot.val()
    callback(postData)
  })
  return unsubscribe
}

export const updatePostLikesCount = (postId, type) => {
  const postRef = ref(database, `posts/${postId}`)
  get(postRef).then((snapshot) => {
    const currentLikes = snapshot.val().numberOfLikes || 0
    if (type === 'increase') {
      set(child(postRef, 'numberOfLikes'), currentLikes + 1)
    } else {
      set(child(postRef, 'numberOfLikes'), currentLikes - 1)
    }
  })
}

export function listenToPostViewsChange(postId, callback) {
  const postRef = ref(database, `posts/${postId}/numberOfViews`)
  const unsubscribe = onValue(postRef, (snapshot) => {
    const postData = snapshot.val()
    callback(postData)
  })
  return unsubscribe
}

export const updatePostViewsCount = (postId) => {
  const postRef = ref(database, `posts/${postId}`)
  get(postRef).then((snapshot) => {
    const currentViews = snapshot.val().numberOfViews || 0
    set(child(postRef, 'numberOfViews'), currentViews + 1)
  })
}



/*export const updateAppLikes = () => {
  const appLikesRef = ref(database, 'appLikes')

  get(appLikesRef).then((snapshot) => {
    const currentLikes = snapshot.val().totalLikes || 0
    set(child(appLikesRef, 'totalLikes'), currentLikes + 1)
  })
}

// Read data from 'posts' exactly once
once(child(postsRef, 'abcTestID')).then((snapshot) => {
  if (snapshot.exists()) {
    const postData = snapshot.val()
    console.log('Data retrieved once:', postData)
  } else {
    console.log('No data found.')
  }
}).catch((error) => {
  console.error('Error getting data:', error)
})

for (const postId in firebaseData.posts) {
  if (firebaseData.posts.hasOwnProperty(postId)) {
    // Access individual post data using postId as the key
    const postData = firebaseData.posts[postId];

    console.log(`Post ID: ${postId}`);
    console.log(`Total Likes: ${postData.totalLikes}`);
    console.log(`Total Views: ${postData.totalViews}`);

    // Process or use the post data as needed
  }
}


onValue(postRef, (snapshot) => {
  const likesCount = snapshot.val();
  // Handle the updated likes count here
  console.log('Total likes updated:', likesCount);
})

once()



*/
