import { useEffect, useContext, useState } from 'react'
import { updatePostViewsCount } from '../../../../firebase'
import { RecipesDataContext, ThemeContext, ScreenSizeContext } from '../../../../contexts/appContext'
import NumberOfViews from '../../../../components/NumberOfViews/NumberOfViews'
import ReactOption from '../../../../components/ReactOption/ReactOption'
import PinOption from '../../../../components/PinOption/PinOption'
import Image from '../../../../components/Image/Image'
import SvgIcons from '../../../../components/SvgIcons/SvgIcons'
import Buttons from './Buttons/Buttons'
import Texts from './Texts/Texts'
import './style.scss'

function RecipeCard(props) {
  const [ isMounted, setIsMounted ] = useState(false)

  const recipesData = useContext(RecipesDataContext)
  const themeContext = useContext(ThemeContext)
  const screenSize = useContext(ScreenSizeContext)

  const setButtonsPosition = () => {
    if(screenSize.width <= 700) {
      const btnsCardEle = document.getElementById('ButtonsInRecipesCardDiv')

      if(btnsCardEle !== null) {
        const recipeCardRect = document.getElementById('RecipeCard').getBoundingClientRect()

        btnsCardEle.style.left = (recipeCardRect.left + recipeCardRect.width - 35) + 'px'
      }
    }
  }

  useEffect(() => {
    console.log(`lenNN: ${recipesData.recipes.length}`);
    //document.body.style.overflow = 'hidden'

    for(let i = 0; i < recipesData.recipes.length; i++) {
      console.log(`recipe ID: ${recipesData.recipes[i].id}|${window.location.pathname.split('/')[2]}`);
      if(recipesData.recipes[i].id === window.location.pathname.split('/')[2]) {
        props.setIsShareDataReady.call(this, true)
        props.setIndex(i)

        if(! isMounted) {
          setTimeout(function () {
            //const tempData = { ...recipesData }
            updatePostViewsCount(recipesData.recipes[i].id)
          }, 1500)

          setIsMounted(true)
        }

        break
      }
    }

    if(recipesData.recipes.length > 0)
      setButtonsPosition.call(this)

  }, [recipesData])

  useEffect(() => {
    setButtonsPosition.call(this)
    /*if(screenSize.width > 800){
      document.getElementById('RecipeDiv').style.height = document.getElementById('ImageInRecipeCard').getBoundingClientRect().height + 'px'
      //window.alert(`test: ${screenSize.width}`)
    }

    else {
      document.getElementById('RecipeDiv').style.height = '100vh'
    }
      //document.getElementById('RecipeDiv').style.height = document.getElementById('ImageInRecipeCard').height + 'px'

      */
  }, [screenSize])

  if(recipesData.recipes.length > 0) {
    return(
      <div id='RecipeCard'>
        {props.isShareDataReady &&
          <div id="ImageAndTextInRecipe" style={{ backgroundColor: themeContext === 'theme-dark'? 'black': 'white' }}>
            <div id='ImageInRecipeCardDiv'>
              <img id='ImageInRecipeCard' src={require(`../../../../images/${recipesData.recipes[props.index].images[0]}`)} draggable={false} />
            </div>
            <div id='TextInRecipeCard'>
              <Texts index={props.index} />
            </div>
          </div>
        }
        {!props.isShareDataReady &&
          <div>LOADING...</div>
        }
        {screenSize.width > 700 &&
          <Buttons inPinnedItems={props.inPinnedItems} index={props.index} />
        }
      </div>
    )
  }
  else
    return false

}

export default RecipeCard
