import { useState, useContext } from 'react'
import { ThemeContext, ThemeUpdateContext } from '../../../contexts/appContext'
import SvgIcons from '../../SvgIcons/SvgIcons'
import './style.scss'

function Theme() {
  const themeContext = useContext(ThemeContext)
  const setThemeContext = useContext(ThemeUpdateContext)

  return(
    <div id='ThemeRow' className='optionRow'
    onClick={() => {
      window.localStorage.setItem('appTheme', themeContext === 'theme-dark'? 'theme-light': 'theme-dark')
      document.body.style.backgroundColor = themeContext === 'theme-dark'? '#eeeeee': '#121212'
      setThemeContext(themeContext === 'theme-dark'? 'theme-light': 'theme-dark')
    }}>
      <div className={`iconInOption ${themeContext}`} style={{ backgroundColor: 'transparent' }}><SvgIcons id={themeContext === 'theme-dark'? 25: 24} /></div>
      <div className='textInOption'>Theme</div>
    </div>
  )
}

export default Theme
