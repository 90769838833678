import { useState, useEffect, useContext } from 'react'
import ReactPlayer from 'react-player'
import { ScreenSizeContext, RecipesDataContext } from '../../../../contexts/appContext'
//import Recipe from '../../../../components/Recipe/Recipe'
import Video from '../Video/Video'
//import MediaPlayer from '../../../../components/MediaPlayer/MediaPlayer'
import Share from '../../../../components/Share/Share'
import './style.scss'

function MiddleSection(props) {
  const [ isRecipeOpen, setRecipeOpen ] = useState(false)
  const [ index, setIndex ] = useState(0)
  const [ videoWidthAndHeight, setVideoWidthAndHeight ] = useState(['236px', '420px'])
  const [ isMediaPlayerDisplayed, setIsMediaPlayerDisplayed] = useState(false)
  const [ isShareDisplayed, setIsShareDisplayed ] = useState(false)
  const [ url, setURL ] = useState('')
  const [ recipesDataArr, setRecipesDataArr ] = useState([])

  const recipesDataCtx =  useContext(RecipesDataContext)
  const screenSize = useContext(ScreenSizeContext)

  //alert(window.localStorage.getItem('savedToWatchLaterItems'))
  useEffect(() => {
    if(recipesDataCtx.recipes.length > 0 && props.type === 'all')
      setRecipesDataArr([...recipesDataCtx.recipes])

    else if(recipesDataCtx.recipes.length > 0 && props.type === 'saved') {
      const temp = []
      const savedData = JSON.parse(window.localStorage.getItem('savedToWatchLaterItems'))

      for(let i = 0; i < savedData.length; i++)
        temp.push({actualIndex: savedData[i], ...recipesDataCtx.recipes[savedData[i]]})

      setRecipesDataArr(temp)
      //alert(`data: ${JSON.stringify(temp[1].id)}`)
    }
  }, [recipesDataCtx])


  const setVideoDims = () => {
    if(screenSize.width >= 488)
      setVideoWidthAndHeight(['236px', '420px'])

    else
      setVideoWidthAndHeight([`${screenSize.width / 2.2}px`, `${(screenSize.width / 2.2) * 1.777}px`])
  }

  useEffect(() => {
    setVideoDims.call(this)
  }, [screenSize])

  const getSpan = (item) => {
    if(item.cardSize === 'large') {
      if(screenSize.width > 768)
        return 38

      else if(screenSize.width > 389)
        return 26

      return 22
    }

    else if(item.cardSize === 'medium') {
      if(screenSize.width > 768)
        return 34

      else if(screenSize.width > 389)
        return 24

      return 20
    }

    else if(item.cardSize === 'small') {
      if(screenSize.width > 768)
        return 31

      else if(screenSize.width > 389)
        return 20

      return 18
    }
  }

  return(
    <div>
      <div id='VideoCardsWrapper' className={props.className}>
        {
          recipesDataArr.map((item, i) => {

            return(
              <div key={i} style={{ position: 'relative', paddingBotton: '50px', gridRowEnd: `span ${getSpan.call(this, item)}` }}>
                <Video index={props.type === 'saved'? item.actualIndex: i} imgIndex={3} width={videoWidthAndHeight[0]} height={videoWidthAndHeight[1]} setRemoveData={props.setRemoveData} />
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default MiddleSection
