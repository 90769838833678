import { useContext, useEffect } from 'react'
import MiddleSection from './components/MiddleSection/MiddleSection'
import { CurrentPageUpdateContext } from '../../contexts/appContext'
import './style.scss'

function Recipe() {
  const setCurrectPage = useContext(CurrentPageUpdateContext)

  useEffect(() => {
    setCurrectPage('Recipe')
    //window.onbeforeunload = () => sessionStorage.removeItem('groupCounter')
  }, [])

  return (
    <div id="RecipePageDiv">
      <MiddleSection />
    </div>
  )
}

export default Recipe
