
function Rights() {
  return (
    <>
      <div className='headerInSlider'>User Rights</div>
      Thank you for visiting MyFoodie.ca (the "Website"). As a user of our Website, you have certain rights that we are committed to upholding. Please take a moment to review the following rights you have while using MyFoodie.ca:
      <div className='headerInSlider'>1. Right to Access</div>
      You have the right to access and browse our Website freely without any discrimination or restrictions. Enjoy exploring our content and resources at your convenience.
      <div className='headerInSlider'>2. Right to Privacy</div>
      While MyFoodie.ca does not collect personal information or data from its users, we still respect your privacy. Any non-personal data collected, such as cookies or browsing behavior, is handled in accordance with our Privacy Policy.
      <div className='headerInSlider'>3. Right to Transparency</div>
      We are committed to being transparent about how our Website operates, the services we offer, and any relevant terms and conditions.
      <div className='headerInSlider'>4. Right to Security</div>
      Your data security is essential to us, even if it's non-personal data. We take reasonable measures to protect the information you provide while using our Website.
      <div className='headerInSlider'>5. Right to Discontinue Use</div>
      You have the right to discontinue using our Website at any time and for any reason. Your participation on MyFoodie.ca is entirely voluntary.
      <div className='headerInSlider'>6. Right to Object</div>
      If you have concerns about any data collection or processing on our Website, you have the right to object. We respect your choices and privacy preferences.
      <div className='headerInSlider'>7. Right to Information Accuracy</div>
      We strive to provide accurate and up-to-date information on our Website. If you come across any inaccuracies, please let us know, and we will take appropriate action.
      <div className='headerInSlider'>8. Right to Fair Practices</div>
      You have the right to expect fair practices while using our Website. We maintain transparent advertising, fair pricing, and no hidden fees or charges.
      <div className='headerInSlider'>9. Right to Contact</div>
      If you have any questions, concerns, or feedback about MyFoodie.ca or our services, we welcome you to contact us. Your feedback helps us improve our offerings.
      <div className='headerInSlider'>10. Right to Withdraw Consent</div>
      If there are areas on our Website where you need to provide consent (e.g., accepting cookies or signing up for newsletters), you have the right to withdraw that consent at any time.
    </>
  )
}

export default Rights
