import { useState, useContext } from 'react'
import { ThemeContext, TooltipUpdateContext } from '../../../contexts/appContext'
import AppOptions from '../AppOptions/AppOptions'
import Tooltip from '../../Tooltip/Tooltip'
import SvgIcons from '../../SvgIcons/SvgIcons'
import './style.scss'

function AppOptionsButton(props) {
  const [ displayListOfPins, setDisplayListOfPins ] = useState(false)
  const [ displaySavedVideos, setDisplaySavedVideos ] = useState(false)

  const themeContext = useContext(ThemeContext)
  const setTooltipData = useContext(TooltipUpdateContext)

  return(
    <div id='AppOptionsSection'>
      <div id='AppOptionsButton' className={themeContext} style={{ backgroundColor: themeContext === 'theme-dark'? 'rgba(0, 0, 0, 1)': 'rgba(255, 255, 255, 1)' }}
      onClick={() => {
        //window.localStorage.clear()
        props.setIsSearchDivDisplayed(false)
        setTooltipData(
          {
            display: false
          }
        )

        //if(document.getElementById('ButtonOptionTooltip') !== null)
        //  document.getElementById('ButtonOptionTooltip').remove()

        props.setIsOptionsDivDisplayed(props.isOptionsDivDisplayed? false: true)
        //props.isOptionsDivDisplayed? document.getElementById('OptionsDiv').style.animation = 'displayOptions 150ms linear forwards': document.getElementById('OptionsDiv').style.animation = 'displayOptions 150ms linear forwards'
        window.onclick = (event) => {
          if(event.target.id !== 'AppOptionsButton' && event.target.id !== 'OptionsDiv') {
            //document.getElementById('OptionsDiv').style.bottom = 'displayOptionsMobile 200ms ease reverse'
            setTimeout(function () {
              props.setIsOptionsDivDisplayed(false)
            }, 10);
            window.onclick = null
          }
        }
      }}
      onMouseEnter={(event) => {
        setTooltipData(
          {
            display: true,
            position: 'fixed',
            text: 'options',
            left: 'auto',
            top: '65',
            right: '3',
            bottom: 'auto'
          }
        )
      }}
      onMouseLeave={(event) => {
        setTooltipData(
          {
            display: false
          }
        )
      }}>
        <SvgIcons id={45} />
      </div>
      { props.isOptionsDivDisplayed && <AppOptions setDisplayListOfPins={setDisplayListOfPins} setDisplaySavedVideos={setDisplaySavedVideos} /> }
    </div>
  )
}

export default AppOptionsButton
