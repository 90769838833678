import { useContext, useState } from 'react'
import { RecipesDataContext, RecipesDataUpdateContext, TooltipUpdateContext } from '../../contexts/appContext'
import SvgIcons from '../SvgIcons/SvgIcons'

function RemoveSavedItem(props) {
  const setTooltipData = useContext(TooltipUpdateContext)

  return (
    <div>
      <div id='RemoveItemFromList' style={{ cursor: 'pointer' }}
        onClick={() => {
          console.log(`PROPS VALUE: ${JSON.stringify(props)}`)
          props.setRemoveData.call(this, props)
        }}
        onMouseEnter={(event) => {
          setTooltipData(
            {
              display: true,
              position: 'fixed',
              text: 'remove item',
              left: event.target.getBoundingClientRect().x - 28,
              top: event.target.getBoundingClientRect().y - 42,
              right: 'auto',
              bottom: 'auto'
            }
          )
        }}

        onMouseLeave={(event) => {
          setTooltipData(
            {
              display: true
            }
          )
        }}>
        <SvgIcons id={37} />
      </div>
    </div>
  )
}

export default RemoveSavedItem
