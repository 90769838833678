import { useState, useEffect, useContext } from 'react'
import { ScreenSizeContext, RecipesDataContext, RecipesDataUpdateContext, TooltipUpdateContext } from '../../contexts/appContext'
import SvgIcons from '../SvgIcons/SvgIcons'
import Tooltip from '../Tooltip/Tooltip'
import './style.scss'

function ShareOption(props) {
  const [ isPinned, setPinned ] = useState(false)
  const screenSize = useContext(ScreenSizeContext)
  const recipesData = useContext(RecipesDataContext)
  const setRecipesData = useContext(RecipesDataUpdateContext)
  const setTooltipData = useContext(TooltipUpdateContext)
  //const locStrgData = {}
  //const locStgVal = window.localStorage.getItem(props.rID)

  useEffect(() => {
    //if(locStgVal !== null) {
    //const tempData = { ...recipesData }
    //tempData.recipes[props.index].isPinned = window.localStorage.getItem(`recipePin_${tempData.recipes[props.index].id}`) !== null
    //setRecipesData(tempData)
    //}
  }, [])

  return(
    <>
      <div style={{ cursor: 'pointer' }}
      onClick={(event) => {
        const pinnedItemsArray = window.localStorage.getItem('pinnedItems') === null? []: JSON.parse(window.localStorage.getItem('pinnedItems'))
        //window.alert(window.localStorage.getItem(`recipePin_${recipesData.recipes[props.index].id}`))
        //event.stopPropagation()
        const tempData = { ...recipesData }

        if(! recipesData.recipes[props.index].isPinned) {
          if(navigator.vibrate !== undefined)
            navigator.vibrate(150)
          const img = document.createElement('img')
          img.setAttribute('src', require(`../../images/${recipesData.recipes[props.index].images[4]}`))
          img.style.position = 'fixed'
          img.style.zIndex = '999999999999999999999'

          if(screenSize.width >= 1024) {
            img.style.right = '50px'
            img.style.top = '70px'
          }

          else {
            img.style.left = 'calc(50% - 32px)'
            img.style.bottom = '120px'
          }

          img.style.width = '70px'
          img.style.borderRadius = '10px'
          //img.style.left = `${event.target.getBoundingClientRect().x - 10}px`
          //img.style.top = `${event.target.getBoundingClientRect().y - 80}px`

          document.body.append(img)

          if(screenSize.width > 1024)
            img.style.animation = 'addImgToPin 1500ms linear forwards'

          else
            img.style.animation = 'addImgToPinMobile 1500ms linear forwards'

          setTimeout(function () {
            img.remove();
          }, 2000);

          tempData.recipes[props.index].isPinned = true
          setRecipesData(tempData)
          pinnedItemsArray.splice(0, 0, props.index)

          window.localStorage.setItem(`recipePin_${recipesData.recipes[props.index].id}`, Date.now())
          window.localStorage.setItem('pinnedItems', JSON.stringify(pinnedItemsArray))
          event.target.style.animation = 'toPin 500ms linear normal'
        }
        else {
          if(navigator.vibrate !== undefined)
            navigator.vibrate(50)
          tempData.recipes[props.index].isPinned = false
          setRecipesData(tempData)

          window.localStorage.removeItem(`recipePin_${recipesData.recipes[props.index].id}`)
          //pinnedItemsArray.push()
          pinnedItemsArray.splice(pinnedItemsArray.indexOf(props.index), 1)
          window.localStorage.setItem('pinnedItems', JSON.stringify(pinnedItemsArray))
          event.target.style.animation = 'toUnpin 400ms linear forwards'
        }
        //event.target.style.transform = 'rotate(90deg)'
        setTooltipData(
          {
            display: true,
            position: 'absolute',
            text: window.localStorage.getItem(`recipePin_${recipesData.recipes[props.index].id}`) === null? 'pin': 'pinned',
            left: window.localStorage.getItem(`recipePin_${recipesData.recipes[props.index].id}`) === null? event.target.getBoundingClientRect().x + 3: event.target.getBoundingClientRect().x - 5,
            top: event.target.getBoundingClientRect().y - 38 + window.pageYOffset,
            right: 'auto',
            bottom: 'auto'
          }
        )
      }}
      onMouseEnter={(event) => {
        setTooltipData(
          {
            display: true,
            position: 'absolute',
            text: window.localStorage.getItem(`recipePin_${recipesData.recipes[props.index].id}`) === null? 'pin': 'pinned',
            left: window.localStorage.getItem(`recipePin_${recipesData.recipes[props.index].id}`) === null? event.target.getBoundingClientRect().x - 5: event.target.getBoundingClientRect().x - 14,
            top: event.target.getBoundingClientRect().y - 39 + window.pageYOffset,
            right: 'auto',
            bottom: 'auto'
          }
        )
      }}

      onMouseLeave={(event) => {
        setTooltipData(
          {
            display: false
          }
        )
      }}>
        <SvgIcons id={recipesData.recipes[props.index].isPinned? 6: 7} className={recipesData.recipes[props.index].isPinned? 8: 7} />
      </div>
    </>
  )
}

export default ShareOption
