import { useState, useContext } from 'react'
import { ThemeContext, DisplayFollowUsDataUpdateContext } from '../../../contexts/appContext'
import About from '../About/About'
import Advertisement from '../Advertisement/Advertisement'
import Contact from '../Contact/Contact'
import Privacy from '../Privacy/Privacy'
import Rights from '../Rights/Rights'
import SEO from '../SEO/SEO'
import Terms from '../Terms/Terms'
import SvgIcons from '../../SvgIcons/SvgIcons'
import './style.scss'

function Slider(props) {
  const themeContext = useContext(ThemeContext)

  return (
    <div id='SliderInMore' className={themeContext}>
      <div className={themeContext} style={{ position: 'sticky', top: '0', width: '100%', height: '40px' }}>
        <div style={{ cursor: 'pointer', width: '30px', height: 'calc(100% - 8px)', paddingTop: '8px' }}
        onClick={() => {
          document.getElementById('SliderInMore').style.animation = 'hideSlider 400ms ease forwards'
          setTimeout(function () {
            props.setIsSliderDisplayed(false)
          }, 450)
        }}>
          <SvgIcons id={49} />
        </div>
      </div>
      {props.sliderType === 'about' &&
        <About />
      }
      {props.sliderType === 'advertisement' &&
        <Advertisement />
      }
      {props.sliderType === 'contact' &&
        <Contact />
      }
      {props.sliderType === 'privacy' &&
        <Privacy />
      }
      {props.sliderType === 'rights' &&
        <Rights />
      }
      {props.sliderType === 'seo' &&
        <SEO />
      }
      {props.sliderType === 'terms' &&
        <Terms />
      }
    </div>
  )
}

export default Slider
