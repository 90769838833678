import SvgIcons from '../../SvgIcons/SvgIcons'
import './style.scss'

function FollowUs() {
  return(
    <div id='FollowUsListinMore'>
      <a target='_blank' href='https://www.pinterest.ca/myfoodiecanada/'>
        <SvgIcons id={0} />
      </a>
      <a target='_blank' href='https://www.youtube.com/channel/UCbLaEFrtEUrev8Ghkew98AQ'>
        <SvgIcons id={1} />
      </a>
      <a target='_blank' href='https://www.instagram.com/myfoodiecanada/'>
        <SvgIcons id={2} />
      </a>
      <a target='_blank' href='https://www.facebook.com/people/MyFoodieCa/100089106942450/'>
        <SvgIcons id={3} />
      </a>
      <a target='_blank' href='https://www.tiktok.com/@myfoodiecanada'>
        <SvgIcons id={9} />
      </a>
      <a target='_blank' href='https://twitter.com/myfoodiecanada'>
        <SvgIcons id={17} />
      </a>
    </div>
  )
}

export default FollowUs
