import { useContext, useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'
import { ScreenSizeContext, DisplayMediaPlayerDataUpdateContext } from '../../contexts/appContext'
import SvgIcons from '../SvgIcons/SvgIcons'
import './style.scss'

function MediaPlayer(props) {
  const [ videoWidth, setVideoWidth ] = useState(0)
  const [ videoHeight, setVideoHeight ] = useState(0)

  const screenSize = useContext(ScreenSizeContext)
  const setIsMediaPlayerDisplayed = useContext(DisplayMediaPlayerDataUpdateContext)

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    //document.getElementById('BackButtonInPinned').style.display = 'none'
  }, [])

  useEffect(() => {
    //window.alert(JSON.stringify(screenSize))
    if(parseInt(screenSize.width * 0.85 * 0.5625) > (screenSize.height * 0.85)) {
      setVideoWidth(parseInt(screenSize.height * 0.85 * 1.78))
      setVideoHeight(parseInt(screenSize.height * 0.85))
    }

    else {
      setVideoWidth(parseInt(screenSize.width * 0.85))
      setVideoHeight(parseInt(screenSize.width * 0.85 * 0.5625))
    }
  }, [screenSize])

  return(
    <div id='MediaPlayerDiv' style={{ zIndex: 2147483647 }}>
      <ReactPlayer
        url={props.url}
        playing={true}
        width={videoWidth}
        height={videoHeight}
        loop={true}
        light={false}
        controls={true}
        />
      <span id='BackButtonInMediaPlayer'
      onClick={() => {
        if(document.getElementById('BackButton') !== null)
          document.getElementById('BackButton').style.display = 'block'

        document.body.style.overflow = 'auto'
        setIsMediaPlayerDisplayed(false)
        }}>
        <SvgIcons id={47} style={{ stroke: 'white', strokeWidth: '0.1' }} />
      </span>
    </div>
  )
}

export default MediaPlayer
